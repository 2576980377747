import React,{useState} from 'react';

import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { Handle, Track, Tick, ValueViewer } from './SliderComponent';

const sliderStyle = {
  margin: '5%',
  position: 'relative',
  width: '90%'
};

const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 14,
  borderRadius: 7,
  cursor: 'pointer',
  backgroundColor: 'rgb(155,155,155)'
};

const RangeSliderComponent = ({data,onChange,saveData}) => {

   const defaultValues = [parseInt(saveData[0]), parseInt(saveData[1])];
   const sliderStyle = {position:'relative',  width:'100%'}
   const [domain,setDoamin] = useState([parseInt(data.iMin), parseInt(data.iMax)]);
   const [values,setValue] = useState(defaultValues.slice());
   const [update,setUpdate] = useState(defaultValues.slice());
   const [reversed,setReversed] = useState(false);

   /* Update slider value after moving the pointer */
   const onUpdate = (update) => {
     setUpdate(update)
   }

   // const getValue = () => {
   //    debugger;
   // }

  return (
  	<div className="range_slider form">
      <ValueViewer values={values} update={update} />
      <Slider
         mode={1}
         step={isNaN(data.iStep)? 1: Number(data.iStep)}
         domain={domain}
         reversed={reversed}
         rootStyle={sliderStyle}
         onUpdate={onUpdate}
         onChange={e=>onChange({name:data.sFilterTag,update})}
         values={values}
         >
         <Rail>
            {({ getRailProps }) => (
            <div style={railStyle} {...getRailProps()} />
            )}
         </Rail>
         <Handles>
            {({ handles, getHandleProps }) => (
            <div className="slider-handles">
               {handles.map(handle => (
               <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                  //onChange={getValue}
                  />
               ))}
            </div>
            )}
         </Handles>
         <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
               {tracks.map(({ id, source, target }) => (
               <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                  />
               ))}
            </div>
            )}
         </Tracks>
         <Ticks count={100}>
            {({ ticks }) => (

            <div className="slider-ticks">
               {ticks.map(tick => (
               <Tick key={tick.id} tick={tick} count={ticks.length}/>
               ))}
            </div>
            )}
         </Ticks>
      </Slider>
   </div>


  );
}
export default withLocalize(withRouter(RangeSliderComponent));

