import React,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import renderHTML from 'react-render-html';

const RestaurantModal = (props, parentCallback) => {

  const [loggerEnabled, setLoggerEnabled] = useState(false);
  const {buttonLabel, className, formData} = props;
  const [modal, setModal] = useState(false);


  /* function to close/open modal */
  const toggle=()=> {
      if(modal){
        props.parentCallback();
      }
       setModal(!modal);
  }

  useEffect(()=>{

  },[]);


  return (
    <div>
       <div className="more_sec single_res_btn" onClick={toggle}>
           <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
       </div>
       <Modal isOpen={modal} toggle={toggle} className="res_popup">
         <ModalHeader toggle={toggle}></ModalHeader>
         <ModalBody>
             <div className="res_pop_sec">
                <div className="res_popup_top" data-backdrop="true">
                   <img alt={formData.sClientName} src={(formData.sImage !== '...')?formData.sImage:'/static/img/bg-resturant.jpg'} />
                   <h3><span>
                     {formData.sClientName}
                   </span>
                   <div className="pop_location">
                   <i className="fa fa-map-marker" aria-hidden="true"></i>
                                  <span>{`${formData.sAddress}, ${formData.sCity}, ${formData.sState}  ${formData.sZip}`}</span></div></h3>
                </div>

                <div className="res_popup_mdl">
                  <div className="single_rest_secbtm1  pink_bg">
                     <div className="single_rest_secbtminner" alt="secbtminner"><span>
                     {`${formData.sFilters} - ${formData.sCuisines}`}
                      </span></div>
                  </div>
                          <div className="single_rest_secbtm2">
                     <div className="single_rest_secbtminner"><span>
                     <img src="/static/img/clock.png" alt="clock" /></span>
                                  <span>Hours <br />{`${formData.sHours}`}</span></div>
                  </div>
                  <div className="single_rest_secbtm3">
                     <div className="single_rest_secbtminner">
                     <span><img src="/static/img/mobile_icon.png" alt="mobile_icon" /></span>
                                  <span>Contact <br /><div dangerouslySetInnerHTML={{ __html: formData.sURL/* + restaurantsDetails.sEmail + restaurantsDetails.sPhone*/ }} /></span></div>
                  </div>
                </div>
                <div className="res_popup_btm">
                   <div id="popaccordion">
                   { formData.sAboutUs  &&
                      <div className="card">
                         <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                               <button className="btn btn-link" data-toggle="collapse" data-target="#popabout" aria-expanded="true" aria-controls="popabout">
                               About Us
                               </button>
                            </h5>
                         </div>
                         <div id="popabout" className="collapse accordian_inner show" aria-labelledby="headingOne" data-parent="#popaccordion">
                            <div className="card-body">
                              {formData.sAboutUs?formData.sAboutUs && renderHTML(formData.sAboutUs):'No data found!'}
                            </div>
                         </div>
                      </div>
                    }
                    {formData.sHealthHygiene &&
                      <div className="card">
                         <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                               <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#pophealtt" aria-expanded="false" aria-controls="pophealtt">
                                Health & Hygiene
                               </button>
                            </h5>
                         </div>
                         <div id="pophealtt" className="collapse accordian_inner" aria-labelledby="headingTwo" data-parent="#popaccordion">
                            <div className="card-body">
                            {formData.sHealthHygiene ?formData.sHealthHygiene && renderHTML(formData.sHealthHygiene):'No data found!'}
                            </div>
                         </div>
                      </div>
                    }
                    { formData.sPlanetHealth &&
                      <div className="card">
                         <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                               <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#popplanet" aria-expanded="false" aria-controls="popplanet">
                              Planet Health Initiatives
                               </button>
                            </h5>
                         </div>
                         <div id="popplanet" className="collapse accordian_inner" aria-labelledby="headingThree" data-parent="#popaccordion">
                            <div className="card-body">
                            {formData.sPlanetHealth?formData.sPlanetHealth && renderHTML(formData.sPlanetHealth):'No data found!'}
                            </div>
                         </div>
                      </div>
                    }
                    </div>
               </div>
              </div>
         </ModalBody>
       </Modal>
  </div>

  );
}
export default withLocalize(withRouter(RestaurantModal));
