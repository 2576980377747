import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import MicrosoftHomePage from "../MicrosoftPage/MicrosoftHomePage/MicrosoftHomePage";
import HomePage from "./HomePage";

const HomePageHandler = ({ history }) => {
    if((window.location.hostname.includes("microsoft")) || (window.location.hostname.includes("mstravel"))) 
    {
        return <MicrosoftHomePage history={history}></MicrosoftHomePage>
    }else{
        return <HomePage history={history}></HomePage>
    }
}
export default withLocalize(withRouter(HomePageHandler));


