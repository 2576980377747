import React from 'react';
import { NavLink, withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux'
import './InspiredPage.css';
import $ from "jquery";

const InspiredPage = ({history}) => {

    return (
        <> 
        <div className='inspiredWrapper'>
            <div className='container'>
                <h3>Get Inspired to Travel Well</h3>
                <div className='inspiredBox'>
                    <div className='row'>
                        <div className='col-12 col-sm-6 col-lg-4'>
                            <div className='card'>
                                <NavLink className="event event-keep-your-immune-system" to={{
                                        pathname : '/articles',
                                        state : {link : "https://www.mymenuusa.com/posts/5-ways-to-keep-your-immune-system-strong-while-traveling"}
                                    }}>
                                    <img src='/static/img/whileTraveling.jpg' alt="whileTraveling" className='travelImg'/>
                                    <div className='card-body'>
                                        <img src='/static/img/whileTraveling.jpg' alt="whileTraveling" className='thumbnailImg'/>
                                        <h4>5 Ways to Keep Your Immune System Strong While Traveling</h4>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4'>
                            <div className='card'>
                                <NavLink className="event event-good-night-sleep-while-traveling" to={{
                                        pathname : '/articles',
                                        state : {link : "https://www.mymenuusa.com/posts/the-secrets-to-a-good-night-s-sleep-while-on-the-road"}
                                    }}>
                                    <img src='/static/img/nightSleep.jpg' alt="travelImg" className='travelImg'/>
                                    <div className='card-body'>
                                        <img src='/static/img/nightSleep.jpg' alt="travelImg" className='thumbnailImg'/>
                                        <h4>The Secrets to a Good Night’s Sleep While Traveling</h4>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-4'>
                            <div className='card'>
                                <NavLink className="event event-healthiest-snacks-when-traveling" to={{
                                        pathname : '/articles',
                                        state : {link : "https://www.mymenuusa.com/posts/the-most-important-nutrient-to-focus-on-for-work-travel"}
                                    }}>
                                    <img src='/static/img/healthiestSnacks.jpg' alt="healthiestSnacks" className='travelImg'/>
                                    <div className='card-body'>
                                        <img src='/static/img/healthiestSnacks.jpg' alt="healthiestSnacks" className='thumbnailImg'/>
                                        <h4>The 15 Healthiest Snacks When Traveling</h4>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );

}

export default withLocalize(withRouter(InspiredPage))
