import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { checkEmailAction } from '../../Actions/User.action';
import { sendEmailAction } from '../../Actions/Utility.action';
import { appConstants, mailTemplate } from '../../../_Constants';
import toaster from '../../../_Common/Toaster';
import { FormError, ShowFormErrors, ShowFormValidErrors } from "../../../_Common/FormError";
import ButtonLoader from '../../../_Common/ButtonLoader';

import './ForgotPasswordPage.css';

const ForgotPasswordPage = ({ history }) => {

    const [formData, setForm] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const { register, errors, getValues, handleSubmit, reset } = useForm();
    const [fieldName, setFieldName] = useState();
    const [buttonActive, setButtonActive] = useState('false');

    /* Set the value in the formData object */
    const formHandler = (e, field) => {
        e.preventDefault();
        let formData1 = formData;
        formData1[field] = e.target.value;
        setForm(formData1);
        setFieldName(field);
    }

    const submitForm = async () => {
        setButtonActive('true');
        let formData1 = formData;
        formData1['sEmail'] = formData1.sEmail;
        let res = await checkEmailAction(formData1);
        let status = res && res.status;

        if (status === 200) {
            /* call the api to send email*/
            if (res && res.data && res.data[0]) {
                await sendEmailFun(res.data[0])
            }
            reset();

        } else {
            if (status == 404) {
                if (res && res.data && res.data[0] && (res.data[0].bEmailConfirmed == false)) {
                    toaster.show({ message: "The email you entered does match any of our records.", intent: "danger" });
                }
            } else {
                if (status === 409) {
                    toaster.show({ message: "The email you entered does match any of our records.", intent: "danger" });
                } else {
                    toaster.show({ message: appConstants.RESPONSECODE[status], intent: "danger" });
                }
            }
            setButtonActive('false');
        }
    }

    const sendEmailFun = async (fData) => {

        if (fData.iEmailCode && fData.sEmail) {
            let link = appConstants.FRONTURL + 'confirm-password/' + fData.iEmailCode + '/' + fData.sEmail;


            var res = (mailTemplate.FORGOTTEN_TEMP).replace("#link", '<a style="color: #ef2859" target="_blank" href="' + link + '">PASSWORD RESET LINK</a>');
            res = (res).replace("#name", fData.sEmail);
            res = (mailTemplate.TEMPLATE).replace("#conetnt", res);

            let senData = {
                'sReceiver': fData.sEmail,
                'sSubject': mailTemplate.FORGOTTEN_SUB,
                'sMessage': res,
            }
            let resData = await sendEmailAction(senData);
            let status = resData && resData.status;

            if (status === 200) {
                setButtonActive('false');
                toaster.show({ message: appConstants.MSGSTATUS.SENDEAMIL, intent: "success" });
            } else {
                setButtonActive('false');
                toaster.show({ message: appConstants.RESPONSECODE[status], intent: "danger" });
            }
        }
    }


    return (
        <div className="App">
            <header className="App-header">
                <div className="landing_wraper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="common_form_sec forgot_password_form">
                                    <div className="" onClick={() => window.location.href = '/login'}>
                                    </div>
                                    <div className="common_form_secinner">
                                        <div className="f_back_btn" onClick={() => window.location.href = '/login'}>
                                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                        </div>

                                        <h1 className="forgot_password">Forgot <br />Password?</h1>
                                        <div className="form_subtext forgot_password">We will send you an email with password reset instruction</div>
                                        <form onSubmit={handleSubmit(submitForm)}>

                                            {ShowFormValidErrors(errors, formErrors, fieldName) &&
                                                <div className="form_error">{ShowFormValidErrors(errors, formErrors, fieldName)}</div>
                                            }

                                            <FormGroup>
                                                <label for="sEmail" className="form-label">Email*:</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="sEmail"
                                                    id="sEmail"
                                                    onChange={e => formHandler(e, 'sEmail')}
                                                    placeholder=""
                                                    ref={register({
                                                        required: "!",
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                            message: "Invalid email address"
                                                        }
                                                    })}
                                                />
                                                <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors, formErrors, "sEmail")}</div>
                                            </FormGroup>
                                            <FormGroup>
                                                <label for="sConfirmEmail" className="form-label">Confirm Email*:</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="sConfirmEmail"
                                                    id="sConfirmEmail"
                                                    onChange={e => formHandler(e, 'sConfirmEmail')}
                                                    placeholder=""
                                                    ref={register({
                                                        required: "!",
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                            message: "Invalid email address"
                                                        },
                                                        validate: {
                                                            matchesPreviousPassword: value => {
                                                                const { sEmail } = getValues();
                                                                return sEmail === value || "Email should match!";
                                                            }
                                                        }
                                                    })}
                                                />
                                                <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors, formErrors, "sConfirmEmail")}</div>
                                            </FormGroup>

                                            <br />
                                            <p>*Required fields</p>

                                            <div className="form-group submit_btn">
                                                {/*<Button className="btntype3 pink_btn">SEND EMAIL</Button>*/}
                                                <ButtonLoader actions={[
                                                    {
                                                        'title': 'SEND EMAIL',
                                                        'loading': 'Loading...',
                                                        'loadingactive': buttonActive,
                                                        'onClick': e => function () { return true },
                                                        'data-type': "SEND EMAIL",
                                                        "className": "btntype3 pink_btn"
                                                    },
                                                ]} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}
export default withLocalize(withRouter(ForgotPasswordPage));
