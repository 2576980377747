export const mailTemplate = {

  VERIFICATION_SUB:'MyMenu® Concierge Account Validation',
  VERIFICATION_TEMP: `<table width="100%" border="0" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="1"></td>
          <td align="center">
            <table width="650" border="0" cellspacing="0" cellpadding="0" className="mobile-shell">
              <tbody>
                <tr>
                  <td className="td" style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; Margin:0">
                    <!-- Top Bar -->
                    <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#fff" className="br-bottom" style="border-radius:0px 0 20px 20px">
                      <tbody>
                        <tr>
                          <td>
                            <div className="fluid-img" style="font-size:0pt; line-height:0pt; text-align:left"><img src="http://demo.ststagingserver.com/mymenu/imgg.jpg" border="0" width="650" height="180" alt=""></div>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                              <tbody>
                                <tr>
                                  <td height="30" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                </tr>
                              </tbody>
                            </table>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="32"></td>
                                  <td>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;">
                                            <p style="margin:0 0 12px 0;">Thank you for registering for MyMenu® Concierge! Please click the following link to verify your email address and activate your account: #link </p>
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;padding-bottom: 3px;">
                                            <p style="margin: 0;">Cheers to your health!</p>
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;">
                                            <p style="margin: 0;">-Healthy Dining Team</p>
                                          </td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                      <tbody>
                                        <tr>
                                          <td height="20" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="32"></td>
                                </tr>
                              </tbody>
                            </table>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                              <tbody>
                                <tr>
                                  <td height="30" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- END Top Bar -->
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="1"></td>
        </tr>
      </tbody>
    </table>`,

  WELCOME_SUB: 'Welcome to MyMenu® Concierge!',
  WELCOME_TEMPL: `<table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="1"></td>
                  <td align="center">
                    <table width="650" border="0" cellspacing="0" cellpadding="0" className="mobile-shell">
                      <tbody>
                        <tr>
                          <td className="td" style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; Margin:0">
                            <!-- Top Bar -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#fff" className="br-bottom" style="border-radius:0px 0 20px 20px">
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="fluid-img" style="font-size:0pt; line-height:0pt; text-align:left"><img src="http://demo.ststagingserver.com/mymenu/imgg.jpg" border="0" width="650" height="180" alt=""></div>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                      <tbody>
                                        <tr>
                                          <td height="30" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                      <tbody>
                                        <tr>
                                          <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="32"></td>
                                          <td>
                                            <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                              <tbody>
                                                <tr>
                                                  <td></td>
                                                  <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 19px;line-height: 22px;font-weight: 600;padding-bottom: 14px;padding-top: 20px;">
                                                    <p style="margin:0;">Hello #name!</p>
                                                  </td>
                                                  <td></td>
                                                </tr>
                                                <tr>
                                                  <td></td>
                                                  <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;">
                                                    <p style="margin:0 0 10px 0;">More than ever, people want nutrition, allergen, and lifestyle diet information (vegan, vegetarian, keto). MyMenu® Concierge was designed by Healthy Dining’s team of dietitians to make it very easy for you to access this information as a personalized menu showing which restaurants and menu items meet your unique preferences. MyMenu empowers you to eat at your best while contributing to a healthier world and planet!</p>
                                                  </td>
                                                  <td></td>
                                                </tr>
                                                <tr>
                                                  <td></td>
                                                  <td height="15" className="spacer" style="text-align: revert;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;padding: 10px 0;">
                                                    <p>Sign in and begin your <b>personalized experience</b> here: #link </p>
                                                  </td>
                                                  <td></td>
                                                </tr>
                                                <tr>
                                                  <td></td>
                                                  <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;padding-bottom: 6px;">
                                                    <p style="margin:0 0 10px 0;">We are here to walk you through every step – <a style="color: #ef2859; cursor:pointer;" href="mailto:support@healthydining.biz">contact us</a> for any assistance, and please let us know if you have feedback to share!</p>
                                                  </td>
                                                  <td></td>
                                                </tr>
                                                <tr>
                                                  <td></td>
                                                  <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;padding-bottom: 3px;">
                                                    <p style="margin:0;">Cheers to your health!</p>
                                                  </td>
                                                  <td></td>
                                                </tr>
                                                <tr>
                                                  <td></td>
                                                  <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;">
                                                    <p style="margin:0;">-Healthy Dining Team</p>
                                                  </td>
                                                  <td></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                              <tbody>
                                                <tr>
                                                  <td height="20" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                          <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="32"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                      <tbody>
                                        <tr>
                                          <td height="30" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!-- END Top Bar -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="1"></td>
                </tr>
              </tbody>
            </table>`,

  FORGOTTEN_SUB: 'MyMenu® Concierge Password Reset',
  FORGOTTEN_TEMP: `<table width="100%" border="0" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="1"></td>
          <td align="center">
            <table width="650" border="0" cellspacing="0" cellpadding="0" className="mobile-shell">
              <tbody>
                <tr>
                  <td className="td" style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; Margin:0">
                    <!-- Top Bar -->
                    <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#fff" className="br-bottom" style="border-radius:0px 0 20px 20px">
                      <tbody>
                        <tr>
                          <td>
                            <div className="fluid-img" style="font-size:0pt; line-height:0pt; text-align:left"><img src="http://demo.ststagingserver.com/mymenu/imgg.jpg" border="0" width="650" height="180" alt=""></div>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                              <tbody>
                                <tr>
                                  <td height="30" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                </tr>
                              </tbody>
                            </table>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="32"></td>
                                  <td>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 19px;line-height: 22px;font-weight: 600;padding-bottom: 14px;padding-top: 20px;">
                                            <p style="margin:0;">Hi #name!</p>
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;">
                                            <p style="margin:0 0 12px 0;">You recently requested to reset the password for your MyMenu® Concierge account. To change your password, please follow this link: #link. </p>
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;padding-bottom: 6px;">
                                            <p style="margin:0 0 12px 0;">If you did not request a password reset, you may disregard this message. If you have any questions or need support, please contact <a style="color: #ef2859; cursor:pointer;" href="mailto:support@healthydining.biz">support@healthydining.biz</a></p>
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;padding-bottom: 3px;">
                                            <p style="margin:0;">Cheers to your health!</p>
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td height="15" className="spacer" style="text-align: left;width:100%;min-width:100%;font-size: 16px;line-height: 24px;color: #333;">
                                            <p style="margin:0;">-Healthy Dining Team</p>
                                          </td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                      <tbody>
                                        <tr>
                                          <td height="20" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="32"></td>
                                </tr>
                              </tbody>
                            </table>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                              <tbody>
                                <tr>
                                  <td height="30" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- END Top Bar -->
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="1"></td>
        </tr>
      </tbody>
    </table>`,


  TEMPLATE: `<table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#e6e6e6" style="font-family: Arial,sans-serif;">
    <tbody>
      <tr>
        <td align="center" valign="top">
          <!-- Header -->
          <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
            <tbody>
              <tr>
                <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="1"></td>
                <td align="center">
                  <table width="650" border="0" cellspacing="0" cellpadding="0" className="mobile-shell">
                    <tbody>
                      <tr>
                        <td className="td" style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; Margin:0">
                          <!-- Header -->
                          <!-- END Header -->
                          <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ef2859" className="br-top" style="border-radius:20px 20px 0px 0px">
                            <tbody>
                              <tr>
                                <td>
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                    <tbody>
                                      <tr>
                                        <td height="18" className="spacer" style="font-size:0pt;line-height:0pt;text-align:center;width:100%;min-width:100%;padding: 10px 0 10px;"><a href="https://www.healthydiningfinder.com/"><img
                                              src="http://demo.ststagingserver.com/mymenu/innerlogo.png" width="150px"></a></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="1"></td>
              </tr>
            </tbody>
          </table>
            #conetnt
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="1"></td>
                <td align="center">
                  <!-- Shell -->
                  <table width="650" border="0" cellspacing="0" cellpadding="0" className="mobile-shell">
                    <tbody>
                      <tr>
                        <td className="td" style="width:650px; min-width:650px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal; Margin:0">
                          <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f7f7f7" style="border-radius:20px">
                            <tbody>
                              <tr>
                                <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="30"></td>
                                <td>
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                    <tbody>
                                      <tr>
                                        <td height="40" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div className="img-center" style="font-size:0pt; line-height:0pt; text-align:center"><img src="http://demo.ststagingserver.com/mymenu/headerlogo.png" border="0" width="190" height="" alt=""></div>
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                    <tbody>
                                      <tr>
                                        <td height="30" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div className="text-footer-center" style="color:#685d5b; font-family:Tahoma, sans-serif; font-size:12px; line-height:22px; text-align:center">8745 Aero Drive Ste. 306 San Diego, CA 92123<br>
                                    <div className="link-footer-u" style="color:#685d5b;margin-right: 13px;">
                                      <span className="link-footer-u" style="color:#685d5b; text-decoration:underline">
                                         <a style="color: #ef2859; cursor:pointer;" href="mailto:hello@healthydining.biz">hello@healthydining.biz</a>
                                      </span>
                                      <span className="link-footer" style="color:#685d5b; text-decoration:none;display: inline-block;margin-left:11px;">
                                        <a style="color: #ef2859; cursor:pointer;" href="tel:+1 858-541-2049">+1 858-541-2049</a>
                                      </span>
                                    </div>
                                  </div>
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                    <tbody>
                                      <tr>
                                        <td height="25" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                      <tr>
                                        <td align="center">
                                          <table className="center" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                              <tr>                                              
                                                <td className="img" style="font-size:0pt; line-height:0pt; text-align:left" width="36"><a href="http://instagram.com/healthydiningfinder" target="_blank"><img
                                                      src="http://demo.ststagingserver.com/mymenu/ico_o2_instagram.jpg" border="0" width="26" height="26" alt=""></a></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">
                                    <tbody>
                                      <tr>
                                        <td height="40" className="spacer" style="font-size:0pt; line-height:0pt; text-align:center; width:100%; min-width:100%">&nbsp;</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="30"></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className="content-spacing" style="font-size:0pt; line-height:0pt; text-align:left" width="1"></td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>`
}
