import React, {useState, useEffect} from 'react';
import { withRouter, useParams } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { resetPasswordPostAction } from '../../Actions/User.action';
import { appConstants } from '../../../_Constants';
import toaster from '../../../_Common/Toaster';
import {FormError,ShowFormErrors, ShowFormValidErrors} from "../../../_Common/FormError";
import ButtonLoader from '../../../_Common/ButtonLoader';
import './ForgotPasswordPage.css';

const ConfirmPasswordPage = ({history}) => {

  const { emailCode, email } =  useParams();
  const [formData,setForm] = useState({});
  const [formErrors,setFormErrors] = useState({});
  const { register, errors, getValues, handleSubmit } = useForm();
  const [fieldName,setFieldName] = useState();
  const [buttonActive,setButtonActive] = useState('false');

  /* Set the value in the formData object */
  const formHandler = (e,field)=>{
    e.preventDefault();
    let formData1 = formData;
    formData1[field] = e.target.value;
    setForm(formData1);
    setFieldName(field);
  }

  const submitForm = async () =>{
     setButtonActive("true");
     let formData1 = formData;
     formData1['sEmail'] = email;
     formData1['iEmailCode'] = emailCode;
     formData1['sPassword'] = formData1.sPassword;
     let res = await resetPasswordPostAction(formData1);
     let status = res && res.status;

     if(status === 200){
         toaster.show({message: appConstants.MSGSTATUS.UPDATE_PASSWORD, intent: "success"});
         setTimeout(function(){
           setButtonActive("false");
             toaster.clear();
             //history.push('/login');
             window.location.href = '/login';
         },1000);
     }else{
         setButtonActive("false");
         toaster.show({message: appConstants.RESPONSECODE[status], intent: "danger"});
     }
   }

  return (
    <div className="App">
      <header className="App-header">
        <div className="landing_wraper ">
           <div className="container">
             <div className="row">
               <div className="col-md-12">
                  <div className="common_form_sec forgot_password_form">
                   <div className="common_form_secinner">
                      <div className="back_btn">
                        <a href="javascript:void(0);"><img src="/static/img/backicon.png" /></a>
                      </div>
                      <h1>Reset <br/>Password?</h1>
                      <form onSubmit={handleSubmit(submitForm)}>
                        { ShowFormValidErrors(errors,formErrors,fieldName) &&
                          <div className="form_error">{ShowFormValidErrors(errors,formErrors,fieldName)}</div>
                        }
                         <FormGroup>
                            <input
                            type="password"
                            className="form-control"
                            name="sPassword"
                            onChange={e=>formHandler(e,'sPassword')}
                            placeholder="Password*"
                            ref={register({ required: "!" })}
                            />
                            <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors,formErrors,"sPassword")}</div>
                         </FormGroup>
                         <FormGroup>
                            <input
                            type="password"
                            className="form-control"
                            name="sConfirmPassword"
                            onChange={e=>formHandler(e,'sConfirmPassword')}
                            placeholder="Confirm Password*"
                            ref={register({
                            required: "!",
                            validate: {
                            matchesPreviousPassword: value => {
                            const { sPassword } = getValues();
                            return sPassword === value || "Passwords should match!";
                            }
                            }
                            })}
                            />
                            <div className="text-danger" xs="12" md="6">{ShowFormErrors(errors,formErrors,"sConfirmPassword")}</div>
                          </FormGroup>
                        <div className="form-group submit_btn">
                          {/*<Button className="btntype3 pink_btn">SUBMIT</Button>*/}
                          <ButtonLoader actions={[
                           { 'title': 'SUBMIT',
                             'loading': 'Loading...',
                             'loadingactive': buttonActive,
                             'onClick': e => function(){return true},
                             'data-type': "SUBMIT",
                             "className": "btntype3 pink_btn"
                           },
                         ]} />
                        </div>
                      </form>
                  </div>
                 </div>
               </div>
             </div>
           </div>
        </div>

      </header>
    </div>
  );
}
export default withLocalize(withRouter(ConfirmPasswordPage));
