let  url = window.location.protocol+"//"+window.location.hostname+"/";
if(window.location.hostname==='localhost'){
	url = "http://"+window.location.hostname+":"+window.location.port+"/";
}

export const appConstants = {

  FRONTURL:url,

  WEB_REST_API_KEY:process.env.REACT_APP_API_KEY,
  WEB_SERVICE_URL:process.env.REACT_APP_API_URL,
  FACEBOOK_APP_ID:process.env.REACT_APP_FACEBOOK_ID,
  GOOGLE_CLIENT_ID:process.env.REACT_APP_GOOGLE_ID,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  WEB_HOST: process.env.REACT_APP_HOST,
  //DEFAULT_ADDRESS:'1016 Tierra Del Rey Chula Vista CA United States 91910',
    DEFAULT_ADDRESS: '92123 San Diego CA United States',
    DIET_ID_API_URL: process.env.REACT_APP_DIETID_API_URL,
    DIET_ID_API_KEY: process.env.REACT_APP_DIETID_API_KEY,

  MSGSTATUS:{
    ADD: "Information saved successfully!",
    UPDATE: "Saved Successfully!",
    VERIFY: "Your account has been verified successfully!",
    LOGIN: "You are logged in successfully!",
    REGISTRATION: "You have successfully verified your account!",
    SENDEAMIL: "An email has been sent to the address you provided.  Please follow thte link in the email to complete your password reset.",
    UPDATE_PASSWORD: "Password reset successfully",
  },

  RESPONSECODE:{
      404: '404 Data does not exist.',
      409: '409 Data already exist',
      500: '500 Internal server error, Please check with Administrator',
	  400: 'The request is invalid'
  },
  nutritionRange:{
    "calories": "0-1250",
    "fat": "0-50",
    "carbs": "0-120",
    "sodium": "0-2000",
    "cholesterol": "0-300",
    "fiber": "0-25",
    "saturatedFat": "0-10",
    "protein": "0-50",
    "sugar": "0-60"
  }

}
