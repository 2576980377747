import React,{useState,useEffect} from 'react';

/**
 * [Get First Name] 
 * @return {[string]}       [Name]
 */
export const GetFirstName = () => {

  const [formData,setFormData1] = useState({});
  const [userName ,setUserName] = useState();

  useEffect(()=>{
      let userDetails = JSON.parse(localStorage.getItem('userDetails'));
      if (userDetails) {
        setFormData1(JSON.parse(localStorage.getItem('userDetails')));
        let name = userDetails.sFirstName.charAt(0).toUpperCase() + userDetails.sFirstName.slice(1)
        setUserName(name)
      }

  },[]);
  
  return (
    <span>
      {userName }
    </span>
  );
}



