import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import './MicrosoftHomePage.css';
import SplashPage from '../../SplashPage/SplashPage';
import HeaderMicrosoftLoginPage from '../HeaderMicrosoftLoginPage/HeaderMicrosoftLoginPage';
import FooterMicrosoftLoginPage from '../FooterMicrosoftLoginPage/FooterMicrosoftLoginPage';

const MicrosoftHomePage = ({ history }) => {

    const [showPage, setPage] = useState(false);

    useEffect(() => {
        document.title = "MyMenuConcierge-MicrosoftHomePage";
        let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        if (userDetails && userDetails.bEmailConfirmed) {
            //history.push('/dashboard');
            window.location.href = '/dashboard';
        }
    }, []);

    return (
        <div className="App">
            {showPage &&
                <SplashPage />
            }
            <HeaderMicrosoftLoginPage></HeaderMicrosoftLoginPage>
            <div className="landing_wraper screen2 microsoft-login-wraper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='loginBtn' onClick={e => window.location.href = '/login'} style={{backgroundColor:'#269FDC'}}>Login</div>
                            <br></br>
                            <div className='loginBtn' onClick={e => window.location.href = '/register'}>Create Account</div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterMicrosoftLoginPage></FooterMicrosoftLoginPage>
        </div>

    );
}
export default withLocalize(withRouter(MicrosoftHomePage));

