import React from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import './SplashPage.css';

const SplashPage = ({ history }) => {

    return (
        <div className="landing_wraper landing_page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img alt='landinglogo' src="/static/img/landinglogo.png" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withLocalize(withRouter(SplashPage));
