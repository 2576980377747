import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';

const ButtonLoader = ({actions}) => {

  const [isLoading, setIsLoading] = useState([]);
  const [key, setKey] = useState([]);

  const handleClick =(e)=> {
    let {key} = e.target.dataset;
    let isLoading1 = isLoading;
    isLoading1[key] = 'true';
    actions[key].onClickFun(e);
    setIsLoading({...isLoading,...isLoading1});
    setKey({key})
  }

  useEffect(()=>{
    let isLoading1 = isLoading;
      actions.map(function(key, index) {
        isLoading1[index] = 'false';
    });
    setIsLoading({...isLoading,...isLoading1});
  },[]);

  return (
    <span className="addProjectPopupBottomBtnBox">
      {actions.map(function(key, index) {
      return (
        <button
        disabled={isLoading[index] && key.loadingactive==='true'}
        type={(key.loadingactive==='true')?'button':'submit'}
        onClick={!isLoading[index] || key.loadingactive==='false'?handleClick: null}
        key={index}
        id={index}
        data-key={index}
        {...key}
        >
        {isLoading[index] && key.loadingactive==='true'? key.loading : key.title}
        </button>)
      })}
    </span>
  )
};

export default withLocalize(withRouter(ButtonLoader));
