import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import Geocode from "react-geocode";
import { appConstants } from '../_Constants';

var componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
        lat:"lat",
        lng:"lng"
};

const LocationPickerExample = (props) => {
  const [status,setStatus] = useState('true');

  // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  Geocode.setApiKey(appConstants.GOOGLE_API_KEY);

  const showLocation = (position)=>{
    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
      response => {
        const address = response.results[0];
        let {geometry} = address;
        let {location} = geometry;
        var addressString = '';
        for (var i = 0; i < address.address_components.length; i++) { var
        addressType = address.address_components[i].types[0];
            var val = address.address_components[i][componentForm[addressType]];
            if(val !== undefined){
              addressString +=  " "+val;
            }
        }   
        props.parentCallback({addressString,location});
       
      },
      error => {
        console.error("ShowLocation Error",error);
      }
    );
    setStatus('false');
  }

    useEffect(() => {
        //if (navigator.geolocation) {
        //    alert('true');
        //}
        //else {
        //    alert('false');
        //}
        let prvPagePath = localStorage.getItem("prvPath");
        let local_address = JSON.parse(localStorage.getItem('local_address'));
        if(local_address && local_address.addressString!= null && local_address.addressString !='' )
        {
          local_address.store_addr = false;
          props.parentCallback(local_address);
        }
        else if (prvPagePath.includes("restaurant/")) {
            let filterOption = JSON.parse(localStorage.getItem("filterOption"));
            let formDataOption = JSON.parse(localStorage.getItem("formDataOption"))
            formDataOption.store_addr = false;
            if (formDataOption != null) {
              if (formDataOption.addressString != null && formDataOption.addressString != '') {
                props.parentCallback(formDataOption);
                    //console.log("formDataOption", formDataOption)
                    // setPrice(filterOption["oLocationsModel.sPrice"])
                    // setForm(formDataOption);
                    // handleChangeAddress(formDataOption)
                    // setCuisines(filterOption["oLocationsModel.sCuisines"])
                    // setDisatnce(filterOption["oLocationsModel.iDistance"])
                }
            }
        }
        else {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (res) {
                    showLocation(res)
                }, function () {
                    let jobLoc = appConstants.DEFAULT_ADDRESS;
                    //props.parentCallback({addressString:jobLoc,location:{lat:32.6370621,lng:-117.0204107}});
                    props.parentCallback({ addressString: jobLoc, location: { lat: 32.8102534, lng: -117.1323579 }, store_addr :false });
                });
            }
            else {
                let jobLoc = appConstants.DEFAULT_ADDRESS;
                props.parentCallback({ addressString: jobLoc, location: { lat: 32.8102534, lng: -117.1323579 }, store_addr :false  });
            }
      }
  }, []);
  
  return (
      <span>
        {props.currentValue}
      </span>

  );
}

export default withLocalize(withRouter(LocationPickerExample));