import React from "react"

const MSTermsConditions = () => {
    return (

        <div className="modal fade terms_conditions" id="MSTnC" tabIndex="-1" role="dialog" aria-labelledby="TnCModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title modal-title-landing">PRIVACY POLICY</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                     
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>TERMS AND CONDITIONS</h3>
                        <p>
                            The following terms ("Terms of Use") constitute an agreement between Accents on
                            Health, Inc. DBA Healthy Dining ("Company"), and you that governs your use of this
                            MyMenu Concierge digital platform (“MyMenu” “MyMenu Concierge” “Digital Platform” or
                            “Service” and all of its associated services, content, and functionality. This policy applies
                            to the Service located at <a href="https://www.MSTravel.MyMenuConcierge.com">https://www.MSTravel.MyMenuConcierge.com</a>. Company,
                            owner and operator of MyMenu Concierge, is a corporation formed under the laws of the
                            state of California, United States.
                        </p>
                        <p>
                            Your use of the MyMenu constitutes your acceptance of, and agreement to, the
                            following Terms of Use. Company reserves the right to modify, alter, amend, or update
                            its Service, policies, and these Terms of Use. These Terms of Use are subject to change
                            without notice. If you do not agree with or do not accept any part of these Terms of
                            Use, you must not use the Service. Your continued use of any part of the Service
                            constitutes your acceptance of such changes to the Terms of Use. You should review the
                            Terms of Use periodically to determine if any changes have been made. Company will
                            make a reasonable attempt to notify you of any changes through email and/or SMS
                            messaging.
                        </p>
                        <p>
                            By accessing MyMenu you will be entering a Microsoft third party site. Your opt-in to use
                            MyMenu and participate in the pilot remains voluntary.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>REGISTRATION & RESTRICTED ACCESS</h3>
                        <p>
                            Access to certain areas of the Digital Platform may be restricted. Company reserves the
                            right to restrict areas of the access at its sole discretion. This could mean some areas
                            may have an extra charge or be in testing or directed to a certain type of food
                            preference or health status, or any other reason.
                        </p>
                        <p>
                            When you register, you are required to create a user profile, which includes a Microsoft
                            email as the username and a password you create ("User Account"). You agree to keep
                            your username and password confidential. If you suspect your password has been
                            compromised, you must notify Company immediately at Support@HealthyDining.biz.
                            Company will not be liable for any loss caused by the unauthorized use of your User
                            Account; however, you may be liable to Company or other third parties for any losses
                            incurred due to such unauthorized use.
                        </p>
                        <p>
                            When you create a User Account with Company, you guarantee that you are 18 years of
                            age or older, are able to consent to these Terms of Use, and that the information you
                            provide to Company is accurate, complete, and current at all times. Violation of this
                            paragraph may result in the immediate termination of your User Account, in Company’s
                            sole discretion.
                        </p>
                        <p>
                            Company may disable your username and password at its sole discretion, refuse to
                            register a user for the Services, remove or edit any content contributed to the Digital
                            Platform or cancel any User Account. Company may, without notice, refuse access to its
                            Service or Products, in whole or part, to any person who fails to comply with these
                            Terms of Use.
                        </p>
                        <p>
                            Company reserves the right to modify methods for registration and access levels of
                            registered users from time to time.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>LICENSE FOR USE OF MEMBERSHIP</h3>
                        <p>
                            The Service on MyMenu Concierge was developed solely for your personal use and may
                            not be reproduced for publication or for the personal or commercial use of others
                            without permission. You may not create any derivative works of the Service. When you
                            purchase or use MyMenu Concierge, you agree that you will not use any information you
                            gain from MyMenu Concierge to create any product or service, whether offered for
                            commercial or personal use, without the express written consent of the Company. All
                            inquiries for use of Company intellectual property must be submitted to
                            Frank@HealthyDining.biz. Company reserves the right to seek equitable and
                            compensatory relief for any violation of this term.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>GENERAL DISCLAIMER</h3>
                        <p>
                            MyMenu is provided in collaboration between Healthy Dining, participating restaurants,
                            and carefully vetted health, nutrition, performance and fitness experts. The Digital
                            Platform and Service is intended to support and guide you in menu item selections for
                            overall wellness. It is important to understand that MyMenu does not provide medical or
                            nutritional advice – you are responsible, in cooperation with your physician, dietitian
                            and/or other health consultants, for making personal food and health decisions.
                        </p>
                        <p>
                            Company makes every effort to ensure that all information is of highest integrity and
                            accuracy. However, because of factors outside of Company’s control, Company can
                            make no guarantees of the accuracy and/or regarding the results that you will gain from
                            using the information provided on the Digital Platform and/or Service. Articles and
                            content is provided by carefully vetted health and nutrition professionals, however,
                            pinions, advice, statements or other comments should not necessarily be relied upon
                            and are not to be construed as professional advice from Company. Each individual has
                            unique health needs, goals, and food preferences, and therefore you must consult your
                            own experts and practitioners to guide your health strategy and goals.
                        </p>
                        <p>
                            Company disclaims liability for incidental or consequential damages and assumes no
                            responsibility or liability for any loss or damage suffered by any person as a result of use
                            of the information provided on the Digital Platform and/or Service.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>NUTRITION DISCLAIMER</h3>
                        <p>
                            All nutrition information provided on the Digital Platform and Service is intended to be
                            used for informational purposes only. Content related to nutrition is not medical advice
                            nor is it intended to replace medical advice. The Digital Platform and Service is not
                            intended to diagnose, prescribe, or treat any disease, condition, illness, or injury. Before
                            beginning any health-related program, modifying your diet, or making changes an
                            eating style, including following the nutrition information available on the Digital
                            Platform and/or Service, you should seek advice from a licensed professional.
                        </p>
                        <p>
                            Company is not responsible for any liability, loss, or damage, caused or alleged to be
                            caused directly or indirectly as a result of the use, application, or interpretation of the
                            nutrition information available on the Digital Platform and Service.
                        </p>
                        <p>
                            The Food and Drug Administration has not evaluated the statements contained in any
                            information on the Digital Platform and Service. Individual results vary.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>‘MADE WITHOUT’ & VEGAN, VEGETARIAN, KETO & ALL OTHER EATING
                            STYLES DISCLAIMER</h3>
                        By using the personalization features within the Digital Platform and Service, you agree
                        to and understand the following:
                        <p>
                            MyMenu is provided to support and guide you in menu item selections. It is important to
                            understand that MyMenu does not provide medical or nutritional advice – you are
                            responsible, in cooperation with your physician, dietitian and/or other health
                            consultants, for making personal food and health decisions.
                        </p>
                        <p>
                            Company understands the difficulties associated with consideration of food allergies,
                            intolerances and avoidance of certain ingredients when eating out. As health and
                            nutrition professionals, Company aims to provide highly accurate information that can
                            help those with food allergens, intolerances and restrictive eating styles to eat
                            safely. However, due to 1) cross contact, 2) ingredient substitutions, 3) lack of
                            restaurant staff training and knowledge, and 4) human error circumstances – all of
                            which are beyond our control, Company and each participating restaurant is <b>not able
                                to guarantee that any menu item is completely free from any particular
                                allergen or ingredient and thus, we assume no liability. Therefore, by
                                using MyMenu, you acknowledge this risk and assume responsibility for
                                ensuring the following four precautionary measures are taken by you:</b>
                        </p>
                        <ul>
                            <li>
                                1. Set your preferences on the ‘Personalize Your Preferences’ section on the home
                                screen of MyMenu Concierge by selecting any of the food allergens, ingredients
                                or eating styles provided. Be aware that cross contact, ingredient substitutions,
                                lack of staff training and knowledge, and human error may occur so you must
                                follow the next three cautionary measures.
                            </li>
                            <li>
                                2. After Personalizing Your Preferences and receiving your restaurant and menu item
                                recommendations, review the recommendations and be sure to note any
                                “Personalized Ordering Instructions.” These instructions are provided to help
                                you customize your order to match your Preferences, as you have specified.
                                Thus, if you have set your Preference as “Made without Eggs,” your search results
                                will show menu items that are either 1) made without eggs as the standard
                                preparation or 2) can be customized and ordered to be made without eggs. For
                                example, a Garden Salad with Grilled Chicken may be one of the results. Next, if
                                you click on the salad to personalize it further (for example, size, toppings,
                                dressing, etc.), a X symbol will be displayed next to any add-on ingredient that
                                contains egg, preventing you from selecting that option (for example, X Hard-
                                Boiled Egg or X Creamy Caesar Dressing) . In addition to receiving search results
                                for item results that do not contain egg, you will also receive ‘Personalized For
                                You’ results. These results instruct you on how to modify and order an eggcontaining
                                dish so that it is ‘Made without Eggs. ‘For example, Pad Thai with
                                Shrimp comes with egg already scrambled into the dish but the restaurant allows
                                for you to request the item without egg. So, for this item you would see the
                                phrase ‘Personalized For You’. When you click on the menu item, it will show the
                                Your Personalized Order: Order with ‘No Egg.’ It’s important to note
                                that when provided with a ‘Personalized For You’ order, you must place
                                your order based on the instructions provided. Also it is important to
                                know that any ingredient with the X contains the ingredient you have
                                selected to avoid.
                            </li>
                            <li>

                                3. Before placing your food order, explain to the restaurant manager and the person
                                taking your order that your order must be modified or specially prepared
                                due to a food allergy or dietary restriction. We recommend informing the
                                restaurant even if the meal does not contain the allergen, so that the restaurant is
                                aware to further prevent cross contact, ingredient substitutions and human error.
                                If you are ordering online, be sure to add your “Personalized Ordering
                                Instructions” with your order, in a format such as:
                                “NOTE: This is a food allergy order – Must be prepared without (food
                                allergen).” You should also call the restaurant and tell them you have placed a
                                digital order and make sure they follow the instructions.
                            </li>
                            <li>

                                4. After receiving your order, confirm with the restaurant personnel, once again, that
                                your order was prepared according to your ordering modifications. Remind them
                                that this is due to a food allergen, intolerance or restricted eating style and it is
                                very important that your meal was prepared as you directed. Confirm the
                                restaurant personnel are confident that your meal was in fact prepared as you
                                had directed.
                            </li>

                        </ul>
                        <p>
                            It’s important to understand that the nutrition, allergen and/or gluten information
                            presented on the MyMenu platform is based on information provided to each
                            restaurant from food manufacturers and ingredient suppliers. Every restaurant menu is
                            diverse and contains a wide variety of foods and ingredients, many of which contain one
                            or more of the eight major allergens. Ingredients or production and processing methods
                            used by suppliers may vary or change without notice and there may be product
                            differences among regional suppliers. Additionally, normal kitchen operations involve
                            shared cooking and preparation areas, and cross-contact with other foods may occur
                            during production and ingredients may need to be substituted at any time.
                        </p>
                        <p>
                            By using the Digital Platform and Service, you agree to defend, indemnify, and hold
                            Healthy Dining and all restaurants featured on the MyMenu platform, and their officers,
                            directors, employees, agents, licensors, and suppliers, harmless from and against any
                            claims, actions or demands, liabilities and/or settlements including without limitation,
                            legal fees.
                        </p>
                        <p>

                            The Allergen information and/or terms and conditions may be revised and updated at
                            any time. Your continued use of the Digital Platform and Service means you review this
                            disclaimer each time you use the Digital Platform and Service, and that you understand
                            and accept any changes.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>YOUR RESPONSIBILITY</h3>
                        <p>
                            MyMenu was developed strictly for wellness and informational purposes. You understand
                            and agree that you are fully responsible for your use of the information provided on the
                            Digital Platform and Service. Company makes no representations, warranties or
                            guarantees. You understand that results may vary from person to person. Although
                            Company prioritizes accurate and high integrity information, Company cannot assume
                            any responsibility for errors or omissions that may appear on the Digital Platform and
                            Service.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>USE OF THE SERVICE</h3>
                        <p>
                            Unless otherwise stated, Company owns the intellectual property and rights to all
                            content and material on the Digital Platform including all data, information, content,
                            features, design, and any other components of the Service. Subject to the license below,
                            all intellectual property rights are reserved.
                        </p>
                        The following uses are not permitted:
                        <ul>
                            <li>
                                • Republication of content, whether for personal or commercial use, unless you
                                request and receive written approval from Company representative; send request
                                to Hello@HealthyDining.biz with “Content Republication” in the Subject line;
                            </li><li>
                                • Sale, rental or sub-license of any content from the Service;
                            </li><li>
                                • Modification of any content on the Digital Platform or Service, unless content is
                                specifically and expressly made available for modification;
                            </li><li>
                                • Redistribution of content;
                            </li>
                        </ul>
                        <p>
                            You must not use the Digital Platform or Service in a way that causes, or may cause,
                            damage to the Digital Platform, Service or Company – or in which impairs the availability
                            of access to the Digital Platform or Service. You must not decompile, reverse engineer,
                            disassemble or otherwise reduce the Digital Platform or Service. You must not use the
                            Digital Platform or Service to copy, store, host, transmit, send, use, publish or distribute
                            any material that consists of (or is linked to) any spyware, computer virus, Trojan horse,
                            worm, keystroke logger, rootkit and/or other harmful code or malicious software.
                            You must not conduct any systematic or automated data collection activities, including,
                            but not limited to scraping, data mining, data extraction or data harvesting on or in
                            relation to the Digital Platform or Service.
                        </p>
                        <p>
                            You must not use the Digital Platform or Service to transmit or send any unsolicited
                            commercial communications.
                        </p>
                        <p>
                            You must not use the Digital Platform or Service for any third-party marketing without
                            Company's express written permission.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>INTELLECTUAL PROPERTY</h3>
                        <p>
                            All original materials provided by Company are owned by Company. Any original
                            materials are provided for your individual use only. You are not authorized to use or
                            transfer any of Company's intellectual property. All intellectual property remains the
                            property of Company. No license to sell, distribute, reproduce, prepare a derivative
                            work, display, or perform is granted or implied.
                        </p>
                        <p>
                            Certain of the names, logos, and other materials displayed on the Digital Platform or
                            Service constitute Company's intellectual property, including, but not limited to, patents,
                            trademarks, service marks, trade secrets and copyrights ("Company IP"). You are not
                            authorized to use any Company IP without Company's express consent. Ownership of
                            Company IP remains with Company, and you agree not to make any claims or assertions
                            of any other party's ownership of Company IP.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>EQUITABLE RELIEF</h3>
                        <p>
                            You acknowledge and agree that in the event of certain breaches of the Terms of Use,
                            Company may suffer irreparable injury, such that no remedy at law will afford it
                            adequate protection against, or appropriate compensation for, such injury. Accordingly,
                            you agree that Company shall be entitled to any injunctive relief, without having to post
                            a bond, as may be granted by a court of competent jurisdiction.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>COPYRIGHT</h3>
                        <p>
                            Unless otherwise noted, the design, content and all components of the Digital Platform
                            and Service are copyrights owned by Company or third parties and are protected by
                            United States and international copyright laws and must not be reused or republished
                            without express written permission.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>TRADEMARKS</h3>
                        <p>
                            "MyMenu" is a registered service and trademark of Company and is protected by United
                            States trademark law. Company's trademarks and trade dress may not be used in
                            connection with any product or service that is not Company's, in any manner likely to
                            cause confusion among consumers, or in any manner that disparages or discredits
                            Accents on Health, Inc. DBA Healthy Dining,
                            <a href="https://www.MSTravel.MyMenuConcierge.com/">https://www.MSTravel.MyMenuConcierge.com/</a>,
                            <a href="https://MyMenuConcierge.com/">https://MyMenuConcierge.com/</a>,
                            <a href="https://MyMenuUSA.com">https://MyMenuUSA.com</a>, or any other current or future products or Services of
                            Company or experts featured on the Digital Website and/or through the Service.
                        </p>
                        <p>
                            From time to time, the Digital Platform and Service will legally utilize trademarks owned
                            by third parties related to Company's services. These trademarks are the respective
                            property of their owners.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>COMMENT POLICY</h3>
                        The Digital Platform may offer the option for you to leave comments and/or engage with
                        Company sta. The following types of comments will not be tolerated and will be deleted:
                        <ul>
                            <li>
                                • harassment directed toward any content creator or Company;
                            </li>
                            <li>
                                • spam;
                            </li>
                            <li>
                                • hate speech;
                            </li>
                            <li>
                                • defamatory to Company or any third party;
                            </li>
                            <li>
                                • reference illegal acts; or,
                            </li>
                            <li>
                                • violate the legal rights of a third party.
                            </li>
                        </ul>
                        <p>
                            Company's sole discretion will be used to determine if a comment is in violation of this
                            comment policy. Any comments in violation will be promptly deleted and no further
                            explanation will be due to you if your comment was determined to be in violation with
                            this policy.
                        </p>
                        <p>
                            You are, and shall remain, solely responsible for the any content you upload, submit,
                            post, transmit, communicate, share, or exchange regarding the Digital Platform or
                            Service and must abide by this Comment Policy. Company reserves the right to report to
                            the appropriate authority any post, comment, message or participant in the Membership
                            that Company deems, in its sole discretion, may implicate the safety of either a
                            participant or a third-party.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>TAKEDOWN REQUESTS</h3>
                        <p>
                            From time to time, the Digital Platform or Service will publish posts with images from
                            other third-party websites. Any such use is considered fair use under copyright laws and
                            is fully attributed to the owner. If you believe that your copyrighted work has been used
                            on the Digital Platform in a way that constitutes copyright infringement and falls outside
                            of fair use, please send a request to Frank@HealthyDining.biz and we will remove the
                            image within 24 to 48 hours.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>COMMUNICATION</h3>
                        <p>
                            Company reserves the right to contact you through an email, SMS text, and/or
                            messaging through the Comm chat feature on the Digital Platform. By using the
                            MyMenu Concierge Digital Platform and Service, you consent to receive communications
                            from Company through email, SMS text, or Chat messaging. You agree that all legal
                            notices provided via these means from Company satisfy any requirement for written
                            notice.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>THIRD PARTIES</h3>
                        <p>
                            The Service may contain links to third-party websites that are not governed or controlled
                            by Company. You represent and warrant that you have read and agree to be bound by
                            all applicable terms of use and policies for any third-party website that relates to your
                            use of the Digital Platform or Service. Company assumes no control or liability over the
                            content of any third-party site. You expressly hold Company harmless from any and all
                            liability related to your use of a third-party website.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>CHILDREN'S INFORMATION</h3>
                        <p>
                            The Digital Platform or Service do not knowingly collect any personally identifiable
                            information from children under the age of 16. If a parent or guardian believes that the
                            Digital Platform or Service has personally identifiable information of a child under the
                            age of 16 in its database, please contact Company immediately at
                            Frank@HealthyDining.biz and we will use our best efforts to promptly remove such
                            information from our records.
                        </p>

                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>NO WARRANTIES</h3>
                        <p>
                            The Digital Platform or Service is provided on an "as is" and "as available" basis without
                            any representations or warranties, expressed or implied. Company makes no
                            representations or warranties in relation to the Digital Platform or Service, or the
                            information and materials provided therein.
                        </p>
                        <p>
                            Company makes no warranty the Digital Platform or Service will meet your
                            requirements; will be available uninterrupted; error free, timely and free of viruses or
                            bugs; or represents full functionality, accuracy, and/or reliability. Company is not
                            responsible to you for the loss of any content or material uploaded or transmitted
                            through the Digital Platform or Service. The Service is provided in English and Company
                            makes no warranty regarding translation or interpretation of content in any language.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>SERVICE LEVEL</h3>
                        <p>
                            Given the cloud-based nature of the Digital Platform or Service, Company cannot
                            guarantee service at all times. Company endeavors to provide a service level of 99%
                            however, Company shall not be liable for any failure to meet this service level and no
                            refunds shall be granted for such failure. If the Membership will not be available due to
                            any pre-scheduled maintenance, Company will provide notice when reasonably able to
                            do so.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>LIMITATION OF LIABILITY</h3>
                        <p>
                            TO THE EXTENT ALLOWABLE BY LAW, COMPANY AND ITS OFFICERS, EMPLOYEES,
                            AGENTS, AFFILIATES, LICENSEES AND WEB HOSTING SERVICES WILL NOT BE LIABLE
                            FOR ANY DIRECT, CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, PUNITIVE
                            OR SPECIAL DAMAGES OF ANY KIND, HOWEVER CAUSED, INCLUDING LOSS OF
                            PROFITS, REVENUE, DATA OR USE, INCURRED BY YOU, WHETHER UNDER THEORY OF
                            CONTRACT, TORT (INCLUDING NEGLIGENCE), WARRANTY OR OTHERWISE, EVEN IF
                            COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>INDEMNITY</h3>
                        <p>
                            YOU HEREBY AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS COMPANY, ITS
                            AFFILIATES AND SUBSIDIARIES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS,
                            EMPLOYEES, AGENTS, SUCCESSORS, ADMINISTRATORS AND ASSIGNS (THE
                            "INDEMNIFIED PARTIES") FROM AND AGAINST ALL LIABILITIES, CLAIMS, ACTIONS,
                            CAUSES OF ACTION, COSTS AND EXPENSES (INCLUDING LEGAL EXPENSES ON A FULL
                            INDEMNITY BASIS AND ANY OTHER FEES AND EXPENSES INCURRED FOR
                            INVESTIGATING OR DEFENDING ANY ACTION OR THREATENED ACTION, AS WELL AS
                            SETTLEMENT COSTS) WHICH ANY OF THE INDEMNIFIED PARTIES MAY SUFFER OR
                            INCUR IN CONNECTION WITH ANY CLAIM ARISING OUT OF OR RESULTING FROM:(I)
                            YOUR VIOLATION OF ANY OF THESE TERMS, (II) YOUR MISUSE OF THE DIGITAL
                            PLATFORM AND/OR SERVICES, INCLUDING BUT NOT LIMITED TO YOUR PERSONAL
                            INJURY OR DEATH, (III) YOUR MISUSE OF ANY LINKED SITES, (IV) YOUR VIOLATION
                            OF ANY LAW OR THIRD PARTY'S RIGHTS.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>ARBITRATION</h3>
                        <p>
                            The Terms of Use will be governed and construed in accordance with the laws of the
                            state of Florida without reference to its conflict of law provisions. Any controversy or
                            claim arising out of or relating to the Digital Platform, Service, Membership, Terms of
                            Use, or the breach thereof, shall be settled by arbitration administered by the American
                            Arbitration Association ("AAA") under its Commercial Arbitration Rules, and judgment on
                            the award rendered by the arbitrator(s) may be entered in any court having jurisdiction
                            thereof. The place of any such arbitration shall be decided by Company. The parties also
                            agree that the AAA Optional Rules for Emergency Measures of Protection shall apply to
                            the proceedings. This section provides the sole recourse for the settlement of any
                            disputes arising out of, in connection with, or related to the Digital Platform, Serviceor
                            Terms of Use. Notwithstanding the foregoing, any action seeking injunctive relief shall
                            be submitted to the courts and shall not be subject to this provision.
                        </p>
                        <p>
                            THE PARTIES WAIVE ANY RIGHT TO ASSERT ANY CLAIMS AGAINST THE OTHER PARTY
                            AS A REPRESENTATIVE OR MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION,
                            EXCEPT WHERE SUCH WAIVER IS PROHIBITED BY LAW OR DEEMED BY A COURT OF
                            LAW TO BE AGAINST PUBLIC POLICY. TO THE EXTENT EITHER PARTY IS PERMITTED
                            BY LAW OR A COURT OF LAW TO PROCEED WITH A CLASS OR REPRESENTATIVE
                            ACTION AGAINST THE OTHER, THE PARTIES AGREE THAT: (I) THE PREVAILING PARTY
                            SHALL NOT BE ENTITLED TO RECOVER ATTORNEYS’ FEES OR COSTS ASSOCIATED
                            WITH PURSUING THE CLASS OR REPRESENTATIVE ACTION (NOT WITHSTANDING ANY
                            OTHER PROVISION IN THIS AGREEMENT); AND (II) THE PARTY WHO INITIATES OR
                            PARTICIPATES AS A MEMBER OF THE CLASS WILL NOT SUBMIT A CLAIM OR
                            OTHERWISE PARTICIPATE IN ANY RECOVERY SECURED THROUGH THE CLASS OR
                            REPRESENTATIVE ACTION.
                        </p>
                        <h3 style={{ textDecoration: "underline", fontWeight: "bolder" }}>MISCELLANEOUS PROVISIONS</h3>
                        <p>
                            If any provision(s) of the Terms of Use is held to be invalid, illegal or unenforceable, the
                            remaining provisions shall be severable and enforceable. In addition, in such event the
                            unenforceable or invalid provision shall be deemed to be modified to the extent
                            necessary to (i) render it valid and enforceable and (ii) give the fullest effect possible to
                            the original intent of the provision.
                        </p>
                        <p>
                            The Terms of Use may not be assigned by you without Company's prior written consent;
                            however, the Terms of Use may be assigned by Company in its sole discretion.
                            The Terms of Use are the final, complete and exclusive agreement of the parties with
                            respect to the Digital Platform and Service offered by Company.
                        </p>
                        <p>
                            The failure of Company to exercise or enforce any right or provision hereunder shall not
                            operate as a waiver of such right or provision. Any waiver of the Terms of Use by
                            Company must be in writing and signed by an authorized representative of the
                            Company.
                        </p>
                        <p>
                            All notices with respect to the Terms of Use must be in writing and may be via email to
                            Frank@HealthyDining.biz for Company and to your email address.
                        </p>
                        <p>
                            Last updated: September 16, 2022.
                        </p>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MSTermsConditions