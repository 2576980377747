import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from "react-router-dom";
import './HeaderMicrosoftPage.css';
import { withLocalize } from 'react-localize-redux';
import { GetFirstName } from '../../../../_Common/CommonComponent';
import { getMyMealAction } from '../../../Actions/Utility.action';
import { MYMEAL_COUNT } from '../../../Actions/actionTypes';
import { appConstants } from '../../../../_Constants';
import $ from "jquery";


const HeaderMicrosoftPage = ({ history, headermobileclassName, headerName, setFormData }) => {

  const dispatch = useDispatch()
  const [formData, setFormData1] = useState(setFormData);
  const [userMSPilot, setUserMSPilot] = useState(false)
  const myMealCount = useSelector(state => {
    return state.counters.myMealCount;
  })

  /* Open the sidebar menu for mobile view */
  const openNav = (e) => {
    e.preventDefault()
    document.getElementById("mySidepanel").className = 'sidepanel open';
    document.getElementById("body").className = 'openmenu';
  }

  const openMSNav = (e) => {
    e.preventDefault()
    document.getElementById("msSidePanel").className = 'sidepanel open';
    document.getElementById("body").className = 'openmenu';
  }

  /* Close sidebar menu for mobile view */
  const closeNav = (e) => {
    e.preventDefault()
    document.getElementById("mySidepanel").className = 'sidepanel close';
    document.getElementById("body").className = '';
  }

  const closeMSNav = (e) => {
    e.preventDefault()
    document.getElementById("msSidePanel").className = 'sidepanel close';
    document.getElementById("body").className = '';
  }

  const logout = (e) => {
    e.preventDefault()
    localStorage.clear();
    //history.push('/');
    window.location.href = '/';
  }

  let userData = JSON.parse(localStorage.getItem('userDetails'));

  /*const getMyMeal = async() => {
    let userDetailsData = localStorage.getItem("userDetails");
    if(userDetailsData){
      let userDetails = JSON.parse(userDetailsData);

      let data = {
        eLoginProvider: userDetails.sLoginProvider,
        sProviderKey: userDetails.sProviderKey
      };

      let res = await getMyMealAction(data);
      if(res && res.status == 200 && res.data.length > 0 && res.data[0].Meals){
        dispatch({type:"MYMEAL_COUNT", data: res.data[0].Meals.length});
      }
    }
  }*/

  useEffect(() => {
    if (localStorage.getItem('pagePath')) {
      localStorage.setItem('prvPath', localStorage.getItem('pagePath'))
      localStorage.setItem('pagePath', history.location.pathname)
    } else {
      localStorage.setItem('pagePath', history.location.pathname)
    }
    if(
      localStorage.getItem('prvPath') 
      && !history.location.pathname.includes('restaurant') 
      && !history.location.pathname.includes('menu-item')
    ) 
    {
      localStorage.removeItem('local_address');
    }
    setFormData1(JSON.parse(localStorage.getItem('userDetails')));

    let isMSPilot = JSON.parse(localStorage.getItem('userMSPilot'));

    setUserMSPilot(isMSPilot ? isMSPilot : false)

    //getMyMeal();

  }, []);


  /******* Go To Restaurant Modal *******/

  const [restaurantModal, setRestaurantModal] = useState(false);

  const toggleRestaurantsModal = () => {
    setRestaurantModal(!restaurantModal);
}



const checkDate = ()=>{
        window.location.href = '/restaurants-list'
}


  return (
    <header className={headermobileclassName + " site_header"}>

      <div id="mySidepanel" className="sidepanel">
        <div className="closebtn" onClick={e => closeNav(e)}>×</div>
        <ul className="mobilememu">
          <li><div onClick={e => window.location.href = '/landing'}>Home</div></li>
          <li><div onClick={e => window.location.href = '/msdashboard'}>EAT: Preference & Restaurants</div></li>
          <li><div onClick={e => cronometer()}>TRACK</div></li>
          {/*<li><div onClick={e => window.open("https://www.mymenuusa.com/healthy-tips-and-recipes", "_blank")}>GET INSPIRED</div></li>*/}
          <li><div onClick={e => window.open("https://www.mymenuinspired.com", "_blank")}>GET INSPIRED</div></li>
          <li><div onClick={e => window.open("https://www.mymenusuperkids.com", "_blank")}>HEALTHY KIDS</div></li>
          {/*<li><div onClick={e => window.location.href = '/landing'}>Home</div></li>
                   * <li><div onClick={e => window.location.href = '/restaurants-list'}>Restaurants</div></li>
                  <li><div onClick={e => window.location.href = '/subscription'}>Subscription</div></li>*/}
          <li><div onClick={e => window.location.href = '/profile'}>Profile</div></li>

          <li className="header-navigation-modified"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Contact Us">Contact Us</a></li>
          <li className="header-navigation-modified"><a target="" data-toggle="modal" data-target="#PrivacyModal">Privacy Policy</a></li>
          <li className="header-navigation-modified"><a target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a></li>

          {/*<li className="header-navigation"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Contact Us">Contact Us</a></li>
                  <li className="header-navigation"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Suggest a Restaurant">Suggest a Restaurant</a></li>
                  <li className="header-navigation"><a target="_blank" href="mailto:hello@healthydining.biz?subject=Offer Feedback">Offer Feedback</a></li>*/}
          <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}>Contact Us</div></li>
          <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}>Suggest a Restaurant</div></li>
          <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}>Offer Feedback</div></li>
          <li className="header-navigation"><a target="" data-toggle="modal" data-target="#PrivacyModal">Privacy Policy</a></li>
          <li className="header-navigation"><a target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a></li>
          <li><div className="logout" onClick={e => logout(e)}>Logout</div></li>
        </ul>
        <div className="mobilesidebar_btm">
          <ul className="header_social_icon">
            {/*<li><a href="https://www.facebook.com/HealthyDiningFinder" target="_blank" aria-label="facebook"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
             <li><a href="https://twitter.com/DineOutHealthy" target="_blank" aria-label="Twitter"><i className="fa fa-twitter" aria-hidden="true"></i></a></li> */}
            <li><a href="https://www.linkedin.com/company/healthy-dining/" target="_blank" aria-label="linkedin"><i className="fa fa-linkedin fa-dark" aria-hidden="true"></i></a></li>
            <li><a href="https://www.pinterest.com/MyMenuUSA/" target="_blank" aria-label="pinterest"><i className="fa fa-pinterest fa-dark" aria-hidden="true"></i></a></li>
            <li><a href="https://instagram.com/mymenuusa" target="_blank" aria-label="instagram"><i className="fa fa-instagram fa-dark" aria-hidden="true"></i></a></li>
            <li><a href="https://www.facebook.com/MyMenuUSA/" target="_blank" aria-label="facebook"><i className="fa fa-facebook fa-dark" aria-hidden="true"></i></a></li>
          </ul>
          <div className="header_logo_wrap text-center">
            <img src="/static/img/headerlogo_mm.png" alt="headerlogo_mm"></img>
          </div>
        </div>
      </div>

      <div id="msSidePanel" className="sidepanel">
        <div className="closebtn" onClick={e => closeMSNav(e)}>×</div>
        <ul className="mobilememu">
          <li>
            <a className="navigation navigation-concierge" href="/msdashboard">Home</a>
          </li>
          <li>
            <a className="navigation navigation-restaurants" href="/restaurants-list">Restaurants</a>
          </li>
          <li>
            <a className="navigation navigation-profile" href="/profile">Profile</a>
          </li>

          <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}>Contact Us</div></li>
          <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}>Suggest a Restaurant</div></li>
          <li className="header-navigation"><div onClick={e => window.location.href = '/contact'}>Offer Feedback</div></li>
          <li className="header-navigation"><a target="" data-toggle="modal" data-target="#PrivacyModal">Privacy Policy</a></li>
          <li className="header-navigation"><a target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a></li>
          <li>
            <a className="navigation navigation-logoff" href="/" onClick={LogOff}>Logout</a>
          </li>
        </ul>
      </div>
      
      {/* Old-header-design */}
      <div className="container d-none">
        <div className="row">
          {userMSPilot == true ?
            <div className="col-sm-3">
              <div className="header_logo_wrap text-right desktop_logowrap tripism_logo">
                <div title="MyMenu" className="header-profile">{/*<img src="/static/img/tripism_logo.png"></img>*/}<img src="/static/img/microsoft_logo.png"  alt="microsoft_logo"></img></div>
              </div>
            </div>
            :
            <div className="col-sm-3">
              <ul className="header_social_icon hide_mobile">
                {/*<li><a href="https://twitter.com/DineOutHealthy" target="_blank" aria-label="twitter"><i className="fa fa-twitter" aria-hidden="true"></i></a></li> */}
                <li><a href="https://www.linkedin.com/company/healthy-dining/" target="_blank" aria-label="linkedin"><i className="fa fa-linkedin fa-dark" aria-hidden="true"></i></a></li>
                <li><a href="http://www.pinterest.com/MyMenuUSA/" target="_blank" aria-label="pinterest"><i className="fa fa-pinterest fa-dark" aria-hidden="true"></i></a></li>
                <li><a href="http://instagram.com/mymenuusa" target="_blank" aria-label="instagram"><i className="fa fa-instagram fa-dark" aria-hidden="true"></i></a></li>
                <li><a href="https://www.facebook.com/MyMenuUSA/" target="_blank" aria-label="facebook"><i className="fa fa-facebook fa-dark" aria-hidden="true"></i></a></li>
              </ul>
              <button className="openbtn" onClick={e => openNav(e)}><img src="/static/img/toggle.png"></img></button>
            </div>
          }
          {/*<div className="col-sm-3">
                  <ul className="header_social_icon hide_mobile">
                          <li><a href="https://twitter.com/DineOutHealthy" target="_blank" aria-label="twitter"><i className="fa fa-twitter" aria-hidden="true"></i></a></li> 
                      <li><a href="https://www.linkedin.com/company/healthy-dining/" target="_blank" aria-label="linkedin"><i className="fa fa-linkedin fa-dark" aria-hidden="true"></i></a></li>
                          <li><a href="http://www.pinterest.com/MyMenuUSA/" target="_blank" aria-label="pinterest"><i className="fa fa-pinterest fa-dark" aria-hidden="true"></i></a></li>
                          <li><a href="http://instagram.com/mymenuusa" target="_blank" aria-label="instagram"><i className="fa fa-instagram fa-dark" aria-hidden="true"></i></a></li>
                          <li><a href="https://www.facebook.com/MyMenuUSA/" target="_blank" aria-label="facebook"><i className="fa fa-facebook fa-dark" aria-hidden="true"></i></a></li>
                  </ul>
                  <button className="openbtn" onClick={e=>openNav(e)}><img src="/static/img/toggle.png"></img></button>
               </div>*/}
          <div className="col-sm-6">

            <div className="header_logo_wrap text-center desktop_logowrap">
              <div title="MyMenu" className="header-profile"><img src="/static/img/headerlogo_mm.png" alt="headerlogo_mm"></img></div>
            </div>
            <div className="mobile_logowrap">
              {headerName === 'dashboard' ?
                <div className="header_logo_wrap text-center">

                  {userMSPilot == true ?
                    <div title="MyMenu" className="header-profile"><img src="/static/img/headerlogo_mm.png" alt="headerlogo_mm"></img><br /><img src="/static/img/microsoft_logo.png"  alt="microsoft_logo"></img>{/*<br /><img src="/static/img/tripism_logo.png"></img>*/}<br /><img src="/static/img/amex_logo_partnership.png"></img></div>
                    :
                    <div title="MyMenu" className="header-profile"><img src="/static/img/headerlogo_mm.png" alt="headerlogo_mm"></img></div>
                  }

                </div>
                :
                <div title="MyMenu" className="page-name">{headerName}</div>
              }
            </div>
            <div className="header-navigation hide_mobile">
              <a className="navigation navigation-concierge" href="/landing">Home</a> | <a className="navigation navigation-preferences" href="/dashboard">Preferences</a> | <a className="navigation navigation-restaurants" href="/restaurants-list">Restaurants</a> | <a className="navigation navigation-profile" href="/profile">Profile</a> | <a className="navigation navigation-logoff" href="/" onClick={LogOff}>Logout</a><br />
              <a className="navigation navigation-subscription footer-links" target="" data-toggle="modal" data-target="#PrivacyModal">Privacy Policy</a> | <a className="navigation navigation-subscription footer-links" target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a> | <a className="navigation navigation-subscription footer-links" href="/contact">Contact Us</a> | <a className="navigation navigation-subscription footer-links" href="/contact">Suggest a Restaurant</a> | <a className="navigation navigation-subscription footer-links" href="/contact">Offer Feedback</a>
            </div>
            <div className="header-navigation-modified hide_mobile">
              <a className="navigation navigation-subscription" href="/subscription">Subscription</a> | <a className="navigation navigation-profile" href="/profile">Profile</a> | <a className="navigation navigation-logoff" href="/" onClick={LogOff}>Logout</a><br />
              <a className="navigation navigation-subscription footer-links" target="" data-toggle="modal" data-target="#PrivacyModal">Privacy Policy</a> | <a className="navigation navigation-subscription footer-links" target="" data-toggle="modal" data-target="#TermsConditionsModal">Terms & Conditions</a> | <a className="navigation navigation-subscription footer-links" href="/contact">Contact Us</a>
            </div>
          </div>
          {userMSPilot == true &&
            <div className="col-sm-3">
              <div className="header_logo_wrap text-left desktop_logowrap amex_logo">
                <div title="MyMenu" className="header-profile"><p className="partnership_text">In Partnership with</p><img src="/static/img/amex_logo_partnership.png"></img></div>
              </div>
            </div>
          }
          {/*<div className="col-sm-3">
                 <div className="header_right_sec">
                 <div className="header_greeting">
                    <span className="">
                      <h6 className="prfile_name" >Hello <GetFirstName />!</h6>
                    </span>
                    <span title="My Profile" className="header-profile"  onClick={e=>history.push('/profile')}><img src="/static/img/headeruser.png"></img></span>
                       <span title="MyMeal" className="header-profile header-my-meal"  onClick={e=>history.push('/my-meal')}><img src="/static/img/mymeal.png"></img> ({myMealCount})</span>
                       <span title="Logout" className="header-profile logout"  onClick={e=>logout(e)}><i className="fa fa-sign-out" aria-hidden="true"></i></span>
                 </div>
                          *<div className="hide_desktop">
                  { headerName === 'dashboard' &&
                                  <a className="header-profile" onClick={e => window.location.href = '/profile'}><img src="/static/img/headeruser.png"></img></a>
                  }
                              <a className="header-profile header-my-meal" onClick={e => window.location.href = '/my-meal'}><img src="/static/img/mymeal.png"></img> ({myMealCount})</a>
                 </div>

                 </div>
               </div>*/}
        </div>
      </div>

      {
        userMSPilot ?
        // Microsoft-user-header
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-lg-5'>
              <div className='headerLogo'>
                <button className="openbtn d-sm-inline d-md-none" 
                  style={{ position: 'absolute', top: '10px', left: '10px' }} 
                  onClick={e => openMSNav(e)}>
                  <img src="/static/img/toggle.png"></img>
                </button>

                                  <img src="/static/img/MyMenuDiningConciergeLogo.png" alt="headerlogo_mm"></img>
                {/*<img src="/static/img/microsoft_logo.png" alt="headerlogo_mm"></img>*/}
              </div>
            </div>
            <div className='col-12 col-sm-12 col-lg-7'>
              <div className='mainMenu'>
                <a className="navigation navigation-concierge" href="/msdashboard">Home</a>
                {/* <a className="navigation navigation-preferences" href="/dashboard">Preferences</a> */}
                {/* <a className="navigation navigation-restaurants" href="/restaurants-list">Restaurants</a> */}
                <a className="navigation navigation-restaurants" onClick={checkDate}>Restaurants</a>
                <a className="navigation navigation-profile" href="/profile">Profile</a>
                <a className="navigation navigation-suggest-restaurants" href="/contact">Suggest a Restaurant</a>
                <a className="navigation navigation-logoff" href="/" onClick={LogOff}>Logout</a>
              </div>
            </div>
          </div>
        </div>
        :
        // Non-Microsoft-user-header
        <div className='container'>
        <div className='row'>
          <div className='col-12 col-sm-12 col-lg-5'>
            <div className='headerLogo'>
              <button className="openbtn d-sm-inline d-md-none" 
                style={{ position: 'absolute', top: '10px', left: '10px' }} 
                onClick={e => openMSNav(e)}>
                <img src="/static/img/toggle.png"></img>
              </button>

              <img src="/static/img/headerlogo_mm.png"></img>
              {/* <img src="/static/img/microsoft_logo.png" alt="microsoft_logo"></img> */}
            </div>
          </div>
          <div className='col-12 col-sm-12 col-lg-7'>
            <div className='mainMenu'>
              <a className="navigation navigation-concierge" href="/msdashboard">Home</a>
              {/* <a className="navigation navigation-preferences" href="/dashboard">Preferences</a> */}
              {/* <a className="navigation navigation-restaurants" href="/restaurants-list">Restaurants</a> */}
              <a className="navigation navigation-restaurants" onClick={checkDate}>Restaurants</a>
              <a className="navigation navigation-profile" href="/profile">Profile</a>
              <a className="navigation navigation-suggest-restaurants" href="/contact">Suggest a Restaurant</a>
              <a className="navigation navigation-logoff" href="/" onClick={LogOff}>Logout</a>
            </div>
          </div>
        </div>
      </div>
      }
    </header>
    
  );
}

function LogOff() {
  localStorage.clear();
  window.location.href = '/';
}

function cronometer() {
  let userDetailsData = JSON.parse(localStorage.getItem('userDetails'));
  var cronometerWindow = window.open('', '_blank');
  cronometerWindow.document.write('Loading TRACK...');
  $.ajax({
    type: "GET",
    url: 'https://nutritrustapi.azurewebsites.net/api/User/CronometerAsync?sEmail=' + userDetailsData.sEmail + '&sHost=' + appConstants.WEB_HOST,
    //url: 'http://localhost:51851/api/User/CronometerAsync?sEmail=' + userDetailsData.sEmail,
    contentType: "application/json; charset=utf-8",
    dataType: "json",
    headers: {
      "Content-Type": "application/json",
      "apikey": 'A3BDF392-1EA2-4A8E-BD67-2B985D4CBD6C',
      "aspnetuserid": userDetailsData.sUserId,
      'crossDomain': true,
    },
    beforeSend: function () {
      $('.actionProcess').css('display', 'block');
    },
    success: function (result) {
      $('.actionProcess').css('display', 'none');
      cronometerWindow.location.href = result.redir;
      //var win = window.open(result.redir, '_blank');
      //if (win == null) {
      //    alert("You must enable pop-ups from MyMenuConcierge to use the TRACK feature.")
      //}
      //else {
      //    win.focus();
      //}

    },
    failure: function () {
      $('.actionProcess').css('display', 'none');
      cronometerWindow.close();
      alert("There was an error loading Track.  Please try again.");
    },
    error: function () {
      $('.actionProcess').css('display', 'none');
      cronometerWindow.close();
      alert("There was an error loading Track.  Please try again.");
    }
  });
};



export default withLocalize(withRouter(HeaderMicrosoftPage));
