import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux'
import HeaderPage from '../HeaderPage/HeaderPage.js';
import FooterPage from '../FooterPage/FooterPage.js';
import { ConfirmModalComponent } from '../../../_Common/Modal';
import { UpdateUserAction } from '../../Actions/User.action';
import { appConstants } from '../../../_Constants';
import toaster from '../../../_Common/Toaster';
//import './CalibrateReportPage.css';
import $ from "jquery";

const CalibrateFatMonosaturated = ({ history }) => {

    const [loader, setLoader] = useState('false');

    useEffect(() => {
        document.title = "MyMenuConcierge-CalibrateFatMonosaturated";
        $('body').css("background-image", 'url("/static/img/CalibrateFatMonosaturated.PNG")');
        let userData = JSON.parse(localStorage.getItem('userDetails'));
        let { UserPreferences } = userData;
        const data = {
            partnerUserId: userData.sUserId,
            email: userData.sEmail,
            firstName: userData.sFirstName,
            lastName: userData.sLastName
        }
        setTimeout(function () {
            window.GetCalibrateValues(userData.sUserId, "total-monounsaturated-fatty-acids-mufa");
        }, 0);
    }, []);

    return (
        <div className="App">
            <HeaderPage headermobileClass="mobile_header_show" headerName="dashboard" />
            <div className="actionProcess" style={{ display: loader ? 'none' : 'block' }}>
                <img src="/static/img/loader.gif" alt="Loading" />
            </div>
            <div className="inner_page_main dashboard_page">
                <div className="container">
                    <div className="row calibrate-report_summary">
                        <div className="col-md-12">
                            <div className="back_btn calibrate_back_btn" onClick={e => window.history.back()}><img src="/static/img/Previousicon.png" /></div>
                            <div className="inner_page">
                                <h1 className="text-center">CALIBRATE Monosaturated Fat (g)</h1>
                                <div className="calibrate-report_det">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <h2 className="text-center">Function</h2>
                                            <p>Monounsaturated fat is a type of fat found in highest proportions in nuts and in moderate amounts in many other foods of both plant and animal origins.​</p>
                                            <p>When selecting fat sources, US Dietary Guidelines recommend favoring unsaturated fats over saturated fats to reduce risk of chronic disease. ​​​</p>
                                        </div>
                                        <div className="col-sm-4 calibrate_intake text-center">
                                            <h5 className="text-center">Current Intake:</h5>
                                            <p className="calibrate_intake_values calibrate_intake_current">&nbsp;<br />&nbsp;</p>
                                            <h5 className="text-center">Ideal Intake:</h5>
                                            <p className="calibrate_intake_values calibrate_intake_ideal">&nbsp;<br />&nbsp;</p>
                                        </div>
                                        <div className="col-sm-4 calibrate_intake">
                                            <h2 className="text-center">Sources</h2>
                                            <h5>Plant-Based:</h5>
                                            <ul>
                                                <li>Pecans (1 oz. = 12.5 g)</li>
                                                <li>Olive Oil (1 tbsp = 9.8 g)​​</li>
                                                <li>Avocado Oil (1 tbsp = 9.6 g)​​</li>
                                                <li>Almonds (1 oz. = 8.9 g)​​</li>
                                                <li>Canola Oil (1 tbsp = 8.6 g)​​</li>
                                                <li>Peanut Oil (1 tbsp = 6.2 g)</li>
                                                <li>Pumpkin Seeds (1 oz. = 4.5 g)​​</li>
                                                <li>Peanut Butter (1 tbsp = 4.2 g)​​</li>
                                                <li>Soybean Oil (1 tbsp = 3.4 g)</li>
                                                <li>Safflower Oil (1 tbsp = 2.0 g)​​</li>
                                                <li>Almond Milk, unsweetened (1 cup = 1.4 g)</li>
                                            </ul>
                                            <h5>Animal-Derived:</h5>
                                            <ul>
                                                <li>Ground Beef, cooked (4 oz. = 8.2 g)​</li>
                                                <li>Yogurt, Whole Milk (1 cup = 2.2 g)​</li>
                                                <li>Chicken Breast, cooked (4 oz. = 1.7 g)</li>
                                                <li>Greek Yogurt, 2%, Plain ( 1 cup = 1.4 g) ​​</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<FooterPage footerName="calibrate" />*/}

            <div className="modal fade terms_conditions" id="TermsConditionsModal" tabindex="-1" role="dialog" aria-labelledby="TermsConditionsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title modal-title-landing">TERMS AND CONDITIONS</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <p>THESE TERMS CONTAIN AN ARBITRATION CLAUSE, DISCLAIMERS AND LIMITATIONS OF LIABILITY. PLEASE REVIEW CAREFULLY.</p>
                            <p>The following terms ("Terms of Use") constitute an agreement between Accents on Health, Inc. DBA Healthy Dining ("Company"), and you that governs your use of this website and all of its associated services, content, and functionality, including purchase and use of the Membership (defined below). This policy applies to the website administered by Company (the "Website"), located at https://www.mymenuusa.com/. Company, owner and operator of the Website, is a corporation formed under the laws of the state of California, United States.</p>
                            <p>Your use of the Website constitutes your acceptance of, and agreement to, the following Terms of Use. Company reserves the right to modify, alter, amend or update its Website, policies and these Terms of Use. These Terms of Use are subject to change without notice. If you do not agree with or do not accept any part of these Terms of Use, you must not use the Website. Your continued use of any part of the Website constitutes your acceptance of such changes to the Terms of Use. You should review the Terms of Use periodically to determine if any changes have been made.</p>
                            <p><strong><u>PURCHASE POLICIES</u></strong></p>
                            <p>On the Website, you may purchase a MyMenu membership (the "Membership"). The Membership includes access to the MyMenu personalized restaurant meal platform, nutritional content, third party software services for nutritional tracking, diet assessment, and a children&rsquo;s nutritional learning program.</p>
                            <p>The Membership is available to individual customers for a monthly or yearly fee, which you may choose at time of purchase (the "Membership Subscription Fee"). You will be required to select a payment plan and provide Company&rsquo;s third-party payment provider with information regarding your credit card or other payment instrument. You represent and warrant to Company that such information is true and that you are authorized to use the payment instrument. You will promptly update your User Account information with any changes (for example, a change in your billing address or credit card expiration date) that may occur. You agree to pay Company the amount that is specified in the payment plan in accordance with the terms of such plan and these Terms of Use. You hereby authorize Company to bill your payment instrument in advance in accordance with the terms of the applicable payment plan until you terminate your User Account, and you further agree to pay any charges so incurred. If you dispute any charges you must let Company know within sixty (60) days after the date that Company charges you. Company reserves the right to change the price of the Membership. Your, or in the case of an enterprise Membership, any end users (&ldquo;End Users&rdquo;), continued use of the Membership after the price change becomes effective constitutes your agreement to pay the changed amount. Access will be revoked to the Membership if you choose a payment plan or subscription and do not complete the payments on schedule.&nbsp;</p>
                            <p>From time to time, Company may offer special promotional or discounted pricing to certain enterprise, affiliate or individual Members. Such pricing may be subject to additional terms. In this case, Company will honor the discounted pricing for one year whether or not the monthly or yearly option is chosen.&nbsp;</p>
                            <p>Your Membership will automatically renew each month or year, depending on the payment plan you have selected. You may cancel a monthly or annual Membership at any time. It is your responsibility to cancel the Membership prior to renewal. You may cancel by selecting &ldquo;cancel&rdquo; in the Manage Billing section of your Profile.</p>
                            <p><strong><u>Refund Policy</u></strong></p>
                            <p>Refunds are not available for the Membership except as expressly set forth herein. You may cancel a Membership at any time, but the account will remain active until either the day prior to the following month (if paid monthly) or day prior to the following year (if paid yearly).</p>
                            <p>Certain jurisdictions may have longer periods where refunds will be granted. Company will honor such periods as required by law.</p>
                            <p><strong><u>REGISTRATION &amp; RESTRICTED ACCESS </u></strong></p>
                            <p>Access to certain areas of the Website may be restricted. Company reserves the right to restrict areas of the Website at its sole discretion.</p>
                            <p>Some restricted areas of the Website may be made available to you as a registered user. When you are registered, you are required to create a user profile, which may include a username and password ("User Account"). You agree to keep your username and password confidential. You may not use as a username the name of another person or entity that is not lawfully available for use, a name or trademark that is subject to any rights or another person or entity or is offensive, vulgar or obscene. If you suspect your password has been compromised, you must notify Company immediately at Terms@HealthyDining.biz. Company will not be liable for any loss caused by the unauthorized use of your User Account; however, you may be liable to Company or other third parties for any losses incurred due to such unauthorized use.</p>
                            <p>When you create a User Account with Company, you guarantee that you are 18 years of age or older, are able to consent to these Terms of Use, and that the information you provide to Company is accurate, complete, and current at all times. Violation of this paragraph may result in the immediate termination of your User Account without refund, in Company&rsquo;s sole discretion.</p>
                            <p>Company may disable your username and password at its sole discretion, refuse to register a user for the Products, remove or edit any content contributed to the Website or cancel any User Account. Company may, without notice, refuse access to its Website or Products, in whole or part, to any person that fails to comply with these Terms of Use.</p>
                            <p>Company reserves the right to modify methods for registration and access levels of registered users from time to time.</p>
                            <p><strong><u>LICENSE FOR USE OF MEMBERSHIP</u></strong></p>
                            <p>The Membership available for sale on the Website was developed solely for your personal use and may not be reproduced for publication or for the personal or commercial use of others without permission. You may not create any derivative works of the Membership. When you purchase or use the Membership, you agree that you will not use any information you gain from the Membership to create any product or service, whether offered for commercial or personal use, without the express written consent of the Company. All inquiries for use of Company intellectual property must be submitted to Terms@HealthyDining.biz. Company reserves the right to seek equitable and compensatory relief for any violation of this term.</p>
                            <p><strong><u>GENERAL DISCLAIMER</u></strong></p>
                            <p>Company has made every effort to ensure that all information on the Website and in the Membership has been tested for accuracy. Company makes no guarantees regarding the results that you will see from using the information provided on the Website or in the Membership. Opinions, advice, statements or other comments should not necessarily be relied upon and are not to be construed as professional advice from Company.</p>
                            <p>Company disclaims liability for incidental or consequential damages and assumes no responsibility or liability for any loss or damage suffered by any person as a result of use of the information provided on the Website or in the Membership. Company assumes or undertakes no liability for any loss or damage suffered as a result of the use of any information found on the Website or in the Membership.</p>
                            <p><strong><u>NUTRITION DISCLAIMER</u></strong></p>
                            <p>All information provided regarding nutrition on the Website or in the Membership is intended to be used for informational purposes only. Content related to nutrition is not medical advice nor is it intended to replace medical advice. The Website and Membership is not intended to diagnose, prescribe, or treat any disease, condition, illness, or injury. Before beginning any diet program, modifying your diet, or making changes to the diet of a child in your care, including following the nutrition information available on the Website or in the Membership, you should seek advice from a licensed professional.</p>
                            <p>We are not responsible for any liability, loss, or damage, caused or alleged to be caused directly or indirectly as a result of the use, application, or interpretation of the nutrition information available on the Website or in the Membership.</p>
                            <p>The Food and Drug Administration has not evaluated the statements contained in any information on the Website or in the Membership. Individual results may vary.</p>
                            <p><strong><u>ALLERGY DISCLAIMER</u></strong></p>
                            <p>You understand that the avoidance tool in the Membership does not certify that the applicable restaurant prevents against cross contamination, therefore an allergic reaction is possible. Company does not make any guarantees that any restaurant or dish does not contain allergens. You are solely responsible for ensuring that any meals or restaurant is safe for you given your dietary restrictions.</p>
                            <p><strong><u>YOUR RESPONSIBILITY</u></strong></p>
                            <p>The Website and Membership were developed strictly for informational purposes. You understand and agree that you are fully responsible for your use of the information provided on the Website and in the Membership. Company makes no representations, warranties or guarantees. You understand that results may vary from person to person. Company assumes no responsibility for errors or omissions that may appear on the Website or in the Membership.</p>
                            <p><strong><u>USE OF THE WEBSITE </u></strong></p>
                            <p>Unless otherwise stated, Company owns the intellectual property and rights to all content and material on the Website and in the Membership. Subject to the license below, all intellectual property rights are reserved.</p>
                            <p>You may view, download (for caching purposes only), and print pages for your personal use, subject to the restrictions set out below and elsewhere in these Terms of Use.</p>
                            <p>The following uses are not permitted:</p>
                            <ol>
                                <li>Republication of content from the Website or Membership, unless content is specifically and expressly made available for republication;</li>
                                <li>Sale, rental or sub-license of any content from the Website or Membership;</li>
                                <li>Reproduction or duplication of any content on the Website or Membership for commercial purposes;</li>
                                <li>Modification of any content on the Website or Membership, unless content is specifically and expressly made available for modification;</li>
                                <li>Redistribution of content of the Website or Membership, unless content is specifically and expressly made available for redistribution. Users are permitted to share content on social media channels, as long as a link to the Website is included.</li>
                            </ol>
                            <p>From time to time, the Website or Membership will utilize various plugins or widgets to allow sharing of content via social media channels, email, or other methods. Use of these plugins or widgets does not constitute any waiver of Company's intellectual property rights. Such use is a limited license to republish the content on the approved social media channels, with full credit to Company.</p>
                            <p>You must not use the Website or Membership in a way that causes, or may cause, damage to the Website or Membership or impairs the availability of access to the Website or Membership. You must not decompile, reverse engineer, disassemble or otherwise reduce the Website or Membership, except to the extent that such activity is expressly permitted by applicable law. You must not use the Website or Membership to copy, store, host, transmit, send, use, publish or distribute any material that consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit and/or other harmful code or malicious software.</p>
                            <p>You must not conduct any systematic or automated data collection activities, including, but not limited to scraping, data mining, data extraction or data harvesting on or in relation to the Website without Company's express written permission.</p>
                            <p>You must not use the Website or Membership to transmit or send any unsolicited commercial communications.</p>
                            <p>You must not use the Website or Membership for any third-party marketing without Company's express written permission.</p>
                            <p><strong><u>INTELLECTUAL PROPERTY</u></strong></p>
                            <p>All original materials provided by Company are owned by Company. Any original materials are provided for your individual use only. You are not authorized to use or transfer any of Company's intellectual property. All intellectual property remains the property of Company. No license to sell, distribute, reproduce, prepare a derivative work, display, or perform is granted or implied.</p>
                            <p>Certain of the names, logos, and other materials displayed on the Website or in the Membership constitute Company's intellectual property, including, but not limited to, patents, trademarks, service marks, trade secrets and copyrights ("Company IP"). You are not authorized to use any Company IP without Company's express consent. Ownership of Company IP remains with Company and you agree not to make any claims or assertions of any other party's ownership of Company IP.</p>
                            <p><strong><u>Equitable Relief</u></strong></p>
                            <p>You acknowledge and agree that in the event of certain breaches of the Terms of Use, Company may suffer irreparable injury, such that no remedy at law will afford it adequate protection against, or appropriate compensation for, such injury. Accordingly, you agree that Company shall be entitled to any injunctive relief, without having to post a bond, as may be granted by a court of competent jurisdiction.</p>
                            <p><strong><u>COPYRIGHT</u></strong></p>
                            <p>Unless otherwise noted, the design, content and all components of the Website and Membership are copyrights owned by Company or third parties and are protected by United States and international copyright laws and should not be reused or republished without express written permission.</p>
                            <p><strong><u>TRADEMARKS</u></strong></p>
                            <p>"MyMenu" is a trademark of Company and is protected by United States trademark law. Company's trademarks and trade dress may not be used in connection with any product or service that is not Company's, in any manner likely to cause confusion among consumers, or in any manner that disparages or discredits Accents on Health, Inc. DBA Healthy Dining, https://www.mymenuusa.com/, or the experts featured on the Website or in the Membership.</p>
                            <p>From time to time, the Website and Membership will legally utilize trademarks owned by third parties related to Company's services. These trademarks are the respective property of their owners.</p>
                            <p><strong><u>FTC DISCLOSURE</u></strong></p>
                            <p>From time to time, the Website or Membership may post sponsored content from an advertiser. This means that an advertiser, which may be a brand, agency, or influencer network, will contract with Company to create content featuring certain messages or product placements. Pursuant to the FTC's requirements, all such advertisements on the Website or in the Membership are clearly and conspicuously disclosed.</p>
                            <p>Even though compensation (cash, free product, services) is received in exchange for this sponsored content placement, Company gives its honest opinion, findings, beliefs, or experiences in such content. All views expressed on the Website or Membership are those of the content creator. Any product claim, statistic, quote or other representation about a product or service should be verified with the manufacturer, provider, or party in question.</p>
                            <p><strong><u>AFFILIATE MARKETING</u></strong></p>
                            <p>From time to time, the Website or Membership may engage in affiliate marketing. This means that if you use an affiliate link to make a purchase, the Website or Membership will receive a commission on that purchase. All efforts are made to ensure that affiliate links are disclosed in accordance with the FTC.</p>
                            <p><strong><u>GRANT OF RIGHTS</u></strong></p>
                            <p>You grant Company a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute any content you contribute to the Website or Membership. This includes, but is not limited to, text, images, audio material, comments, video material and audio-visual material. This license extends to all known and future media. You also grant Company the right to sub-license these rights and the right to bring an action for infringement of these rights. By posting content to the Website or Membership, you represent that you have the right to grant these permissions for the use of such content by Website, the Membership, Company and Company&rsquo;s sublicensees.</p>
                            <p><strong><u>CONTENT CONTRIBUTED TO THE WEBSITE<br /></u></strong>Any content you contribute to the Website or Membership, including, but not limited to text, images, audio material, comments, video material and audio-visual material, must not be illegal or unlawful, may not infringe on any third-party's legal rights, and must not be capable of giving rise to legal action whether against you or Company or a third party.</p>
                            <p>Company reserves the right to edit or remove: (i) any material submitted to the Website or Membership; (ii) stored on Company's servers; or, (iii) hosted or published on the Website or Membership. Company takes no responsibility and assumes no liability for any content posted by you or any third party.</p>
                            <p>Notwithstanding Company's rights under the Terms of Use, Company does not undertake to monitor the submission of all content to, or the publication of such content on, the Website and is not responsible for such content.</p>
                            <p><strong><u>COMMENT POLICY</u></strong></p>
                            <p>The Website or Membership may offer the option for you to leave comments, engaging with the Website or Membership. The following types of comments will not be tolerated and will be deleted:</p>
                            <ol>
                                <li>harassment directed toward any content creator or Company;</li>
                                <li>spam;</li>
                                <li>hate speech;</li>
                                <li>defamatory to Company or any third party;</li>
                                <li>reference illegal acts; or,</li>
                                <li>violate the legal rights of a third party.</li>
                            </ol>
                            <p>Company's sole discretion will be used to determine if a comment is in violation of this comment policy. Any comments in violation will be promptly deleted and no further explanation will be due to you if your comment was determined to be in violation with this policy.</p>
                            <p>You are, and shall remain, solely responsible for the any content you upload, submit, post, transmit, communicate, share, or exchange by means of the Website or Membership and for the consequences of submitting or posting same. COMPANY DISCLAIMS ANY PERCEIVED, IMPLIED OR ACTUAL DUTY TO MONITOR THE MEMBERSHIP OR WEBSITE AND SPECIFICALLY DISCLAIMS ANY RESPONSIBILITY OR LIABILITY FOR INFORMATION PROVIDED THEREON.</p>
                            <p>You and any participant violating the Terms of Use may be immediately and permanently removed from the Website and/or Membership, in Company&rsquo;s sole discretion and no refund or credit will be due to you in such case.</p>
                            <p>Any content posted in the Membership or on the Website is the sole responsibility of the person(s) who created it, and Company and its employees, agents, directors, and officers, undertake no obligation or liability related to such content. Company and its employees, agents, directors, and officers, do not undertake or assume any duty to monitor for inappropriate or unlawful content posted by participants, nor does it assume responsibility or liability that may arise from any content posted in the Membership or on the Website, including, but not limited to, claims of defamation, libel, slander, infringement, invasion of privacy and publicity rights, obscenity, pornography, fraud, or misrepresentation.&nbsp;</p>
                            <p>Company reserves the right to report to the appropriate authority any post, comment, message or participant in the Membership that Company deems, in its sole discretion, may implicate the safety of either a participant or a third-party.</p>
                            <p><strong><u>TAKEDOWN REQUESTS</u></strong></p>
                            <p>From time to time, the Website or Membership will publish posts with images from other third-party websites. Any such use is considered fair use under copyright laws and is fully attributed to the owner. If you believe that your copyrighted work has been used on the Website or Membership in a way that constitutes copyright infringement and falls outside of fair use, please send a request to Terms@HealthyDining.biz and we will remove the image within 24 to 48 hours.</p>
                            <p><strong><u>COMMUNICATION</u></strong></p>
                            <p>If you send Company an email, register to use the Website or Membership or provide your email to Company in any other way, you consent to receive communications from Company electronically. You agree that all legal notices provided via electronic means from Company satisfy any requirement for written notice.</p>
                            <p><strong><u>THIRD PARTIES</u></strong></p>
                            <p>The Website or Membership may contain links to third-party websites that are not governed or controlled by Company. You represent and warrant that you have read and agree to be bound by all applicable terms of use and policies for any third-party website that relates to your use of the Website or Membership. Company assumes no control or liability over the content of any third-party sites. You expressly hold Company harmless from any and all liability related to your use of a third-party website.</p>
                            <p><strong><u>CHILDREN'S INFORMATION</u></strong></p>
                            <p>The Website and Membership do not knowingly collect any personally identifiable information from children under the age of 16. If a parent or guardian believes that the Website or Membership has personally identifiable information of a child under the age of 16 in its database, please contact Company immediately at Terms@HealthyDining.biz and we will use our best efforts to promptly remove such information from our records.</p>
                            <p><strong><u>NO WARRANTIES </u></strong></p>
                            <p>The Website and Membership is provided on an "as is" and "as available" basis without any representations or warranties, expressed or implied. Company makes no representations or warranties in relation to the Website, Membership or the information and materials provided therein.</p>
                            <p>Company makes no warranty the Website or Membership will meet your requirements; will be available uninterrupted; error free, timely and free of viruses or bugs; or represents the full functionality, accuracy, and reliability of the Website or Membership. Company is not responsible to you for the loss of any content or material uploaded or transmitted through the Website or Membership. The Website and Membership is written in English and Company makes no warranty regarding translation or interpretation of content in any language.&nbsp;</p>
                            <p><strong><u>SERVICE LEVEL</u></strong></p>
                            <p>Given the cloud-based nature of the Membership, Company cannot guarantee service at all times. Company endeavors to provide a service level of 99% however, Company shall not be liable for any failure to meet this service level and no refund or credits shall be granted for such failure. If the Membership will not be available due to any pre-scheduled maintenance, Company will provide notice when reasonably able to do so.</p>
                            <p><strong><u>LIMITATION OF LIABILITY</u></strong></p>
                            <p>TO THE EXTENT ALLOWABLE BY LAW, COMPANY AND ITS OFFICERS, EMPLOYEES, AGENTS, AFFILIATES, LICENSEES AND WEB HOSTING SERVICES WILL NOT BE LIABLE FOR ANY DIRECT, CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, PUNITIVE OR SPECIAL DAMAGES OF ANY KIND, HOWEVER CAUSED, INCLUDING LOSS OF PROFITS, REVENUE, DATA OR USE, INCURRED BY YOU, WHETHER UNDER THEORY OF CONTRACT, TORT (INCLUDING NEGLIGENCE), WARRANTY OR OTHERWISE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                            <p>YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE USE OF THIS WEBSITE OR THE TERMS OF USE MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR BE FOREVER BARRED.</p>
                            <p><strong><u>INDEMNITY</u></strong></p>
                            <p>You agree to defend, indemnify and hold Company, its members, employees, officers, directors, managers and agents harmless from and against any and all losses, claims, suits, actions, liabilities, obligations, costs and expenses (including reasonable attorneys' fees and expenses) which Company suffers as a result of third-party claims based on: (i) your negligence or intentional misconduct, (ii) your breach of any provision of the Terms of Use (including representation or warranty); (iii) materials prepared or provided by you including, but not limited to, any claims of infringement, or misappropriation of copyright, trademark, patent, trade secret, or other intellectual property or proprietary right, infringement of the rights of privacy or publicity, or defamation or libel; or (iv) death, personal injury, or property damage arising out of, or relating to, your obligations hereunder.</p>
                            <p><strong><u>ARBITRATION</u></strong></p>
                            <p>The Terms of Use will be governed and construed in accordance with the laws of the state of California without reference to its conflict of law provisions. Any controversy or claim arising out of or relating to the Website, Membership, Terms of Use, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association ("AAA") under its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The place of any such arbitration shall be in San Diego County, California. The parties also agree that the AAA Optional Rules for Emergency Measures of Protection shall apply to the proceedings. This section provides the sole recourse for the settlement of any disputes arising out of, in connection with, or related to the Website, Membership, or Terms of Use. Notwithstanding the foregoing, any action seeking injunctive relief shall be submitted to the courts and shall not be subject to this provision.</p>
                            <p>THE PARTIES WAIVE ANY RIGHT TO ASSERT ANY CLAIMS AGAINST THE OTHER PARTY AS A REPRESENTATIVE OR MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION, EXCEPT WHERE SUCH WAIVER IS PROHIBITED BY LAW OR DEEMED BY A COURT OF LAW TO BE AGAINST PUBLIC POLICY. TO THE EXTENT EITHER PARTY IS PERMITTED BY LAW OR A COURT OF LAW TO PROCEED WITH A CLASS OR REPRESENTATIVE ACTION AGAINST THE OTHER, THE PARTIES AGREE THAT: (I) THE PREVAILING PARTY SHALL NOT BE ENTITLED TO RECOVER ATTORNEYS&rsquo; FEES OR COSTS ASSOCIATED WITH PURSUING THE CLASS OR REPRESENTATIVE ACTION (NOT WITHSTANDING ANY OTHER PROVISION IN THIS AGREEMENT); AND (II) THE PARTY WHO INITIATES OR PARTICIPATES AS A MEMBER OF THE CLASS WILL NOT SUBMIT A CLAIM OR OTHERWISE PARTICIPATE IN ANY RECOVERY SECURED THROUGH THE CLASS OR REPRESENTATIVE ACTION.</p>
                            <p><strong><u>MISCELLANEOUS PROVISIONS</u></strong></p>
                            <p>If any provision(s) of the Terms of Use is held to be invalid, illegal or unenforceable, the remaining provisions shall be severable and enforceable. In addition, in such event the unenforceable or invalid provision shall be deemed to be modified to the extent necessary to (i) render it valid and enforceable and (ii) give the fullest effect possible to the original intent of the provision.</p>
                            <p>The Terms of Use may not be assigned by you without Company's prior written consent; however, the Terms of Use may be assigned by Company in its sole discretion.</p>
                            <p>The Terms of Use are the final, complete and exclusive agreement of the parties with respect to the Website and Membership offered by Company.</p>
                            <p>The failure of Company to exercise or enforce any right or provision hereunder shall not operate as a waiver of such right or provision. Any waiver of the Terms of Use by Website or Company must be in writing and signed by an authorized representative of the Company.</p>
                            <p>All notices with respect to the Terms of Use must be in writing and may be via email to Terms@HealthyDining.biz for Company and to your email address.</p>
                            <p>Last updated: May 6, 2021.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade terms_conditions" id="PrivacyModal" tabindex="-1" role="dialog" aria-labelledby="PrivacyModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title modal-title-landing">PRIVACY POLICY</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>The following privacy policy ("Privacy Policy") sets forth Accents on Health, Inc. DBA Healthy Dining's ("Company") policy with respect to information, including personally identifiable data ("Personal Data") and other information, Company collects from visitors to the Company website located at https://www.mymenuusa.com/ ("Website") and those visitors that sign up for the Company&rsquo;s services (the &ldquo;Membership&rdquo;). Any references to the Website shall include the Membership. The company, owner and operator of the Website, is a corporation formed under the laws of the state of California, United States.</p>
                            <p>We respect your privacy and are committed to protecting it. The purpose of this Privacy Policy is to inform you what Personal Data we may collect and how it may be used. This statement only applies to this Website. This Privacy Policy does not apply to information of any kind that we collect by means (including offline means) or from sources other than those specified herein. If you choose to use the Website, your use is hereby deemed acceptance of the Company&rsquo;s practices described in this Privacy Policy.</p>
                            <p>WHAT INFORMATION DO WE COLLECT AND HOW IS IT USED?</p>
                            <p>Information You Voluntarily Submit to the Website: The Website may collect personal information from you such as your name or email address. For example, you may voluntarily submit information to the Website by leaving a comment, subscribing to a newsletter, or submitting a contact form. Personal Data may include, but is not limited to, your name, email address, and phone number.</p>
                            <p>Our legal basis for processing this information is your consent, and by voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy. If you provide Personal Data to us, you acknowledge and agree that such Personal Data may be transferred and stored from your current location to the offices and servers of the Company and the authorized third parties referenced below. We collect the following information from users of the Website when they sign up for the Membership:</p>
                            <ul>
                                <li>First and Last Name;</li>
                                <li>Email Address;</li>
                                <li>Zip Code;</li>
                                <li>Cell Phone;</li>
                                <li>Age Range;</li>
                                <li>Other demographic information related to the services; and</li>
                                <li>Payment information.</li>
                            </ul>
                            <p>Only first and last name, email address and payment information are required to sign up for the Membership. The rest of the information is optional and at the user&rsquo;s discretion.</p>
                            <ul>
                                <li>Information We Collect from Others: The Website may receive information about you from other sources. For example, if you use a third-party software through the Website, they may transfer information to us for fulfillment. We do not control the data privacy or protection policies of third parties, and we are not responsible for the privacy practices of these third parties. Our legal basis for processing this information is our legitimate interests, namely monitoring and improving our services, and the legitimate interests of our customers.</li>
                                <li>Automatically-Collected Information: The Website automatically collects certain information about you and the device with which you access the Website. For example, when you use the Website, The Website will log your IP address, operating system type, browser type, referring website, pages you viewed, and the dates/times when you accessed the Website. The Website may also collect information about actions you take when using the Website, such as links clicked.</li>
                                <li>Cookies: The Website may log information using cookies, which are small data files stored on your browser by the Website. The Website may use both session cookies, which expire when you close your browser, and persistent cookies, which stay on your browser until deleted, to provide you with a more personalized experience on the Website.</li>
                            </ul>
                            <p>HOW YOUR INFORMATION MAY BE USED</p>
                            <p>The Website may use the information collected in the following ways:</p>
                            <ul>
                                <li>To operate and maintain the Website;</li>
                                <li>To fulfill orders or oversee contests or promotions;</li>
                                <li>To send you promotional information, such as newsletters. Each email promotion will provide information on how to opt-out of future mailings;</li>
                                <li>To send you administrative communications, such as administrative emails, confirmation emails, technical notices, updates on policies, or security alerts;</li>
                                <li>To respond to your comments or inquiries;</li>
                                <li>To provide you with user support;</li>
                                <li>Training and quality control;</li>
                                <li>To track and measure advertising on the Website; or,</li>
                                <li>To protect, investigate, and deter against unauthorized or illegal activity.</li>
                            </ul>
                            <p>THIRD-PARTY USE OF PERSONAL INFORMATION</p>
                            <p>Company may share your Personal Data with certain third parties as set forth below:</p>
                            <p>Third Party Vendors: We may share your information with third-party vendors or service providers who help us provide our products or services or the Website, including database hosting and data processing services, and assist us in responding to requests by you for information that you request.</p>
                            <p>Parent Companies and Affiliates: We may share your information with a parent company, any subsidiaries, joint ventures, or other companies under a common control (collectively, "Affiliates"), provided that we require our Affiliates to honor this Privacy Policy.</p>
                            <p>Business Transfers: As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution, or similar event, Personal Data may be part of the transferred assets. We may also share Personal Data with potential buyers. Usually, information will be anonymized, but this may not always be possible. The recipient of the information will be bound by confidentiality obligations.</p>
                            <p>Related Companies: We may also share your Personal Data with our related companies for purposes consistent with this Privacy Policy.</p>
                            <p>Third Parties with Permission: We may share your information with third parties to whom you ask us to send your information.</p>
                            <p>Agents, Consultants, and Related Third Parties: Company, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases, and processing payments. When we employ another entity to perform a function of this nature, we only provide them with the information that they need to perform their specific function.</p>
                            <p>Legal Requirements: Company may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation or to respond to requests from law enforcement or other government officials relating to investigations or alleged illegal activity or in connection with our own investigation of suspected or actual illegal activity, in which case we may (and you hereby authorize us to) disclose information without subpoenas or warrants served on us, (ii) protect and defend the rights or property of Company, (iii) act in urgent circumstances to protect the personal safety of users of the Website or the public, (iv) protect against legal liability including to resolve disputes, investigate problems, or enforce our customer contracts.</p>
                            <p>The Website may share your information with third parties when you explicitly authorize us to share your information. Additionally, the Website may use third-party service providers to service various aspects of the Website. Each third-party service provider's use of your personal information is dictated by their respective privacy policies. The Website currently uses the following third-party service providers:</p>
                            <ul>
                                <li>Google Analytics &ndash; this service tracks Website usage and provides information such as referring websites and user actions on the Website. Google Analytics may capture your IP address, but no other personal information is captured by Google Analytics.</li>
                                <li>Wix &ndash; this service is used for delivery of email updates and newsletters. We store your name and email address for purposes of delivering such communications.</li>
                                <li>Cronometer &ndash; this service is used to provide nutrition tracking services and may collect personal information, sensitive information and biometric information from you. Your use of the Cronometer services is subject to Cronometer&rsquo;s <u>privacy policy</u>. Company will disclose your name and email address to Cronometer if you sign up for Cronometer&rsquo;s service. Cronometer does not share any personally identifiable information with Company.</li>
                                <li>DietID- this service is used to support our MyMenu services and Company will provide DietID anonymized data about you in order to calculate nutritional info. DietID will share this information with Company and Cronometer.</li>
                                <li>Stripe &ndash; this service is used to serve our e-commerce platform. At no time is your banking information passed to the Website. We receive only information used for order fulfillment.</li>
                                <li>Microsoft &ndash; this service is used to host the MyMenu application and database. We do not share any of your personal information with Microsoft, nor does Microsoft have access to your personal information in our database.</li>
                            </ul>
                            <p>At this time, your personal information is not shared with any other third-party service providers. This list may be amended from time to time in the Website's sole discretion.</p>
                            <p>Except when required by law, Company will not sell, distribute, or reveal your email addresses or other personal information without your consent; however, Company may disclose or transfer personal information collected through the Website to third parties who acquire all or a portion of our business, which may be the result of a merger, consolidation, or purchase of all or a portion of our assets, or in connection with any bankruptcy or reorganization proceeding brought by or against us.</p>
                            <p>ANONYMOUS DATA</p>
                            <p>From time to time, the Company may use anonymous data, which does not identify you alone, or when combined with data from other parties. This type of anonymous data may be provided to other parties for marketing, advertising, or other uses. Examples of this anonymous data may include analytics or information collected from cookies.</p>
                            <p>COOKIES</p>
                            <p>The Website uses cookies to store visitors' preferences, record user-specific information on what pages users access or visit, ensure that visitors are not repeatedly sent the same banner ads, customize content based on visitors' browser type or other information that the visitor sends. Cookies may also be used by third-party services, such as Google Analytics, as described herein.</p>
                            <p>What are cookies?</p>
                            <p>A cookie is a small text file that is sent to your computer or mobile device (referred to in this policy as a "device") by the web server so that a website can remember some information about your browsing activity on the Website. The cookie will collect information relating to your use of the Website, information about your device such as the device&rsquo;s IP address and browser type, demographic data and, if you arrived at the Website via a link from third-party site, the URL of the linking page.</p>
                            <p>In addition to cookies, the Website may use web beacons. Web beacons allow us to count the number of users who have visited or accessed the Website and to recognize users by accessing our cookies. We may employ web beacons to facilitate Website administration and navigation, to track the actions of users of the Website, to compile aggregated statistics about Website usage and response rates, and to provide an enhanced online experience for visitors to the Website. We may also include web beacons in HTML-formatted e-mail messages that we send to determine which e-mail messages were opened. A web beacon is often invisible because it is only 1 x 1 pixel in size with no color. A web beacon can also be known as a web bug, 1 by 1 GIF, invisible GIF, and tracker GIF.</p>
                            <p>What are the different types of cookies and how do we use them?</p>
                            <p>Essential - These are cookies that are essential for the running of the Website. Without these cookies, parts of the Website would not function. These cookies do not track where you have been on the internet and do not gather information about you that could be used for marketing purposes.</p>
                            <p>Examples of how we may use essential cookies include:</p>
                            <ul>
                                <li>Setting unique identifiers for each unique visitor, so that Website numbers can be analyzed.</li>
                            </ul>
                            <p>Functional &ndash; These cookies are used to remember your preferences on the Website and to provide enhanced, more personal features. The information collected by these cookies is usually anonymized, so we cannot identify you personally. Functional cookies do not track your internet usage or gather information that could be used for selling advertising.</p>
                            <p>Examples of how we may use functional cookies include:</p>
                            <ul>
                                <li>Gathering data about visits to the Website, including numbers of visitors and visits, length of time spent on the Website, pages clicked on, or where visitors have come from;</li>
                                <li>Eliminating the need for returning users to re-enter their login details.</li>
                            </ul>
                            <p>Analytical Performance - Analytical performance cookies are used to monitor the performance of the Website, for example, to determine the number of page views and the number of unique users our Website has. We use this information to improve the user experience or identify areas of the Website which may require maintenance. The information is anonymous (i.e. it cannot be used to identify you and does not contain personal information such as your name and email address) and it is only used for statistical purposes.</p>
                            <p>Examples of how we may use analytical cookies include:</p>
                            <ul>
                                <li>Measuring users&rsquo; behavior;</li>
                                <li>Analyzing which pages are viewed and how long for and which links are followed to better develop the Website.</li>
                            </ul>
                            <p>Advertising - Behavioral advertising cookies, which may be placed on your device by us or our trusted third-party service providers, remember that you have visited a website and use that information to provide you with advertising which is tailored to your interests. This is often called online behavioral advertising and is done by grouping together shared interests based upon web browsing history. Your web browsing history can be used to infer things about you (e.g. your age, gender, etc.), and this information may also be used to make advertising on websites more relevant to you. Although behavioral advertising cookies can track your activity around the internet, these cookies cannot identify you personally.</p>
                            <p>Examples of how we may use advertising cookies include:</p>
                            <ul>
                                <li>providing advertising to you and enabling us to manage our relationship with those advertisers by, for example, tracking how many unique users have seen a particular advertisement or followed a link in an advertisement.</li>
                            </ul>
                            <p>Third Party Cookies - You may notice on some pages of the Website that Cookies have been set that are not related to us. When you visit a page with content embedded from, for example, YouTube or Facebook, these third-party service providers may set their own cookies on your device. We do not control the use of these third-party cookies and cannot access them due to the way that cookies work, as cookies can only be accessed by the party who originally set them. Please check the third-party websites for more information about these cookies.</p>
                            <p>Users may, at any time, prevent the setting of cookies, by the Website, by using a corresponding setting of your internet browser and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. However, if you deactivate the setting of cookies in your Internet browser, not all functions of our Website may be entirely usable.</p>
                            <p>How can you manage or opt out of cookies?</p>
                            <p>Cookies, including those which have already been set, can be deleted from your hard drive. You can also change the preferences/settings in your web browser to control cookies. In some cases, you can choose to accept cookies from the primary site, but block them from third parties. In others, you can block cookies from specific advertisers, or clear out all cookies. Deleting or blocking cookies may reduce the functionality of the Website. To learn more about how to reject cookies, visit&nbsp;<u>www.allaboutcookies.org</u>&nbsp;or go to the help menu within your internet browser. If you experience any problems having deleted cookies, you should contact the supplier of your web browser.</p>
                            <p>Opting out of Analytical Performance Cookies</p>
                            <p>If you would like to opt-out of Analytics cookies, please do so by visiting on the links below:</p>
                            <p>Google Analytics:&nbsp;<u>https://tools.google.com/dlpage/gaoptout</u>.</p>
                            <p>Opting out of Behavioral Advertising Cookies</p>
                            <p>If you would like more information about how to opt-out of interest-based advertising in desktop and mobile browsers on a particular device, please visit http://optout.aboutads.info/#/ and http://optout.networkadvertising.org/#. You may download the AppChoices app at http://www.aboutads.info/appchoices to opt-out in connection with mobile apps, or use the platform controls on your mobile device to opt-out.&nbsp;</p>
                            <p>ADVERTISING</p>
                            <p>Retargeting Ads</p>
                            <p>From time to time, the Website may engage in remarketing efforts with third-party companies, such as Google, Facebook, or Instagram, in order to market the Website. These companies use cookies to serve ads based on someone's past visits to the Website. This means, after visiting the Website, you may see an ad for our services. However, your personally identifiable information is not used by any remarketing service other than to present you offers from Company. We use the following third-party service providers for remarketing:</p>
                            <p>Affiliate Program Participation</p>
                            <p>The Website may engage in affiliate marketing, which is done by embedding tracking links into the Website. If you click on a link for an affiliate partnership, a cookie will be placed on your browser to track any sales for purposes of commissions.&nbsp;</p>
                            <p>Newsletters</p>
                            <p>On the Website, you may subscribe to our newsletter, which may be used for advertising purposes. All newsletters sent may contain tracking pixels. The pixel is embedded in emails and allows an analysis of the success of online marketing campaigns. Because of these tracking pixels, Company may see if and when you open an email and which links within the email you click. Also, this allows the Company to adapt the content of future newsletters to the interests of the user. This behavior will not be passed on to third parties.</p>
                            <p>HOW LONG WILL YOUR PERSONAL INFORMATION BE KEPT</p>
                            <p>We will keep your personal information while you have an account with us, or we are providing products or services to you. Thereafter, we will keep your personal information for as long as is necessary:</p>
                            <ul>
                                <li>To respond to any questions, complaints, or claims made by you or on your behalf.</li>
                                <li>To show that we treated you fairly.</li>
                                <li>To keep records required by law.</li>
                            </ul>
                            <p>We will not retain your personal information for longer than necessary for the purposes set out in this Privacy Policy. Different retention periods apply for different types of personal information. When it is no longer necessary to retain your personal information, we will delete or anonymize it.</p>
                            <p>RIGHTS RELATED TO YOUR PERSONAL INFORMATION</p>
                            <p>Under laws in certain countries in which we operate, customers and other visitors to our Website from those countries have a right to access Personal Data about themselves, and to amend, correct or delete Personal Data that is inaccurate, incomplete, or outdated. We will, upon request, provide you with confirmation regarding whether we are processing Personal Data about you, consistent with applicable law. In addition, upon your request, we will take reasonable steps to correct, amend, or delete your Personal Data that is found to be inaccurate, incomplete, or processed in a manner non-compliant with this Privacy Policy or applicable law, except where the burden or expense of providing access would be disproportionate to the risks to your privacy, where the rights of persons other than you would be violated or where doing so is otherwise inconsistent with applicable law. Unless prohibited by applicable law, we reserve the right to charge a reasonable fee to cover costs for providing copies of Personal Data that you request.</p>
                            <p>Please note that while any amendments, corrections, or deletions will be reflected in active user databases (as updated within a reasonable period of time), we may retain all Personal Data for backups, archiving, prevention of fraud and abuse, analytics, and satisfaction of other legal obligations we reasonably believe applicable.</p>
                            <p>You authorize us to use and disclose any new or modified information that you provide in accordance with this Privacy Policy, and you agree that we are under no obligation to delete or modify information that you have previously chosen to provide us as to which you have not instructed us to take such action. Please remember, however, that if we have already disclosed some of your Personal Data to third parties, we cannot access that Personal Data any longer and cannot force the deletion or modification of any such information by the parties to whom we have made those disclosures.</p>
                            <p>We may retain your Personal Data to comply with laws, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigations, enforce our contracts, and take other actions otherwise permitted by law.</p>
                            <p>Opt-out &ndash; If you send us an email with questions or comments, we may use your personally identifiable information to respond to your questions or comments, and we may save your questions or comments for future reference. For security reasons, we do not recommend that you send non-public personal information, such as passwords, social security numbers, or bank account information, to us by email. You may "opt-out" of receiving future commercial email communications from us by clicking the "unsubscribe" link included at the bottom of most emails we send, or as provided below; provided, however, we reserve the right to send you transactional emails such as customer service communications. You may also notify us at Terms@HealthyDining.biz to be removed from our mailing list.</p>
                            <p>Access &ndash; You may request access to the personal information we have about you by submitting a request to Terms@HealthyDining.biz.</p>
                            <p>Amend &ndash; You may contact us at Terms@HealthyDining.biz to amend or update your personal information.</p>
                            <p>Forget &ndash; In certain situations, you may request that we erase or forget your Personal Data. To do so, please submit a request to Terms@HealthyDining.biz.</p>
                            <p>Please note that we may need to retain certain information for recordkeeping purposes or to complete transactions, or when required by law.</p>
                            <p>CALIFORNIA RESIDENTS</p>
                            <p>California Residents: Pursuant to the California Consumer Privacy Act of 2019 ("CCPA"), you have certain rights with respect to your personal information. For the purposes of this section, personal information is used as defined in the CCPA. The Company reserves the right to verify all requests made pursuant to the CCPA.</p>
                            <p>Access and Disclosure</p>
                            <p>You may make a verifiable request that we disclose the categories of personal information we have collected about you, the categories of sources of the personal information we collected about you, the business or commercial purpose for collecting or selling the personal information, the categories of third parties with whom we share your personal information, our use of the personal information and if the personal information was disclosed or sold to third parties, including the categories of personal information sold or disclosed. You also have the right to make a verifiable request for a copy of the personal information collected about you for the twelve (12) months prior to the date of your request. The Company has not sold any categories of personal information in the last twelve (12) months. Please note that we are not required to:</p>
                            <ul>
                                <li>Retain any personal information about you that was collected for a single one-time transaction if, in the ordinary course of business, that information about you is not retained;</li>
                                <li>Reidentify or otherwise link any data that, in the ordinary course of business, is not maintained in a manner that would be considered personal information.</li>
                            </ul>
                            <p>Deletion Request Rights</p>
                            <p>You have the right to request that the Company delete any of your personal information that we collected from you and retained, subject to certain exceptions. We may deny your deletion request if retaining the personal information is necessary for us or our service provider(s) to:</p>
                            <ol>
                                <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
                                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
                                <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                                <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
                                <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code &sect; 1546 et. seq.).</li>
                                <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</li>
                                <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
                                <li>Comply with a legal obligation.</li>
                                <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
                            </ol>
                            <p>Children Under 16</p>
                            <p>We do not sell the personal information of consumers we actually know are less than 16 years of age, unless we receive affirmative authorization (the "right to opt-in") from either the consumer who is between 13 and 16 years of age, or the parent or guardian of a consumer less than 13 years of age.&nbsp;</p>
                            <p>No Discrimination</p>
                            <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
                            <ul>
                                <li>Deny you goods or services;</li>
                                <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties;</li>
                                <li>Provide you a different level or quality of goods or services; or</li>
                                <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                            </ul>
                            <p>Exercising Your Rights</p>
                            <p>To exercise the access, disclosure, and deletion rights described above, please submit a verifiable request to us by emailing Terms@HealthyDining.biz, Attn: CCPA.</p>
                            <p>You may only make a verifiable request for access or disclosure twice within a twelve (12) month period. You may also make a verifiable consumer request on behalf of your minor child. The verifiable consumer request must:</p>
                            <ul>
                                <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</li>
                                <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
                            </ul>
                            <p>To respond to your request or provide you with personal information, Company must verify your identity or your authority to make the request. We will only use personal information provided in a verifiable request to verify the requestor's identity or authority to make the request.&nbsp;</p>
                            <p>Once you make an opt-out request, we will wait at least twelve (12) months before asking you to reauthorize personal information sales. However, you may change your mind and opt back in to personal information sales at any time by emailing us at Terms@HealthyDining.biz. Consumers who opt-in to personal information sales may opt-out of future sales at any time.</p>
                            <p>Timing</p>
                            <p>We will respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. We will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the twelve (12) month period preceding the verifiable request's receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable.</p>
                            <p>SENSITIVE PERSONAL INFORMATION</p>
                            <p>At no time should you submit sensitive personal information to the Website. This includes your social security number, information regarding race or ethnic origin, political opinions, religious beliefs, health information, criminal background, or trade union memberships. If you elect to submit such information to us, it will be subject to this Privacy Policy.</p>
                            <p>CHILDREN'S INFORMATION</p>
                            <p>The Website does not knowingly collect any personally identifiable information from children under the age of 16. If a parent or guardian believes that the Website has personally identifiable information of a child under the age of 16 in its database, please contact us immediately at Terms@HealthyDining.biz and we will use our best efforts to promptly remove such information from our records.</p>
                            <p>ARBITRATION</p>
                            <p>This Privacy Policy will be governed and construed in accordance with the laws of the State of California. Any controversy or claim arising out of or relating to the Privacy Policy, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association ("AAA") under its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The place of any such arbitration shall be in or near San Diego County, California. The parties also agree that the AAA Optional Rules for Emergency Measures of Protection shall apply to the proceedings.</p>
                            <p>LINKS TO OTHER WEBSITES</p>
                            <p>This Privacy Policy applies only to the Website. The Website may contain links to other websites not operated or controlled by Company (the "Third-Party Sites"). The policies and procedures described here do not apply to the Third-Party Sites. The links from the Website do not imply that Company endorses or has reviewed the Third-Party Sites. We suggest contacting those sites directly for information on their privacy policies.</p>
                            <p>OTHER TERMS AND CONDITIONS</p>
                            <p>Your access to and use of the Website may also be subject to any separate agreements or terms and conditions you have signed or agreed to with Company. Please refer to those agreements as needed.</p>
                            <p>Regardless of any other provision in this Privacy Policy, we reserve the right to disclose any personally identifiable or non-personally identifiable information about you to: (a) fulfill a government request; (b) conform with the requirements of the law or legal process; (c) protect or defend our legal rights or property, our Website, or other users; or (d) protect, in an emergency, the health and safety of our customers or the general public. This includes exchanging information with third parties and organizations in connection with credit risk reduction and fraud protection.</p>
                            <p>Changes to Company's Privacy Policy</p>
                            <p>The Website may change from time to time. As a result, at times it may be necessary for Company to make changes to this Privacy Policy. The Company reserves the right to update or modify this Privacy Policy at any time and will notify you by email in advance of any changes, provided the Website has your email address. Please review this policy periodically, and especially before you provide any Personal Data. Your continued use of the Website after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.</p>
                            <p>CONTACT INFORMATION</p>
                            <p>At any time, please contact us at Terms@HealthyDining.biz for questions related to this Privacy Policy.</p>
                            <p>Last updated: May 18, 2021.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default withLocalize(withRouter(CalibrateFatMonosaturated));