import React from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';

const Footer = ({history}) => {
  return (
      <div id="profile_footer">
           <ul className="footer_social_icon">
             <li><a href="http://instagram.com/healthydiningfinder" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
             <li><a href="https://twitter.com/DineOutHealthy" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
             <li><a href="http://instagram.com/healthydiningfinder" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
          </ul>
          <div className="footer_logo_wrap text-center">
           <a href="/"><img alt="headerlogo" src="/static/img/headerlogo.png"></img></a>
          </div>
      </div>
  );
}

export default withLocalize(withRouter(Footer));
