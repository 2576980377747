import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import menu from './Menu.reducer'
import counters from './Counters.reducer'


const reducerFrontEnd = combineReducers({
   router:routerReducer,
   menu:menu,
   counters:counters   
})
export default reducerFrontEnd
