import React from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux'
import './FooterMicrosoftLoginPage.css';

const FooterMicrosoftLoginPage = ({history}) => {

  return (
      <footer>

          <div className='container'>
            <div className='row'>
                <div className='col-12 col-sm-12 col-lg-12 copyRight'>
                    <p>Powered by <img src='/static/img/copy-right-heart.png' alt="copy-right-heart"></img> <b>Healthy dining</b></p>
                </div>

            </div>
          </div>


      </footer>
    );
}

export default withLocalize(withRouter(FooterMicrosoftLoginPage));
