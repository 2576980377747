import React from 'react';
import  {BrowserRouter, Route }  from 'react-router-dom';
import { LocalizeProvider } from "react-localize-redux";
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { Provider } from 'react-redux'
import {routerMiddleware} from 'react-router-redux';
import history from './history';
import reducer from '../Reducers'

import HomePage from  '../../FrontEnd/Components/HomePage/HomePage';
import LoginPage from '../../FrontEnd/Components/LoginPage/LoginPage.js';
import RegistrationPage from '../../FrontEnd/Components/RegistrationPage/RegistrationPage.js';
import VerifyEmailPage from '../../FrontEnd/Components/LoginPage/VerifyEmailPage.js';
import ForgotPasswordPage from '../../FrontEnd/Components/ForgotPasswordPage/ForgotPasswordPage.js';
import ConfirmPasswordPage from '../../FrontEnd/Components/ForgotPasswordPage/ConfirmPasswordPage.js';
import DashboardPage from '../../FrontEnd/Components/DashboardPage/DashboardPage.js';
import ArticlesPage from '../Components/InspiredPage/ArticlesPage/ArticlesPage';
import PofilePage from '../../FrontEnd/Components/PofilePage/PofilePage.js';
import NutrientPage from '../../FrontEnd/Components/NutrientPage/NutrientPage.js';
import VegetarianVeganPage from '../../FrontEnd/Components/VegetarianVeganPage/VegetarianVeganPage.js';
import KetoCuratedPage from '../../FrontEnd/Components/KetoCuratedPage/KetoCuratedPage.js';
import PlanetHealthPage from '../../FrontEnd/Components/PlanetHealthPage/PlanetHealthPage.js';
import AllergensGluten from '../../FrontEnd/Components/AllergensGlutenPage/AllergensGluten.js';
import RestaurantsListPage from '../../FrontEnd/Components/RestaurantsListPage/RestaurantsListPage.js';
import RestaurantPage from '../../FrontEnd/Components/RestaurantPage/RestaurantPage.js';
import MenuItemsPage from '../../FrontEnd/Components/MenuItemsPage/MenuItemsPage.js';
import MyMealPage from '../../FrontEnd/Components/MyMealPage/MyMealPage.js';
import TermsAndConsitionsPage from '../../FrontEnd/Components/AllergensGlutenPage/TermsAndConditions.js';
import SubscriptionPage from '../../FrontEnd/Components/SubscriptionPage/SubscriptionPage.js';
import LandingPage from '../../FrontEnd/Components/LandingPage/LandingPage.js';
import PrivacyPage from '../../FrontEnd/Components/PrivacyPage/PrivacyPage.js';
import CalibrateAssessmentPage from '../../FrontEnd/Components/CalibrateAssessmentPage/CalibrateAssessmentPage.js';
import CalibratePage from '../../FrontEnd/Components/CalibratePage/CalibratePage.js';
import CalibrateReportPage from '../../FrontEnd/Components/CalibrateReportPage/CalibrateReportPage.js';
import CalibrateResultsPage from '../../FrontEnd/Components/CalibrateReportPage/CalibrateResultsPage.js';
import CalibrateResultsDevPage from '../../FrontEnd/Components/CalibrateReportPage/CalibrateResultsDevPage.js';
import ContactPage from '../../FrontEnd/Components/ContactPage/ContactPage.js';
import MacroCalculatorPage from '../../FrontEnd/Components/MacroCalculatorPage/MacroCalculatorPage.js';
import HomePageHandler from '../Components/HomePage/HomePageHandler';
import MsDashboardPage from '../../FrontEnd/Components/MicrosoftPage/MsDashboardPage/MsDashboardPage';

//calibrate macronutrients
import CalibrateCalories from '../../FrontEnd/Components/CalibrateReportPage/CalibrateCaloriesPage.js';
import CalibrateProtein from '../../FrontEnd/Components/CalibrateReportPage/CalibrateProteinPage.js';
import CalibrateCarbohydrates from '../../FrontEnd/Components/CalibrateReportPage/CalibrateCarbohydratesPage.js';
import CalibrateSugarTotal from '../../FrontEnd/Components/CalibrateReportPage/CalibrateSugarTotalPage.js';
import CalibrateSugarAdded from '../../FrontEnd/Components/CalibrateReportPage/CalibrateSugarAddedPage.js';
import CalibrateFiber from '../../FrontEnd/Components/CalibrateReportPage/CalibrateFiberPage.js';
import CalibrateCarbohydratesAvailable from '../../FrontEnd/Components/CalibrateReportPage/CalibrateCarbohydratesAvailablePage.js';
import CalibrateFat from '../../FrontEnd/Components/CalibrateReportPage/CalibrateFatPage.js';
import CalibrateFatMonosaturated from '../../FrontEnd/Components/CalibrateReportPage/CalibrateFatMonosaturatedPage.js';
import CalibrateFatPolyunsaturated from '../../FrontEnd/Components/CalibrateReportPage/CalibrateFatPolyunsaturatedPage.js';
import CalibrateFatSaturated from '../../FrontEnd/Components/CalibrateReportPage/CalibrateFatSaturatedPage.js';
import CalibrateCholesterol from '../../FrontEnd/Components/CalibrateReportPage/CalibrateCholesterolPage.js';
import CalibrateOmega3Fat from '../../FrontEnd/Components/CalibrateReportPage/CalibrateOmega3FatPage.js';
import CalibrateFatTrans from '../../FrontEnd/Components/CalibrateReportPage/CalibrateFatTransPage.js';

//calibrate amino acids
import CalibrateHistidine from '../../FrontEnd/Components/CalibrateReportPage/CalibrateHistidinePage.js';
import CalibrateIsoleucine from '../../FrontEnd/Components/CalibrateReportPage/CalibrateIsoleucinePage.js';
import CalibrateLeucine from '../../FrontEnd/Components/CalibrateReportPage/CalibrateLeucinePage.js';
import CalibrateLysine from '../../FrontEnd/Components/CalibrateReportPage/CalibrateLysinePage.js';
import CalibrateMethionine from '../../FrontEnd/Components/CalibrateReportPage/CalibrateMethioninePage.js';
import CalibratePhenylalanine from '../../FrontEnd/Components/CalibrateReportPage/CalibratePhenylalaninePage.js';
import CalibrateThreonine from '../../FrontEnd/Components/CalibrateReportPage/CalibrateThreoninePage.js';
import CalibrateTryptophan from '../../FrontEnd/Components/CalibrateReportPage/CalibrateTryptophanPage.js';
import CalibrateValine from '../../FrontEnd/Components/CalibrateReportPage/CalibrateValinePage.js';

//calibrate vitamins
import CalibrateVitaminB1 from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminB1Page.js';
import CalibrateVitaminB2 from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminB2Page.js';
import CalibrateVitaminB3 from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminB3Page.js';
import CalibrateVitaminB5 from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminB5Page.js';
import CalibrateVitaminB6 from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminB6Page.js';
import CalibrateVitaminB12 from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminB12Page.js';
import CalibrateVitaminFolate from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminFolatePage.js';
import CalibrateVitaminA from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminAPage.js';
import CalibrateVitaminC from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminCPage.js';
import CalibrateVitaminD from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminDPage.js';
import CalibrateVitaminE from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminEPage.js';
import CalibrateVitaminK from '../../FrontEnd/Components/CalibrateReportPage/CalibrateVitaminKPage.js';

//calibrate minerals
import CalibrateCalcium from '../../FrontEnd/Components/CalibrateReportPage/CalibrateCalciumPage.js';
import CalibrateCopper from '../../FrontEnd/Components/CalibrateReportPage/CalibrateCopperPage.js';
import CalibrateIron from '../../FrontEnd/Components/CalibrateReportPage/CalibrateIronPage.js';
import CalibrateMagnesium from '../../FrontEnd/Components/CalibrateReportPage/CalibrateMagnesiumPage.js';
import CalibrateManganese from '../../FrontEnd/Components/CalibrateReportPage/CalibrateManganesePage.js';
import CalibratePhosphorus from '../../FrontEnd/Components/CalibrateReportPage/CalibratePhosphorusPage.js';
import CalibratePotassium from '../../FrontEnd/Components/CalibrateReportPage/CalibratePotassiumPage.js';
import CalibrateSelenium from '../../FrontEnd/Components/CalibrateReportPage/CalibrateSeleniumPage.js';
import CalibrateSodium from '../../FrontEnd/Components/CalibrateReportPage/CalibrateSodiumPage.js';
import CalibrateZinc from '../../FrontEnd/Components/CalibrateReportPage/CalibrateZincPage.js';
import GetInspiredPage from '../Components/LandingPage/GetInspiredPage/GetInspiredPage';


export const makeMainRoutes = () => {
    const historyMiddleware = routerMiddleware(history);
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducer,
        composeEnhancers(
            applyMiddleware(historyMiddleware, thunk)
        )
    )

    let isMSPilot = JSON.parse(localStorage.getItem('userMSPilot'));

    return (
        <Provider store={store}>
            <BrowserRouter history={history}>
                <div className="routesBox">
                    <LocalizeProvider>
                        <Route exact path="/" render={(props) => <HomePageHandler  {...props} />} />
                        <Route exact path="/home" render={(props) => <HomePageHandler  {...props} />} />
                        <Route exact path="/login" render={(props) => <LoginPage  {...props} />} />
                        <Route exact path="/register" render={(props) => <RegistrationPage  {...props} />} />
                        <Route exact path="/verify-email/:emailCode/:key" render={(props) => <VerifyEmailPage  {...props} />} />
                        <Route exact path="/forgot-password" render={(props) => <ForgotPasswordPage  {...props} />} />
                        <Route exact path="/confirm-password/:emailCode/:email" render={(props) => <ConfirmPasswordPage  {...props} />} />
                        <Route exact path="/dashboard" render={(props) => isMSPilot? <MsDashboardPage  {...props} /> : <DashboardPage  {...props} />} />
                        <Route exact path="/msdashboard" render={(props) => <MsDashboardPage  {...props} />} />
                        <Route exact path="/articles" render={(props) => <ArticlesPage  {...props} />} />
                        <Route exact path="/get-inspired" render={(props) => <GetInspiredPage  {...props} />} />
                        <Route exact path="/profile" render={(props) => <PofilePage  {...props} />} />
                        <Route exact path="/nutrient" render={(props) => <NutrientPage  {...props} />} />
                        <Route exact path="/vegetarian-vegan" render={(props) => <VegetarianVeganPage  {...props} />} />
                        <Route exact path="/keto-curated" render={(props) => <KetoCuratedPage  {...props} />} />
                        <Route exact path="/planet-health" render={(props) => <PlanetHealthPage  {...props} />} />
                        <Route exact path="/allergens-gluten" render={(props) => <AllergensGluten  {...props} />} />
                        <Route exact path="/restaurants-list" render={(props) => <RestaurantsListPage  {...props} />} />
                        <Route exact path="/restaurant/:locationId/:iMenuId" render={(props) => <RestaurantPage  {...props} />} />
                        <Route exact path="/menu-item/:iMenuItemId/:iMenuId" render={(props) => <MenuItemsPage  {...props} />} />
                        <Route exact path="/my-meal" render={(props) => <MyMealPage  {...props} />} />
                        <Route exact path="/terms-&-conditions" render={(props) => <TermsAndConsitionsPage  {...props} />} />
                        <Route exact path="/subscription" render={(props) => <SubscriptionPage  {...props} />} />
                        <Route exact path="/landing" render={(props) => <LandingPage  {...props} />} />
                        <Route exact path="/privacy" render={(props) => <PrivacyPage  {...props} />} />
                        <Route exact path="/calibrate-assessment" render={(props) => <CalibrateAssessmentPage  {...props} />} />
                        <Route exact path="/calibrate" render={(props) => <CalibratePage  {...props} />} />
                        <Route exact path="/calibrate-report" render={(props) => <CalibrateReportPage  {...props} />} />
                        <Route exact path="/calibrate-results" render={(props) => <CalibrateResultsPage  {...props} />} />
                        <Route exact path="/calibrate-results-dev" render={(props) => <CalibrateResultsDevPage  {...props} />} />
                        <Route exact path="/contact" render={(props) => <ContactPage  {...props} />} />
                        <Route exact path="/macro-calculator" render={(props) => <MacroCalculatorPage  {...props} />} />

                        <Route exact path="/calibrate-calories" render={(props) => <CalibrateCalories  {...props} />} />
                        <Route exact path="/calibrate-protein" render={(props) => <CalibrateProtein  {...props} />} />
                        <Route exact path="/calibrate-carbohydrates" render={(props) => <CalibrateCarbohydrates  {...props} />} />
                        <Route exact path="/calibrate-sugar-total" render={(props) => <CalibrateSugarTotal  {...props} />} />
                        <Route exact path="/calibrate-sugar-added" render={(props) => <CalibrateSugarAdded  {...props} />} />
                        <Route exact path="/calibrate-fiber" render={(props) => <CalibrateFiber  {...props} />} />
                        <Route exact path="/calibrate-carbohydrates-available" render={(props) => <CalibrateCarbohydratesAvailable  {...props} />} />
                        <Route exact path="/calibrate-fat" render={(props) => <CalibrateFat  {...props} />} />
                        <Route exact path="/calibrate-fat-monosaturated" render={(props) => <CalibrateFatMonosaturated  {...props} />} />
                        <Route exact path="/calibrate-fat-polyunsaturated" render={(props) => <CalibrateFatPolyunsaturated  {...props} />} />
                        <Route exact path="/calibrate-fat-saturated" render={(props) => <CalibrateFatSaturated  {...props} />} />
                        <Route exact path="/calibrate-cholesterol" render={(props) => <CalibrateCholesterol  {...props} />} />
                        <Route exact path="/calibrate-omega3fat" render={(props) => <CalibrateOmega3Fat  {...props} />} />
                        <Route exact path="/calibrate-fat-trans" render={(props) => <CalibrateFatTrans  {...props} />} />

                        <Route exact path="/calibrate-histidine" render={(props) => <CalibrateHistidine  {...props} />} />
                        <Route exact path="/calibrate-isoleucine" render={(props) => <CalibrateIsoleucine  {...props} />} />
                        <Route exact path="/calibrate-leucine" render={(props) => <CalibrateLeucine  {...props} />} />
                        <Route exact path="/calibrate-lysine" render={(props) => <CalibrateLysine  {...props} />} />
                        <Route exact path="/calibrate-methionine" render={(props) => <CalibrateMethionine  {...props} />} />
                        <Route exact path="/calibrate-phenylalanine" render={(props) => <CalibratePhenylalanine  {...props} />} />
                        <Route exact path="/calibrate-threonine" render={(props) => <CalibrateThreonine  {...props} />} />
                        <Route exact path="/calibrate-tryptophan" render={(props) => <CalibrateTryptophan  {...props} />} />
                        <Route exact path="/calibrate-valine" render={(props) => <CalibrateValine  {...props} />} />

                        <Route exact path="/calibrate-vitaminb1" render={(props) => <CalibrateVitaminB1  {...props} />} />
                        <Route exact path="/calibrate-vitaminb2" render={(props) => <CalibrateVitaminB2  {...props} />} />
                        <Route exact path="/calibrate-vitaminb3" render={(props) => <CalibrateVitaminB3  {...props} />} />
                        <Route exact path="/calibrate-vitaminb5" render={(props) => <CalibrateVitaminB5  {...props} />} />
                        <Route exact path="/calibrate-vitaminb6" render={(props) => <CalibrateVitaminB6  {...props} />} />
                        <Route exact path="/calibrate-vitaminb12" render={(props) => <CalibrateVitaminB12  {...props} />} />
                        <Route exact path="/calibrate-vitaminfolate" render={(props) => <CalibrateVitaminFolate  {...props} />} />
                        <Route exact path="/calibrate-vitamina" render={(props) => <CalibrateVitaminA  {...props} />} />
                        <Route exact path="/calibrate-vitaminc" render={(props) => <CalibrateVitaminC  {...props} />} />
                        <Route exact path="/calibrate-vitamind" render={(props) => <CalibrateVitaminD  {...props} />} />
                        <Route exact path="/calibrate-vitamine" render={(props) => <CalibrateVitaminE  {...props} />} />
                        <Route exact path="/calibrate-vitamink" render={(props) => <CalibrateVitaminK  {...props} />} />

                        <Route exact path="/calibrate-calcium" render={(props) => <CalibrateCalcium  {...props} />} />
                        <Route exact path="/calibrate-copper" render={(props) => <CalibrateCopper  {...props} />} />
                        <Route exact path="/calibrate-iron" render={(props) => <CalibrateIron  {...props} />} />
                        <Route exact path="/calibrate-magnesium" render={(props) => <CalibrateMagnesium  {...props} />} />
                        <Route exact path="/calibrate-manganese" render={(props) => <CalibrateManganese  {...props} />} />
                        <Route exact path="/calibrate-phosphorus" render={(props) => <CalibratePhosphorus  {...props} />} />
                        <Route exact path="/calibrate-potassium" render={(props) => <CalibratePotassium  {...props} />} />
                        <Route exact path="/calibrate-selenium" render={(props) => <CalibrateSelenium  {...props} />} />
                        <Route exact path="/calibrate-sodium" render={(props) => <CalibrateSodium  {...props} />} />
                        <Route exact path="/calibrate-zinc" render={(props) => <CalibrateZinc  {...props} />} />
                    </LocalizeProvider>
                </div>
            </BrowserRouter>
        </Provider>
    );
}
