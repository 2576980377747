import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import './HeaderMicrosoftLoginPage.css';
import { withLocalize } from 'react-localize-redux';


const HeaderMicrosoftLoginPage = ({ headerName }) => {

  const [loader, setLoader] = useState('false');

  return (
    <header>
        <div className="actionProcess" style={{ display: loader ? 'none' : 'block' }}>
            <img src="/static/img/loader.gif" alt="Loading" />
        </div>
        <div className='logoWrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-sm-12'>
                         {/* <img src="/static/img/headerlogo_mm.png" className='logo img-fluid' alt="headerlogo" />*/}
                          <img src="/static/img/MyMenuDiningConciergeLogo" className='logo img-fluid' alt="headerlogo" />
                {/*<img src="/static/img/microsoft_logo.png" className='logo img-fluid' alt="microsoft_logo"/>*/}
              </div>
            </div>
          </div>
        </div>
    </header>
  );
}

function LogOff() {
  localStorage.clear();
  window.location.href = '/';
}

export default withLocalize(withRouter(HeaderMicrosoftLoginPage));
