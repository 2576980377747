import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import PlacesAutocomplete, { geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import { appConstants } from '../_Constants';

var componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
        lat:"lat",
        lng:"lng"
};

var addressData = {componentForm}

const GeoAddress = (props) => {
    const [address, setAddress] = useState('');
    /* function for set address  */
    const handleChange = (address) => {
        if (address) {
            setAddress(address);
        }
    };

    const handleSelect = (address) => {
        let formDataState = props.formDataState;
        formDataState = props.state[formDataState];
        geocodeByAddress(address)
            .then(results => {
                var addressString = '';
                const address = results[0];
                let { geometry } = address;
                let { location } = geometry;
                for (var i = 0; i < results[0].address_components.length; i++) {
                    var addressType = results[0].address_components[i].types[0];
                    var val = results[0].address_components[i][componentForm[addressType]];
                    if (val !== undefined) {
                        addressString += " " + val;
                    }
                }
                setAddress(addressString);
                localStorage.setItem("formDataOption", JSON.stringify({ addressString, location: { lat: location.lat(), lng: location.lng() } }))
                props.parentCallback({ addressString, location: { lat: location.lat(), lng: location.lng() } });
            })
            .catch(error => console.error('GeoAddress Error', error));
    };

    useEffect(() => {
      let addr = localStorage.getItem("local_address");
      if(addr)
      {
        let addressObj = JSON.parse(addr);
        setAddress(addressObj.addressString);
        props.parentCallback(addressObj);
      }
      handleSelect(props.currentValue);
    }, []);

    return (
        <PlacesAutocomplete
            //value={(address!=="")?address:props.currentValue}
            value={address}
            onChange={ e => handleChange(e) }
            onSelect={handleSelect}
      >

        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className='autocomplete-dropdown-container-main'>
            <div className="row">
                <div className="col-sm-12">
                     <input                       
                     id={props.geoAddress["street_number"]}
                      {...getInputProps({
                        placeholder:'Search Places ...',
                        className: 'form-control',                        
                      })}
                    />
                </div>
            </div>

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, si) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div key={si}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <div>{suggestion.description}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

  );
}
export default withLocalize(withRouter(GeoAddress));
