import React, { useEffect } from 'react';
import { withRouter, useParams } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { Form } from 'reactstrap';
import { verifyEmailAction } from '../../Actions/User.action';
import { sendEmailAction } from '../../Actions/Utility.action';
import toaster from '../../../_Common/Toaster';
import { appConstants, mailTemplate } from '../../../_Constants';
import './LoginPage.css';

const VerifyEmailPage = ({ history }) => {

    const { emailCode, key } = useParams();

    /*call api to verify user account*/
    const getVerifyEmail = async () => {

        let data = {
            eLoginProvider: 'Manual',
            sProviderKey: key,
            iEmailCode: emailCode
        }

        let res = await verifyEmailAction(data);
        let status = res && res.status;

        if (status === 200) {

            /* call the api to send email*/
            if (res && res.data && res.data[0]) {
                await sendEmailFun(res.data[0]);
            }

            setTimeout(function () {
                //history.push('/login');
                window.location.href = '/login';
            }, 4000);
        } else {
            toaster.show({ message: appConstants.RESPONSECODE[status], intent: "danger" });
        }
        setTimeout(function () {
            toaster.clear();
        }, 1000);
    }

    /* send email function */
    const sendEmailFun = async (signupData) => {
        if (signupData.iEmailCode && signupData.sProviderKey) {
            let link = appConstants.FRONTURL + 'login';

            var res = (mailTemplate.WELCOME_TEMPL).replace("#link", '<a style="color: #ef2859" target="_blank" href="' + link + '">LOGIN LINK</a>');
            res = (res).replace("#name", signupData.sFirstName);
            res = (mailTemplate.TEMPLATE).replace("#conetnt", res);

            let senData = {
                'sReceiver': signupData.sEmail,
                'sSubject': mailTemplate.WELCOME_SUB,
                'sMessage': res,
            }

            let resData = await sendEmailAction(senData);
            let status = resData && resData.status;

            if (status === 200) {
            } else {
                toaster.show({ message: appConstants.RESPONSECODE[status], intent: "danger" });
            }

        }
    }

    useEffect(() => {
        getVerifyEmail();
    }, []);

    return (
        <div className="landing_wraper ">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="common_form_sec verify_email_form">
                            <div className="common_form_secinner">
                                <Form>
                                    <div>
                                        <p className="tick-i"><i className="fa fa-check" aria-hidden="true"></i></p>
                                        <h5>Your account has been verified.</h5>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default withLocalize(withRouter(VerifyEmailPage));
