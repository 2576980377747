import React from "react"

const MSPrivacyPolicy = () => {
    return (

        <div className="modal fade terms_conditions" id="MSPrivacyModal" tabIndex="-1" role="dialog" aria-labelledby="PrivacyModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title modal-title-landing">PRIVACY POLICY</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            This Privacy Policy ("Privacy Policy") outlines Accents on Health, Inc. DBA Healthy Dining's (“Company” or "Healthy Dining") policy with respect to information, including personally identifiable data ("Personal Data") and other information, Healthy Dining collects from you as a user of the MyMenu digital platform found at MSTravel.MyMenuConcierge.com (to be termed “MyMenu MSTravel” or “Digital Platform” or “Progressive Web Application” throughout this Privacy Policy). Healthy Dining, owner and operator of this MyMenu MSTravel platform, is a corporation formed under the laws of the state of California, United States. If you choose to use MyMenu MSTravel, your use is hereby deemed as acceptance of the Healthy Dining’s practices described in this Privacy Policy.
                            Healthy Dining highly respects your privacy and is committed to protecting it. MyMenu MSTravel is a private and secure Digital Platform that is only accessible by Microsoft employees and other employment positions which Microsoft has approved. This Privacy Policy applies only to this MSTravel.MyMenuConcierge.com digital platform. This Privacy Policy does not apply to information of any kind that Healthy Dining collects through other MyMenu® public or commercial products which you may otherwise access or use through restaurants’ websites and mobile apps, MyMenuConcierge.com, MyMenuUSA.com, and/or any other current or future products which have separate Privacy Policies and Terms of Conditions.
                        </p>
                        <p>Personally Identifiable Information includes: “Contact Data” (such as your name, address, city, state, zip code, phone number, and email address); and (“Demographic Data” (such as your zip code and mobile phone number).
                        </p>
                        <p>
                            <b> We do not sell your Personal Data. We do not share your Personal Data.</b>
                        </p>
                        <p>
                            The purpose of this Privacy Policy is to inform you what Personal Data we may collect and how it may be used. Any or all Personal Data and other voluntary data collected in the use of MyMenu MSTravel may be used in a <b>fully anonymous method to produce aggregate analytics</b> which, in turn, Healthy Dining can share with Microsoft leadership and/or through other forms of communications such as press releases, articles, newsletters, social media, sales and marketing materials and/or any other communication venues. Aggregated means data from MyMenu MSTravel users in which individual identities and other Personal Information has been removed and/or deidentified so it cannot be easily linked back to an individual. The Raw data may be shared with Microsoft leadership – though all personal identifying information, such as email addresses and IP addresses, mobile cell numbers and/or any othr personal identifying information, will be stripped before sharing the raw data with Microsoft. </p>
                        <p>
                            The general purpose of the aggregated analytics and associated communications is to:
                        </p>
                        <ul style={{ display: "flex", flexDirection: "column" }}>
                            <li>                            •	Broaden and deepen the corporate business-focused body of knowledge and understanding of eating styles and food preferences and resulting health, wellbeing, performance, and financial impact – including employers, the travel industry, and the hospitality industry
                            </li>
                            <li>
                                •	Heighten understanding of how MyMenu can best support the business traveler through access to more food-related data, recommendations, customization, and digital ordering integrations
                            </li>
                        </ul>

                        <p>
                            WHAT INFORMATION DO WE COLLECT AND HOW IS IT USED? <br />
                            Information You Voluntarily Submit: MyMenu MSTravel collects personal information and other information from you such as described below. Our legal basis for aggregating this information is your consent, and by voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy. If you provide Personal Data to us, you acknowledge and agree that such Personal Data may be transferred and stored from your current location to the offices and Azure cloud services of the Healthy Dining. This information may include some, all or more of the following:
                        </p>
                        <ul style={{ display: "flex", flexDirection: "column" }}>
                            <li>                            •	Name (first, last, middle)

                            </li>
                            <li>
                                •	Microsoft email address
                            </li>
                            <li>
                                •	Zip code
                            </li>
                            <li>
                                •	Mobile phone number
                            </li>
                            <li>
                                •	Age range
                            </li>
                            <li>
                                •	Other demographic information related to the services
                            </li>
                            <li>
                                •	Survey data responses
                            </li>
                            <li>
                                •	Restaurant and menu item searches and views
                            </li>
                            <li>
                                •	Personalized preference settings
                            </li>
                            <li>
                                •	Blog article views
                            </li>
                            <li>
                                •	Nutrient assessment and report analytics
                            </li>
                            <li>
                                •	Feedback, questions, restaurant suggestions, reviews
                            </li>
                            <li>
                                •	Any other information voluntarily submitted
                            </li>
                        </ul>
                        <p>
                            Automatically-Collected Information: The Digital Platform automatically collects certain information about you and the device with which you access the Platform and/or uses Google Analytics and/or cookies. For example, when you use the MyMenu MSTravel, your IP address, operating system type, browser type, pages you viewed, and the dates/times when you accessed are collected. The Digital Platform may also collect information about actions you take when using MyMenu MSTravel, such as links clicked, pages/screens accessed and other user behavior actions.
                        </p>
                        <p>Third Party Service Providers: At this time, your personal information is not shared with any other third-party service providers. This list may be amended from time to time in Healthy Dining’s sole discretion. The following third party providers are paid for services related to the MyMenu MSTravel Digital Platform but neither party receives any Personal Data about you from Healthy Dining.
                        </p>
                        <ul style={{ display: "flex", flexDirection: "column" }}>
                            <li>
                                •	DietID is a nutrient assessment that is offered to you voluntarily. The assessment collects Personal Data and health information and provides the data back to Healthy Dining. Healthy Dining then prepares a confidential and personalized report which is presented in your secure MyMenu MSTravel platform. Our contract with Diet ID is in compliance with this Privacy Policy and Diet ID does not have access to your Personal Data.
                            </li>
                            <li> •	Microsoft Azure – this service is used to cloud host the MyMenu applications and database.  We do not share any of your personal information with Microsoft Azure, nor does Microsoft Azure have access to your personal information in our database.
                            </li>
                        </ul>
                        <p>Except when required by law, Healthy Dining will not sell, distribute, or reveal your email addresses or other personal information without your consent.
                        </p>
                        <p>HOW YOUR INFORMATION MAY BE USED <br />
                            Healthy Dining may use the information collected in the following ways:
                        </p>
                        <ul style={{ display: "flex", flexDirection: "column" }}>
                            <li>
                                •	To operate and maintain the Digital Platform;
                            </li><li>                            •	To send you information, such as newsletters or feature updates. Each email will provide information on how to opt-out of future mailings;
                            </li><li>                        •	To send you administrative communications, such as administrative emails, confirmation emails, technical notices, updates on policies, or security alerts;
                            </li><li>•	To respond to your comments or inquiries;
                            </li><li>•	To provide you with user support;
                            </li><li>•	To provide training and quality control;
                            </li><li>•	To aggregate data in accordance to this Privacy Policy
                            </li><li>•	To protect, investigate, and deter against unauthorized or illegal activity.</li>
                        </ul>
                        <p>
                            Legal Requirements: Healthy Dining may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation or to respond to requests from law enforcement or other government officials relating to investigations or alleged illegal activity or in connection with our own investigation of suspected or actual illegal activity, in which case we may (and you hereby authorize us to) disclose information without subpoenas or warrants served on us, (ii) protect and defend the rights or property of Healthy Dining, (iii) act in urgent circumstances to protect the personal safety of users of the Digital Platform or the public, (iv) protect against legal liability including to resolve disputes, investigate problems, or enforce our customer contracts.
                        </p>
                        <p>How can you manage or opt out of cookies?<br />
                            Cookies, including those which have already been set, can be deleted from your hard drive. You can also change the preferences/settings in your web browser to control cookies. In some cases, you can choose to accept cookies from the primary site, but block them from third parties. In others, you can block cookies from specific advertisers, or clear out all cookies. Deleting or blocking cookies may reduce the functionality of the Digital Platform. To learn more about how to reject cookies, visit www.allaboutcookies.org or go to the help menu within your internet browser. If you experience any problems having deleted cookies, you should contact the supplier of your web browser.
                            Opting out of Analytical Performance Cookies
                        </p>
                        <p>If you would like to opt-out of Analytics cookies, please do so by clicking on the links below: <br />
                            Google Analytics: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
                        </p>
                        <p>HOW LONG WILL YOUR PERSONAL INFORMATION BE KEPT <br />
                            We will keep your personal information while you have an account with us, or we are providing products or services to you. Thereafter, we will keep your personal information for as long as is necessary:
                        </p>
                        <ul style={{ display: "flex", flexDirection: "column" }}>
                            <li>
                                •    To respond to any questions, complaints, or claims made by you or on your behalf.
                            </li><li>
                                •    To show that we treated you fairly.
                            </li><li>
                                •    To keep records required by law.
                            </li>
                        </ul>
                        We will not retain your personal information for longer than necessary for the purposes set out in this Privacy Policy. Different retention periods apply for different types of personal information. When it is no longer necessary to retain your personal information, we will delete or anonymize it.

                        <p>RIGHTS RELATED TO YOUR PERSONAL INFORMATION <br />
                            Under laws in certain countries in which we operate, customers and other visitors to our Digital Platform from those countries have a right to access Personal Data about themselves, and to amend, correct or delete Personal Data that is inaccurate, incomplete, or outdated.  We will, upon request, provide you with confirmation regarding whether we are processing Personal Data about you, consistent with applicable law. In addition, upon your request, we will take reasonable steps to correct, amend, or delete your Personal Data that is found to be inaccurate, incomplete, or processed in a manner non-compliant with this Privacy Policy or applicable law, except where the burden or expense of providing access would be disproportionate to the risks to your privacy, where the rights of persons other than you would be violated or where doing so is otherwise inconsistent with applicable law. Unless prohibited by applicable law, we reserve the right to charge a reasonable fee to cover costs for providing copies of Personal Data that you request.
                            <br /> Please note that while any amendments, corrections, or deletions will be reflected in active user databases (as updated within a reasonable period of time), we may retain all Personal Data for backups, archiving, prevention of fraud and abuse, analytics, and satisfaction of other legal obligations we reasonably believe applicable.
                            <br /> You authorize us to use and disclose any new or modified information that you provide in accordance with this Privacy Policy, and you agree that we are under no obligation to delete or modify information that you have previously chosen to provide us as to which you have not instructed us to take such action.
                            <br /> We may retain your Personal Data to comply with laws, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigations, enforce our contracts, and take other actions otherwise permitted by law.
                            Opt-out – If you send us an email with questions or comments, we may use your personally identifiable information to respond to your questions or comments, and we may save your questions or comments for future reference. For security reasons, we do not recommend that you send non-public personal information, such as passwords, social security numbers, or bank account information, to us by email. You may "opt-out" of receiving future commercial email communications from us by clicking the "unsubscribe" link included at the bottom of most emails we send, or as provided below. You may also notify us at Terms@HealthyDining.biz to be removed from our mailing list.
                            <br /> Access – You may request access to the personal information we have about you by submitting a request to Terms@HealthyDining.biz.
                            <br /> Amend – You may contact us at Terms@HealthyDining.biz to amend or update your personal information.
                            <br /> Forget – In certain situations, you may request that we erase or forget your Personal Data. To do so, please submit a request to Terms@HealthyDining.biz.
                            <br /> Please note that we may need to retain certain information for recordkeeping purposes or to complete transactions, or when required by law.
                        </p>
                        <p>CALIFORNIA RESIDENTS<br />
                            California Residents:  Pursuant to the California Consumer Privacy Act of 2019 ("CCPA"), you have certain rights with respect to your personal information.  For the purposes of this section, personal information is used as defined in the CCPA.  The Healthy Dining reserves the right to verify all requests made pursuant to the CCPA.
                            <br />     Access and Disclosure
                            <br /> You may make a verifiable request that we disclose the categories of personal information we have collected about you, the categories of sources of the personal information we collected about you, the business or commercial purpose for collecting or selling the personal information, the categories of third parties with whom we share your personal information, our use of the personal information and if the personal information was disclosed or sold to third parties, including the categories of personal information sold or disclosed.  You also have the right to make a verifiable request for a copy of the personal information collected about you for the twelve (12) months prior to the date of your request.   The Healthy Dining has not sold any categories of personal information in the last twelve (12) months.  Please note that we are not required to:
                        </p>
                        <ul style={{ display: "flex", flexDirection: "column" }}>
                            <li>
                                •	Retain any personal information about you that was collected for a single one-time transaction if, in the ordinary course of business, that information about you is not retained;

                            </li>
                            <li>
                                •	Reidentify or otherwise link any data that, in the ordinary course of business, is not maintained in a manner that would be considered personal information.

                            </li>
                        </ul>
                        <p>Deletion Request Rights <br />
                            You have the right to request that the Healthy Dining delete any of your personal information that we collected from you and retained, subject to certain exceptions.  We may deny your deletion request if retaining the personal information is necessary for us or our service provider(s) to:
                        </p>
                        <ul style={{ display: "flex", flexDirection: "column" }}>
                            <li>

                                1.   Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.
                            </li><li>2.   Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.
                            </li><li>3.   Debug products to identify and repair errors that impair existing intended functionality.
                            </li><li>4.   Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.
                            </li><li>5.   Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).
                            </li><li>6.   Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.
                            </li><li>7.   Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.
                            </li><li>8.   Comply with a legal obligation.
                            </li><li>9.   Make other internal and lawful uses of that information that are compatible with the context in which you provided it.
                            </li>
                        </ul>
                        <p>Children Under 16 <br />
                            We do not sell the personal information of consumers we actually know are less than 16 years of age, unless we receive affirmative authorization (the "right to opt-in") from either the consumer who is between 13 and 16 years of age, or the parent or guardian of a consumer less than 13 years of age.
                        </p>
                        <p>No Discrimination<br />
                            We will not discriminate against you for exercising any of your CCPA rights.  Unless permitted by the CCPA, we will not:
                        </p>
                        <ul style={{ display: "flex", flexDirection: "column" }}>
                            <li>
                                •    Deny you goods or services;
                            </li><li>
                                •    Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties;
                            </li><li>•    Provide you a different level or quality of goods or services; or
                            </li><li>•    Suggest that you may receive a different price or rate for goods or services or a different level or  quality of goods or services.
                            </li>
                        </ul>
                        <p>Exercising Your Rights <br />
                            To exercise the access, disclosure, and deletion rights described above, please submit a verifiable request to us by emailing Terms@HealthyDining.biz, Attn: CCPA.
                            You may only make a verifiable request for access or disclosure twice within a twelve (12) month period.  You may also make a verifiable consumer request on behalf of your minor child. The verifiable consumer request must:
                        </p>
                        <ul style={{ display: "flex", flexDirection: "column" }}>
                            <li>
                                •    Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.
                            </li>
                            <li> •    Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
                            </li>
                        </ul>
                        <p>To respond to your request or provide you with personal information, Healthy Dining must verify your identity or your authority to make the request.  We will only use personal information provided in a verifiable request to verify the requestor's identity or authority to make the request.
                            Once you make an opt-out request, we will wait at least twelve (12) months before asking you to reauthorize personal information sales.  However, you may change your mind and opt back in to personal information sales at any time by emailing us at Terms@HealthyDining.biz.  Consumers who opt-in to personal information sales may opt-out of future sales at any time.
                            Timing
                        </p>
                        <p>We will respond to a verifiable consumer request within forty-five (45) days of its receipt.  If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.  We will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the twelve (12) month period preceding the verifiable request's receipt.  The response we provide will also explain the reasons we cannot comply with a request, if applicable.
                        </p>
                        <p>
                            SENSITIVE PERSONAL INFORMATION<br />
                            At no time should you submit sensitive personal information to the Digital Platform. This includes your social security number, information regarding race or ethnic origin, political opinions, religious beliefs, health information, criminal background, or trade union memberships. If you elect to submit such information to us, it will be subject to this Privacy Policy.
                        </p>
                        <p>CHILDREN'S INFORMATION<br />
                            The Digital Platform does not knowingly collect any personally identifiable information from children under the age of 16. If a parent or guardian believes that the Digital Platform has personally identifiable information of a child under the age of 16 in its database, please contact us immediately at Terms@HealthyDining.biz and we will use our best efforts to promptly remove such information from our records.
                            ARBITRATION
                        </p>
                        <p>This Privacy Policy will be governed and construed in accordance with the laws of the State of California. Any controversy or claim arising out of or relating to the Privacy Policy, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association ("AAA") under its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The place of any such arbitration shall be in or near San Diego County, California. The parties also agree that the AAA Optional Rules for Emergency Measures of Protection shall apply to the proceedings.
                        </p>
                        <p>OTHER TERMS AND CONDITIONS<br />
                            Your access to and use of the Digital Platform may also be subject to any separate agreements or terms and conditions you have signed or agreed to with Healthy Dining.  Please refer to those agreements as needed.
                            Regardless of any other provision in this Privacy Policy, we reserve the right to disclose any personally identifiable or non-personally identifiable information about you to: (a) fulfill a government request; (b) conform with the requirements of the law or legal process; (c) protect or defend our legal rights or property, our Digital Platform, or other users; or (d) protect, in an emergency, the health and safety of our customers or the general public. This includes exchanging information with third parties and organizations in connection with credit risk reduction and fraud protection.

                        </p>
                        <p>Changes to Healthy Dining's Privacy Policy<br />
                            The Digital Platform may change from time to time. As a result, at times it may be necessary for Healthy Dining to make changes to this Privacy Policy. The Healthy Dining reserves the right to update or modify this Privacy Policy at any time and will notify you by email in advance of any changes, provided the Digital Platform has your email address. Please review this policy periodically, and especially before you provide any Personal Data. Your continued use of the Digital Platform after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.
                        </p>
                        <p>CONTACT INFORMATION<br />
                            At any time, please contact us at Terms@HealthyDining.biz for questions related to this Privacy Policy.
                            Last updated: September 12, 2022.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MSPrivacyPolicy