import React,{useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const MessageModal = (props, parentCallback) => {

  const {buttonLabel, className } = props;
  const [modal, setModal] = useState(false);

  /* Funtion for manage open/close modal */
  const toggle=(title)=> {

    if(typeof title == 'string' && window.filter_click){
      window.filter_click(title);
    }

    if(modal){
      props.parentCallback();
    }
      setModal(!modal);
  }

  return (
    <div>
       <i className="fa fa-info-circle" onClick={()=>toggle(props.formTitle)} aria-hidden="true"></i>
       <Modal isOpen={modal} toggle={toggle} className="entity_popup">
         <ModalHeader toggle={toggle}>{props.formTitle}</ModalHeader>
         <ModalBody>
           {props.formData}
         </ModalBody>
       </Modal>
    </div>
  );
}
export default withLocalize(withRouter(MessageModal));
