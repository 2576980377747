import {MENU_LIST} from '../Actions/actionTypes';
const initState = {status:false,menuList:[]}

//Define Actions
const characterReducer = (state = initState, action) => {
    switch (action.type) {
        case MENU_LIST:
            return {
                ...state,
                menuList: action.payload,
                status:action.status
            }
        default:
            return state
    }
}

export default characterReducer;
