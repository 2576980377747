import React,{useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
const ModalComponent = (props) => {
  const {
    buttonLabel,
    className
  } = props;
  
  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle} className={className}>
        <ModalHeader toggle={props.toggle}>{props.formTitle}</ModalHeader>
        <ModalBody>
          {props.formData}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.toggle}>Do Somethingssss</Button>{' '}
          <Button color="secondary" onClick={props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}



export default withLocalize(withRouter(ModalComponent));


/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
export const ConfirmModalComponent = (props) => {
  const {
    buttonLabel,
    className
  } = props;
  
  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle} className={className}>
        <ModalHeader toggle={props.toggle}>{props.formTitle}</ModalHeader>
        <ModalBody>
          {props.formData}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.okButton.function}>{props.okButton.label}</Button>{' '}
          <Button color="secondary" onClick={props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

/* Nutrient Assessment FirstTime Modal */

export const NutrientAssessmentFirstTimeModal = (props) => {
  const {} = props;
 
  return(
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle} className="res_popup" style={{maxWidth: '1000px'}}>
        <ModalHeader toggle={props.toggle}></ModalHeader>
        <ModalBody style={{background: '#fff', padding:'0'}}>
          <img src="/static/img/nutrient-assessment.png" />
          <ModalFooter style={{justifyContent:'center'}}>
            <div className='startAssBtn' onClick={e => window.location.href = '/calibrate-results'}>Start Assessment</div>
          </ModalFooter>
        </ModalBody>
      </Modal>
    </div>
  )
}


/* Nutrient Assessment FirstTime Modal */

export const GoToRestaurantsModal = (props) => {
  const {} = props;
 
  return(
    <div>
      <Modal isOpen={props.modal} toggle={props.toggle} className="res_popup" style={{maxWidth: '1000px'}}>
        <ModalHeader toggle={props.toggle}></ModalHeader>
        <ModalBody className='goToRestaurantModel' style={{background: '#fff', padding:'0'}}>
            <h2>Access available on 9/20/2022!</h2>
        </ModalBody>
      </Modal>
    </div>
  )
}




