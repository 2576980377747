import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux'
import { Button, Form, FormGroup, Input } from 'reactstrap';
import HeaderPage from '../HeaderPage/HeaderPage.js';
import FooterPage from '../FooterPage/FooterPage.js';
import {getDefaultFiltersByTypeAction} from '../../Actions/Utility.action';
import { appConstants } from '../../../_Constants';
import toaster from '../../../_Common/Toaster';
import {termConditionsAction} from '../../Actions/Utility.action';

import './AllergensGluten.css';

const TermsAndConditions = ({history}) => {

  const [list,setList] = useState([]);
  const [formData,setFormData] = useState({});
  const [loader,setLoader] = useState('false');

//  const [checkBtn,setCheckBtn] = useState(false);

  const getTermAndConditions = async () => {
      setLoader(true);
      const res = await termConditionsAction();
      let status = res && res.status;
      if(status === 200){
        console.log(res)
          setList(res.data);
          setFormData(res.data && res.data[0]);
          setLoader(false);
      }else{
          setLoader(false);
          toaster.show({message: appConstants.RESPONSECODE[status], intent: "danger"});
      }
    }


    useEffect(() => {
      getTermAndConditions();
      const formData = JSON.parse(localStorage.getItem('userDetails'));
      const {UserPreferences} = formData;

      setFormData(formData);
    }, []);

 console.log(formData)

  return (
    <div className="App">
     <HeaderPage />
     <div className="actionProcess" style={{ display: loader ? 'block' : 'none' }}>
        <img src="/static/img/loader.gif" alt="Loading" />
     </div>
     <header className="App-header">
        <div className="inner_page_main allergens_page">
           <div className="container">
              <div className="row">
                 <div className="col-md-12">
                    <div className="inner_page_innersec">
                       <h1 className="text-center">Personalize Your Preferences</h1>
                       <div className="entity_page_box">
                          <div className="entity_page_boxtop">
                             <div className="entity_header">
                                <a href="javascript:void(0);" onClick={e => window.location.href = '/dashboard'} aria-label="Previousicon"><img src="/static/img/Previousicon.png" alt="Previousicon"/></a>
                                <h3 className="h4">Preferences</h3>
                             </div>
                             <h4>Terms & Conditions</h4>
                          </div>
                          <div className="entity_page_boxbtm">
                             <div className="entity_page_boxbtminner">
                                <div className="allergens_page_sec text-center row spc-termsi">
                                {formData !== "" &&
                                  <div className="allergens_singleinner">
                                     <div dangerouslySetInnerHTML={{__html: formData.sDisclaimer}} />
                                  </div>
                                 }
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
          </header>
          <FooterPage footerName="dashboard" />
  </div>
    );
}
export default withLocalize(withRouter(TermsAndConditions));
