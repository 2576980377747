import React from 'react';
import { useLocation, withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import HeaderPage from '../../HeaderPage/HeaderPage';
import FooterPage from '../../FooterPage/FooterPage';
import './GetInspiredPage.css';
import $ from 'jquery';

const GetInspiredPage = ({history}) => {

    const location = useLocation();
    console.log(location);

    return (
        <> 
        <div className="App" style={{background:'#fff'}}>
            <HeaderPage headermobileClass="mobile_header_show" headerName="dashboard" />
                {   location.state && location.state.link && 
                    <iframe src={location.state.link} title="mobile-header"></iframe>
                }
            <FooterPage></FooterPage> 
        </div>
        </>
    );

}

export default withLocalize(withRouter(GetInspiredPage))
