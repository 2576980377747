import axios from "axios"
import toastr from "../_Common/Toaster";
import history from '../FrontEnd/routes/history';
import { appConstants } from '../_Constants';

export const request = async (path, data, method,paginationObj, dataType) => {

  /* post data with the url */
	const serialize = ( obj ) => {
	    let str = '?' + Object.keys(obj).reduce(function(a, k){
	        a.push(k + '=' + encodeURIComponent(obj[k]));
	        return a;
	    }, []).join('&');
	    return str;
    }

    let userData = JSON.parse(localStorage.getItem('userDetails'));
    let sUserId = '1234567890'
    if (userData && userData.sUserId) {
        sUserId = userData.sUserId;
    }

	var options = {
		method: method,
		url: `${process.env.REACT_APP_API_URL}/${path}`,
		headers: {
		 	 "Content-Type":"application/json",
			 "apikey":process.env.REACT_APP_API_KEY,
			 'crossDomain': true,
			 'Paging-Headers':paginationObj,
            'Access-Control-Expose-Headers': 'Paging-Headers,Content-Length',
            'aspnetuserid': sUserId
		},
		dataType: 'json',

	};

	let jwtToken = localStorage.getItem("jwtToken");
	if(jwtToken){
		options.headers["Authorization"] = jwtToken;
	}

	if(dataType === "params"){
		options["params"] = data;
	}
	else{
		options["data"] = data;
	}

	let res = null;

    await axios(options).then((respose)=>{
      res = respose;
		 }).catch((error)=> {
		  res = error.response;
    });

  return res;
}

export const postRequest = async(path, data,paginationObj, dataType="data") =>await request(path, data, "POST",paginationObj, dataType)
export const putRequest = async(path, data,paginationObj, dataType="data") =>await request(path, data, "PUT",paginationObj, dataType)
export const getRequest = async(path, data,paginationObj) =>await request(path, data, "GET",paginationObj, "params")
