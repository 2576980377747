import React from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import './PrivacyPage.css';

const PrivacyPage = ({ history }) => {

    return (
        <div className="privacy_wraper privacy_page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>THESE TERMS CONTAIN AN ARBITRATION CLAUSE, DISCLAIMERS AND LIMITATIONS OF LIABILITY. PLEASE REVIEW CAREFULLY.</p>
                        <p>The following terms ("Terms of Use") constitute an agreement between Accents on Health, Inc. DBA Healthy Dining ("Company"), and you that governs your use of this website and all of its associated services, content, and functionality, including purchase and use of the Membership (defined below). This policy applies to the website administered by Company (the "Website"), located at https://www.mymenuusa.com/. Company, owner and operator of the Website, is a corporation formed under the laws of the state of California, United States.</p>
                        <p>Your use of the Website constitutes your acceptance of, and agreement to, the following Terms of Use. Company reserves the right to modify, alter, amend or update its Website, policies and these Terms of Use. These Terms of Use are subject to change without notice. If you do not agree with or do not accept any part of these Terms of Use, you must not use the Website. Your continued use of any part of the Website constitutes your acceptance of such changes to the Terms of Use. You should review the Terms of Use periodically to determine if any changes have been made.</p>
                        <p><strong><u>PURCHASE POLICIES</u></strong></p>
                        <p>On the Website, you may purchase a MyMenu membership (the "Membership"). The Membership includes access to the MyMenu personalized restaurant meal platform, nutritional content, third party software services for nutritional tracking, diet assessment, and a children&rsquo;s nutritional learning program.</p>
                        <p>The Membership is available to individual customers for a monthly or yearly fee, which you may choose at time of purchase (the "Membership Subscription Fee"). You will be required to select a payment plan and provide Company&rsquo;s third-party payment provider with information regarding your credit card or other payment instrument. You represent and warrant to Company that such information is true and that you are authorized to use the payment instrument. You will promptly update your User Account information with any changes (for example, a change in your billing address or credit card expiration date) that may occur. You agree to pay Company the amount that is specified in the payment plan in accordance with the terms of such plan and these Terms of Use. You hereby authorize Company to bill your payment instrument in advance in accordance with the terms of the applicable payment plan until you terminate your User Account, and you further agree to pay any charges so incurred. If you dispute any charges you must let Company know within sixty (60) days after the date that Company charges you. Company reserves the right to change the price of the Membership. Your, or in the case of an enterprise Membership, any end users (&ldquo;End Users&rdquo;), continued use of the Membership after the price change becomes effective constitutes your agreement to pay the changed amount. Access will be revoked to the Membership if you choose a payment plan or subscription and do not complete the payments on schedule.&nbsp;</p>
                        <p>From time to time, Company may offer special promotional or discounted pricing to certain enterprise, affiliate or individual Members. Such pricing may be subject to additional terms. In this case, Company will honor the discounted pricing for one year whether or not the monthly or yearly option is chosen.&nbsp;</p>
                        <p>Your Membership will automatically renew each month or year, depending on the payment plan you have selected. You may cancel a monthly or annual Membership at any time. It is your responsibility to cancel the Membership prior to renewal. You may cancel by selecting &ldquo;cancel&rdquo; in the Manage Billing section of your Profile.</p>
                        <p><strong><u>Refund Policy</u></strong></p>
                        <p>Refunds are not available for the Membership except as expressly set forth herein. You may cancel a Membership at any time, but the account will remain active until either the day prior to the following month (if paid monthly) or day prior to the following year (if paid yearly).</p>
                        <p>Certain jurisdictions may have longer periods where refunds will be granted. Company will honor such periods as required by law.</p>
                        <p><strong><u>REGISTRATION &amp; RESTRICTED ACCESS </u></strong></p>
                        <p>Access to certain areas of the Website may be restricted. Company reserves the right to restrict areas of the Website at its sole discretion.</p>
                        <p>Some restricted areas of the Website may be made available to you as a registered user. When you are registered, you are required to create a user profile, which may include a username and password ("User Account"). You agree to keep your username and password confidential. You may not use as a username the name of another person or entity that is not lawfully available for use, a name or trademark that is subject to any rights or another person or entity or is offensive, vulgar or obscene. If you suspect your password has been compromised, you must notify Company immediately at Terms@HealthyDining.biz. Company will not be liable for any loss caused by the unauthorized use of your User Account; however, you may be liable to Company or other third parties for any losses incurred due to such unauthorized use.</p>
                        <p>When you create a User Account with Company, you guarantee that you are 18 years of age or older, are able to consent to these Terms of Use, and that the information you provide to Company is accurate, complete, and current at all times. Violation of this paragraph may result in the immediate termination of your User Account without refund, in Company&rsquo;s sole discretion.</p>
                        <p>Company may disable your username and password at its sole discretion, refuse to register a user for the Products, remove or edit any content contributed to the Website or cancel any User Account. Company may, without notice, refuse access to its Website or Products, in whole or part, to any person that fails to comply with these Terms of Use.</p>
                        <p>Company reserves the right to modify methods for registration and access levels of registered users from time to time.</p>
                        <p><strong><u>LICENSE FOR USE OF MEMBERSHIP</u></strong></p>
                        <p>The Membership available for sale on the Website was developed solely for your personal use and may not be reproduced for publication or for the personal or commercial use of others without permission. You may not create any derivative works of the Membership. When you purchase or use the Membership, you agree that you will not use any information you gain from the Membership to create any product or service, whether offered for commercial or personal use, without the express written consent of the Company. All inquiries for use of Company intellectual property must be submitted to Terms@HealthyDining.biz. Company reserves the right to seek equitable and compensatory relief for any violation of this term.</p>
                        <p><strong><u>GENERAL DISCLAIMER</u></strong></p>
                        <p>Company has made every effort to ensure that all information on the Website and in the Membership has been tested for accuracy. Company makes no guarantees regarding the results that you will see from using the information provided on the Website or in the Membership. Opinions, advice, statements or other comments should not necessarily be relied upon and are not to be construed as professional advice from Company.</p>
                        <p>Company disclaims liability for incidental or consequential damages and assumes no responsibility or liability for any loss or damage suffered by any person as a result of use of the information provided on the Website or in the Membership. Company assumes or undertakes no liability for any loss or damage suffered as a result of the use of any information found on the Website or in the Membership.</p>
                        <p><strong><u>NUTRITION DISCLAIMER</u></strong></p>
                        <p>All information provided regarding nutrition on the Website or in the Membership is intended to be used for informational purposes only. Content related to nutrition is not medical advice nor is it intended to replace medical advice. The Website and Membership is not intended to diagnose, prescribe, or treat any disease, condition, illness, or injury. Before beginning any diet program, modifying your diet, or making changes to the diet of a child in your care, including following the nutrition information available on the Website or in the Membership, you should seek advice from a licensed professional.</p>
                        <p>We are not responsible for any liability, loss, or damage, caused or alleged to be caused directly or indirectly as a result of the use, application, or interpretation of the nutrition information available on the Website or in the Membership.</p>
                        <p>The Food and Drug Administration has not evaluated the statements contained in any information on the Website or in the Membership. Individual results may vary.</p>
                        <p><strong><u>ALLERGY DISCLAIMER</u></strong></p>
                        <p>You understand that the avoidance tool in the Membership does not certify that the applicable restaurant prevents against cross contamination, therefore an allergic reaction is possible. Company does not make any guarantees that any restaurant or dish does not contain allergens. You are solely responsible for ensuring that any meals or restaurant is safe for you given your dietary restrictions.</p>
                        <p><strong><u>YOUR RESPONSIBILITY</u></strong></p>
                        <p>The Website and Membership were developed strictly for informational purposes. You understand and agree that you are fully responsible for your use of the information provided on the Website and in the Membership. Company makes no representations, warranties or guarantees. You understand that results may vary from person to person. Company assumes no responsibility for errors or omissions that may appear on the Website or in the Membership.</p>
                        <p><strong><u>USE OF THE WEBSITE </u></strong></p>
                        <p>Unless otherwise stated, Company owns the intellectual property and rights to all content and material on the Website and in the Membership. Subject to the license below, all intellectual property rights are reserved.</p>
                        <p>You may view, download (for caching purposes only), and print pages for your personal use, subject to the restrictions set out below and elsewhere in these Terms of Use.</p>
                        <p>The following uses are not permitted:</p>
                        <ol>
                            <li>Republication of content from the Website or Membership, unless content is specifically and expressly made available for republication;</li>
                            <li>Sale, rental or sub-license of any content from the Website or Membership;</li>
                            <li>Reproduction or duplication of any content on the Website or Membership for commercial purposes;</li>
                            <li>Modification of any content on the Website or Membership, unless content is specifically and expressly made available for modification;</li>
                            <li>Redistribution of content of the Website or Membership, unless content is specifically and expressly made available for redistribution. Users are permitted to share content on social media channels, as long as a link to the Website is included.</li>
                        </ol>
                        <p>From time to time, the Website or Membership will utilize various plugins or widgets to allow sharing of content via social media channels, email, or other methods. Use of these plugins or widgets does not constitute any waiver of Company's intellectual property rights. Such use is a limited license to republish the content on the approved social media channels, with full credit to Company.</p>
                        <p>You must not use the Website or Membership in a way that causes, or may cause, damage to the Website or Membership or impairs the availability of access to the Website or Membership. You must not decompile, reverse engineer, disassemble or otherwise reduce the Website or Membership, except to the extent that such activity is expressly permitted by applicable law. You must not use the Website or Membership to copy, store, host, transmit, send, use, publish or distribute any material that consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit and/or other harmful code or malicious software.</p>
                        <p>You must not conduct any systematic or automated data collection activities, including, but not limited to scraping, data mining, data extraction or data harvesting on or in relation to the Website without Company's express written permission.</p>
                        <p>You must not use the Website or Membership to transmit or send any unsolicited commercial communications.</p>
                        <p>You must not use the Website or Membership for any third-party marketing without Company's express written permission.</p>
                        <p><strong><u>INTELLECTUAL PROPERTY</u></strong></p>
                        <p>All original materials provided by Company are owned by Company. Any original materials are provided for your individual use only. You are not authorized to use or transfer any of Company's intellectual property. All intellectual property remains the property of Company. No license to sell, distribute, reproduce, prepare a derivative work, display, or perform is granted or implied.</p>
                        <p>Certain of the names, logos, and other materials displayed on the Website or in the Membership constitute Company's intellectual property, including, but not limited to, patents, trademarks, service marks, trade secrets and copyrights ("Company IP"). You are not authorized to use any Company IP without Company's express consent. Ownership of Company IP remains with Company and you agree not to make any claims or assertions of any other party's ownership of Company IP.</p>
                        <p><strong><u>Equitable Relief</u></strong></p>
                        <p>You acknowledge and agree that in the event of certain breaches of the Terms of Use, Company may suffer irreparable injury, such that no remedy at law will afford it adequate protection against, or appropriate compensation for, such injury. Accordingly, you agree that Company shall be entitled to any injunctive relief, without having to post a bond, as may be granted by a court of competent jurisdiction.</p>
                        <p><strong><u>COPYRIGHT</u></strong></p>
                        <p>Unless otherwise noted, the design, content and all components of the Website and Membership are copyrights owned by Company or third parties and are protected by United States and international copyright laws and should not be reused or republished without express written permission.</p>
                        <p><strong><u>TRADEMARKS</u></strong></p>
                        <p>"MyMenu" is a trademark of Company and is protected by United States trademark law. Company's trademarks and trade dress may not be used in connection with any product or service that is not Company's, in any manner likely to cause confusion among consumers, or in any manner that disparages or discredits Accents on Health, Inc. DBA Healthy Dining, https://www.mymenuusa.com/, or the experts featured on the Website or in the Membership.</p>
                        <p>From time to time, the Website and Membership will legally utilize trademarks owned by third parties related to Company's services. These trademarks are the respective property of their owners.</p>
                        <p><strong><u>FTC DISCLOSURE</u></strong></p>
                        <p>From time to time, the Website or Membership may post sponsored content from an advertiser. This means that an advertiser, which may be a brand, agency, or influencer network, will contract with Company to create content featuring certain messages or product placements. Pursuant to the FTC's requirements, all such advertisements on the Website or in the Membership are clearly and conspicuously disclosed.</p>
                        <p>Even though compensation (cash, free product, services) is received in exchange for this sponsored content placement, Company gives its honest opinion, findings, beliefs, or experiences in such content. All views expressed on the Website or Membership are those of the content creator. Any product claim, statistic, quote or other representation about a product or service should be verified with the manufacturer, provider, or party in question.</p>
                        <p><strong><u>AFFILIATE MARKETING</u></strong></p>
                        <p>From time to time, the Website or Membership may engage in affiliate marketing. This means that if you use an affiliate link to make a purchase, the Website or Membership will receive a commission on that purchase. All efforts are made to ensure that affiliate links are disclosed in accordance with the FTC.</p>
                        <p><strong><u>GRANT OF RIGHTS</u></strong></p>
                        <p>You grant Company a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute any content you contribute to the Website or Membership. This includes, but is not limited to, text, images, audio material, comments, video material and audio-visual material. This license extends to all known and future media. You also grant Company the right to sub-license these rights and the right to bring an action for infringement of these rights. By posting content to the Website or Membership, you represent that you have the right to grant these permissions for the use of such content by Website, the Membership, Company and Company&rsquo;s sublicensees.</p>
                        <p><strong><u>CONTENT CONTRIBUTED TO THE WEBSITE<br /></u></strong>Any content you contribute to the Website or Membership, including, but not limited to text, images, audio material, comments, video material and audio-visual material, must not be illegal or unlawful, may not infringe on any third-party's legal rights, and must not be capable of giving rise to legal action whether against you or Company or a third party.</p>
                        <p>Company reserves the right to edit or remove: (i) any material submitted to the Website or Membership; (ii) stored on Company's servers; or, (iii) hosted or published on the Website or Membership. Company takes no responsibility and assumes no liability for any content posted by you or any third party.</p>
                        <p>Notwithstanding Company's rights under the Terms of Use, Company does not undertake to monitor the submission of all content to, or the publication of such content on, the Website and is not responsible for such content.</p>
                        <p><strong><u>COMMENT POLICY</u></strong></p>
                        <p>The Website or Membership may offer the option for you to leave comments, engaging with the Website or Membership. The following types of comments will not be tolerated and will be deleted:</p>
                        <ol>
                            <li>harassment directed toward any content creator or Company;</li>
                            <li>spam;</li>
                            <li>hate speech;</li>
                            <li>defamatory to Company or any third party;</li>
                            <li>reference illegal acts; or,</li>
                            <li>violate the legal rights of a third party.</li>
                        </ol>
                        <p>Company's sole discretion will be used to determine if a comment is in violation of this comment policy. Any comments in violation will be promptly deleted and no further explanation will be due to you if your comment was determined to be in violation with this policy.</p>
                        <p>You are, and shall remain, solely responsible for the any content you upload, submit, post, transmit, communicate, share, or exchange by means of the Website or Membership and for the consequences of submitting or posting same. COMPANY DISCLAIMS ANY PERCEIVED, IMPLIED OR ACTUAL DUTY TO MONITOR THE MEMBERSHIP OR WEBSITE AND SPECIFICALLY DISCLAIMS ANY RESPONSIBILITY OR LIABILITY FOR INFORMATION PROVIDED THEREON.</p>
                        <p>You and any participant violating the Terms of Use may be immediately and permanently removed from the Website and/or Membership, in Company&rsquo;s sole discretion and no refund or credit will be due to you in such case.</p>
                        <p>Any content posted in the Membership or on the Website is the sole responsibility of the person(s) who created it, and Company and its employees, agents, directors, and officers, undertake no obligation or liability related to such content. Company and its employees, agents, directors, and officers, do not undertake or assume any duty to monitor for inappropriate or unlawful content posted by participants, nor does it assume responsibility or liability that may arise from any content posted in the Membership or on the Website, including, but not limited to, claims of defamation, libel, slander, infringement, invasion of privacy and publicity rights, obscenity, pornography, fraud, or misrepresentation.&nbsp;</p>
                        <p>Company reserves the right to report to the appropriate authority any post, comment, message or participant in the Membership that Company deems, in its sole discretion, may implicate the safety of either a participant or a third-party.</p>
                        <p><strong><u>TAKEDOWN REQUESTS</u></strong></p>
                        <p>From time to time, the Website or Membership will publish posts with images from other third-party websites. Any such use is considered fair use under copyright laws and is fully attributed to the owner. If you believe that your copyrighted work has been used on the Website or Membership in a way that constitutes copyright infringement and falls outside of fair use, please send a request to Terms@HealthyDining.biz and we will remove the image within 24 to 48 hours.</p>
                        <p><strong><u>COMMUNICATION</u></strong></p>
                        <p>If you send Company an email, register to use the Website or Membership or provide your email to Company in any other way, you consent to receive communications from Company electronically. You agree that all legal notices provided via electronic means from Company satisfy any requirement for written notice.</p>
                        <p><strong><u>THIRD PARTIES</u></strong></p>
                        <p>The Website or Membership may contain links to third-party websites that are not governed or controlled by Company. You represent and warrant that you have read and agree to be bound by all applicable terms of use and policies for any third-party website that relates to your use of the Website or Membership. Company assumes no control or liability over the content of any third-party sites. You expressly hold Company harmless from any and all liability related to your use of a third-party website.</p>
                        <p><strong><u>CHILDREN'S INFORMATION</u></strong></p>
                        <p>The Website and Membership do not knowingly collect any personally identifiable information from children under the age of 16. If a parent or guardian believes that the Website or Membership has personally identifiable information of a child under the age of 16 in its database, please contact Company immediately at Terms@HealthyDining.biz and we will use our best efforts to promptly remove such information from our records.</p>
                        <p><strong><u>NO WARRANTIES </u></strong></p>
                        <p>The Website and Membership is provided on an "as is" and "as available" basis without any representations or warranties, expressed or implied. Company makes no representations or warranties in relation to the Website, Membership or the information and materials provided therein.</p>
                        <p>Company makes no warranty the Website or Membership will meet your requirements; will be available uninterrupted; error free, timely and free of viruses or bugs; or represents the full functionality, accuracy, and reliability of the Website or Membership. Company is not responsible to you for the loss of any content or material uploaded or transmitted through the Website or Membership. The Website and Membership is written in English and Company makes no warranty regarding translation or interpretation of content in any language.&nbsp;</p>
                        <p><strong><u>SERVICE LEVEL</u></strong></p>
                        <p>Given the cloud-based nature of the Membership, Company cannot guarantee service at all times. Company endeavors to provide a service level of 99% however, Company shall not be liable for any failure to meet this service level and no refund or credits shall be granted for such failure. If the Membership will not be available due to any pre-scheduled maintenance, Company will provide notice when reasonably able to do so.</p>
                        <p><strong><u>LIMITATION OF LIABILITY</u></strong></p>
                        <p>TO THE EXTENT ALLOWABLE BY LAW, COMPANY AND ITS OFFICERS, EMPLOYEES, AGENTS, AFFILIATES, LICENSEES AND WEB HOSTING SERVICES WILL NOT BE LIABLE FOR ANY DIRECT, CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, PUNITIVE OR SPECIAL DAMAGES OF ANY KIND, HOWEVER CAUSED, INCLUDING LOSS OF PROFITS, REVENUE, DATA OR USE, INCURRED BY YOU, WHETHER UNDER THEORY OF CONTRACT, TORT (INCLUDING NEGLIGENCE), WARRANTY OR OTHERWISE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                        <p>YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE USE OF THIS WEBSITE OR THE TERMS OF USE MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR BE FOREVER BARRED.</p>
                        <p><strong><u>INDEMNITY</u></strong></p>
                        <p>You agree to defend, indemnify and hold Company, its members, employees, officers, directors, managers and agents harmless from and against any and all losses, claims, suits, actions, liabilities, obligations, costs and expenses (including reasonable attorneys' fees and expenses) which Company suffers as a result of third-party claims based on: (i) your negligence or intentional misconduct, (ii) your breach of any provision of the Terms of Use (including representation or warranty); (iii) materials prepared or provided by you including, but not limited to, any claims of infringement, or misappropriation of copyright, trademark, patent, trade secret, or other intellectual property or proprietary right, infringement of the rights of privacy or publicity, or defamation or libel; or (iv) death, personal injury, or property damage arising out of, or relating to, your obligations hereunder.</p>
                        <p><strong><u>ARBITRATION</u></strong></p>
                        <p>The Terms of Use will be governed and construed in accordance with the laws of the state of California without reference to its conflict of law provisions. Any controversy or claim arising out of or relating to the Website, Membership, Terms of Use, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association ("AAA") under its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The place of any such arbitration shall be in San Diego County, California. The parties also agree that the AAA Optional Rules for Emergency Measures of Protection shall apply to the proceedings. This section provides the sole recourse for the settlement of any disputes arising out of, in connection with, or related to the Website, Membership, or Terms of Use. Notwithstanding the foregoing, any action seeking injunctive relief shall be submitted to the courts and shall not be subject to this provision.</p>
                        <p>THE PARTIES WAIVE ANY RIGHT TO ASSERT ANY CLAIMS AGAINST THE OTHER PARTY AS A REPRESENTATIVE OR MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION, EXCEPT WHERE SUCH WAIVER IS PROHIBITED BY LAW OR DEEMED BY A COURT OF LAW TO BE AGAINST PUBLIC POLICY. TO THE EXTENT EITHER PARTY IS PERMITTED BY LAW OR A COURT OF LAW TO PROCEED WITH A CLASS OR REPRESENTATIVE ACTION AGAINST THE OTHER, THE PARTIES AGREE THAT: (I) THE PREVAILING PARTY SHALL NOT BE ENTITLED TO RECOVER ATTORNEYS&rsquo; FEES OR COSTS ASSOCIATED WITH PURSUING THE CLASS OR REPRESENTATIVE ACTION (NOT WITHSTANDING ANY OTHER PROVISION IN THIS AGREEMENT); AND (II) THE PARTY WHO INITIATES OR PARTICIPATES AS A MEMBER OF THE CLASS WILL NOT SUBMIT A CLAIM OR OTHERWISE PARTICIPATE IN ANY RECOVERY SECURED THROUGH THE CLASS OR REPRESENTATIVE ACTION.</p>
                        <p><strong><u>MISCELLANEOUS PROVISIONS</u></strong></p>
                        <p>If any provision(s) of the Terms of Use is held to be invalid, illegal or unenforceable, the remaining provisions shall be severable and enforceable. In addition, in such event the unenforceable or invalid provision shall be deemed to be modified to the extent necessary to (i) render it valid and enforceable and (ii) give the fullest effect possible to the original intent of the provision.</p>
                        <p>The Terms of Use may not be assigned by you without Company's prior written consent; however, the Terms of Use may be assigned by Company in its sole discretion.</p>
                        <p>The Terms of Use are the final, complete and exclusive agreement of the parties with respect to the Website and Membership offered by Company.</p>
                        <p>The failure of Company to exercise or enforce any right or provision hereunder shall not operate as a waiver of such right or provision. Any waiver of the Terms of Use by Website or Company must be in writing and signed by an authorized representative of the Company.</p>
                        <p>All notices with respect to the Terms of Use must be in writing and may be via email to Terms@HealthyDining.biz for Company and to your email address.</p>
                        <p>Last updated: May 6, 2021.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withLocalize(withRouter(PrivacyPage));
