import {MYMEAL_COUNT} from '../Actions/actionTypes';
const initState = {status:false,myMealCount:0}

//Define Actions
const characterReducer = (state = initState, action) => {
    switch (action.type) {
        case MYMEAL_COUNT:
            return {
                ...state,
                myMealCount: action.data
            }
        default:
            return state
    }
}

export default characterReducer;
