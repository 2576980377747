import renderHTML from 'react-render-html';

export const FormError=(errors)=>{
    let formatedErrors = {};
    for(let x in errors){
        formatedErrors[errors[x].param] = errors[x].msg;
    }
   return formatedErrors;
}

export const ShowFormErrors=(hookError,formError,field)=>{
    if(hookError[field] && hookError[field].message){

        if (hookError[field].type === 'required') {
            return renderHTML("<span>" + hookError[field].message + "</span>");

        }
    }
    else if (formError[field]) {
        return formError[field];
    }
}

export const ShowFormValidErrors=(hookError,formError,field)=>{
    if(hookError[field] && hookError[field].message){
        if (hookError[field].type !== 'required') {
           return hookError[field].message;
        }

    }
    else if(formError[field]){
        return formError[field];
    }
}
